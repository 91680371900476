/* eslint-disable no-sequences */
import React, { useState } from 'react';
import '../../../config/ReactotronConfig';
import { IoMdClose } from 'react-icons/io';
import { Base64 } from 'js-base64';
import { Cpf } from '../../../services/masks';
import {
  Bloco,
  DadosPesssoaisItemLabel,
  DadosPesssoaisItemInfo,
  ModalPPE,
  ModalEstadoCiv
} from '../styles';
import api from '../../../services/api';
import { store } from '../../../store';

function BlocoDadosPessoais(props, n) {
  const dadosPessoa = props;
  const [visiblePPE, setVisiblePPE] = useState(false);
  const [visibleEstadoCiv, setVisibleEstadoCiv] = useState(false);
  const [ppe, setPPE] = useState('');
  const [estadoCiv, setestadoCiv] = useState('');
  // const [flag, setFlag] = useState(false);

  if (props === undefined) {
    return <></>;
  }

  function handleMostrarPPE() {
    setVisiblePPE(!visiblePPE);
  }

  function handleMostrarEstadoCiv() {
    setVisiblePPE(!visibleEstadoCiv);
  }

  async function handleTrocaPPE(v) {
    if (v === 'S' || v === 'N') {
      const t = store.getState().auth.token;

      const parametros = Base64.encode(
        `{"token":"${t}","politicamenteexposta":"${v}"}`
      );

      const parametrosFinal = Base64.encode(parametros);

      // const response =
      await api
        .post(`/wsPoliticamenteExposta.rule?sys=PRT&Entrada=${parametrosFinal}`)
        .then(() => document.location.reload(true))
        .catch(() => setVisiblePPE(!visiblePPE));

      // setVisiblePPE(!visiblePPE);
    }
  }

  async function handleTrocaEstadoCiv(v) {
    if (v !== "") {
      const t = store.getState().auth.token;

      const parametros = Base64.encode(
        `{"token":"${t}","estadocivil":"${v}"}`
      );

      const parametrosFinal = Base64.encode(parametros);

      // const response =
      await api
        .post(`/wsEstadoCivil.rule?sys=PRT&Entrada=${parametrosFinal}`)
        .then(() => document.location.reload(true))
        .catch(() => setVisibleEstadoCiv(!visibleEstadoCiv));

      // setVisiblePPE(!visiblePPE);
    }
  }


  return (
    <>
      <ModalPPE visible={visiblePPE}>
        <popup-inner>
          <icone-sair>
            <IoMdClose size={20} color="#818181" onClick={handleMostrarPPE} />
          </icone-sair>
          <div-corpo>
            <select onChange={(e) => setPPE(e.target.value)}>
              <option value=""> </option>
              <option value="S">Sim</option>
              <option value="N">Não</option>
            </select>
            <button type="button" onClick={() => handleTrocaPPE(ppe)}>
              Alterar
            </button>
          </div-corpo>
        </popup-inner>
      </ModalPPE>
      <ModalEstadoCiv visible={visibleEstadoCiv}>
        <popup-inner>
          <icone-sair>
            <IoMdClose size={20} color="#818181" onClick={handleMostrarEstadoCiv} />
          </icone-sair>
          <div-corpo>
            <select onChange={(e) => setestadoCiv(e.target.value)}>
              <option value=""> </option>
              <option value="C">Casado(a)</option>
              <option value="S">Solteiro(a)</option>
              <option value="D">Divorciado(a)</option>
              <option value="P">Separado(a)</option>
              <option value="U">União Estável</option>
              <option value="V">Viúvo(a)</option>
            </select>
            <button type="button" onClick={() => handleTrocaEstadoCiv(estadoCiv)}>
              Alterar
            </button>
          </div-corpo>
        </popup-inner>
      </ModalEstadoCiv>
      <Bloco>
        <dados-pessoais-subtitulo>Dados pessoais</dados-pessoais-subtitulo>
        <dados-pessoais-bloco-parte01>
          <dados-pessoais-bloco-parte02>
            <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>Nome</DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>
                {dadosPessoa[n].nome == null ? '-' : dadosPessoa[n].nome}
              </DadosPesssoaisItemInfo>
            </dados-pesssoais-item>
            <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>CPF</DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>
                {Cpf(dadosPessoa[n].cpf) == null
                  ? '-'
                  : Cpf(dadosPessoa[n].cpf)}
              </DadosPesssoaisItemInfo>
            </dados-pesssoais-item>
            <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>
                Data de nascimento
              </DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>
                {dadosPessoa[n].nascimento == null
                  ? '-'
                  : dadosPessoa[n].nascimento}
              </DadosPesssoaisItemInfo>
            </dados-pesssoais-item>
          </dados-pessoais-bloco-parte02>
          <dados-pessoais-bloco-parte02>
            <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>Sexo</DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>
                {dadosPessoa[n].sexo == null ? '-' : dadosPessoa[n].sexo}
              </DadosPesssoaisItemInfo>
            </dados-pesssoais-item>
            <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>Estado civil</DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>
                {dadosPessoa[n].estadoCivil == null
                  ? '-'
                  : dadosPessoa[n].estadoCivil}
                  {/* <label-alterar onClick={() => setVisiblePPE(!visiblePPE)}>
                  Alterar
                </label-alterar> */}
              </DadosPesssoaisItemInfo>
            </dados-pesssoais-item>

            <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>
                Pessoa politicamente exposta?
              </DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>
                {dadosPessoa[n].politicamenteExposta == null
                  ? '-'
                  : dadosPessoa[n].politicamenteExposta}
                <label-alterar onClick={() => setVisiblePPE(!visiblePPE)}>
                  Alterar
                </label-alterar>
              </DadosPesssoaisItemInfo>
            </dados-pesssoais-item>
          </dados-pessoais-bloco-parte02>
        </dados-pessoais-bloco-parte01>
      </Bloco>
    </>
  );
}
export default BlocoDadosPessoais;
