/* eslint-disable no-nested-ternary */

import React, { useState, useEffect } from 'react'; //
import '../../config/ReactotronConfig';
import { Base64 } from 'js-base64';
import { useDispatch } from 'react-redux';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { Cpf, ValorMascarar } from '../../services/masks';
import api from '../../services/api';
import { SecaoVisaoGeral, VisaoGeralLabel, VisaoGeralInfo } from './styles';
import IconeVisaoGeral from '../../assets/icone-visa-geral-2.svg';
import { store } from '../../store';
import { signOut } from '../../store/modules/auth/actions';

function VisaoGeral() {
  const [state, setState] = useState({
    VisaoGeral: {},
    Erro: '',
  });
  const [visible, setVisible] = useState(false);
  const [visibleEye, setVisibleEye] = useState(false);

  function HandleToggleVisible() {
    setVisible(!visible);
  }
  function HandleToggleVisibleEye() {
    setVisibleEye(!visibleEye);
  }

  const dispatch = useDispatch();

  useEffect(() => {
    const { indice } = store.getState().visaoGeral;
    async function pegaVisaoGeral() {
      // try {
      const t = store.getState().auth.token;
      const dadosPlano = store.getState().visaoGeral.profile.dadosPlano[indice];
      const pl = dadosPlano.codPlano;
      const pt = dadosPlano.patrocinadora;
      const m = dadosPlano.matricula;

      const parametros = Base64.encode(
        `{"token":"${t}",
          "patrocinadora":"${pt}",
          "matricula":"${m}",
          "plano":"${pl}"}`
      );

      const parametrosFinal = Base64.encode(parametros);
      // const response =
      await api
        .post(`/wsVisaoGeral.rule?sys=PRT&Entrada=${parametrosFinal}`)
        .then((res) =>
          setState({
            VisaoGeral: res.data,
          })
        )
        .catch((res) =>
          res.message === 'Request failed with status code 401' ||
          res.message === 'Request failed with status code 408'
            ? dispatch(signOut())
            : setState({
                VisaoGeral: {},
              })
        );
    }
    pegaVisaoGeral();
  }, [dispatch]);

  const dadosVisaoGeral = state.VisaoGeral;

  return (
    <SecaoVisaoGeral>
      <visao-geral-frame>
        <visao-geral-frame-parte01>
          <visao-geral-linha-titulo onClick={HandleToggleVisible}>
            <visao-geral-linha-titulo-parte01>
              <image>
                <img src={IconeVisaoGeral} alt="IconeVisaoGeral" />
              </image>
              <visao-geral-linha-titulo-texto>
                <visao-geral-titulo>Visão geral</visao-geral-titulo>
                <visao-geral-linha-atualizacao>
                  <visao-geral-atualizacao-label>
                    atualização:
                  </visao-geral-atualizacao-label>
                  <visao-geral-atualizacao-data>
                    {dadosVisaoGeral.referencia === null ||
                    dadosVisaoGeral.referencia === undefined ||
                    dadosVisaoGeral.referencia === ''
                      ? '-'
                      : dadosVisaoGeral.referencia}
                  </visao-geral-atualizacao-data>
                </visao-geral-linha-atualizacao>
              </visao-geral-linha-titulo-texto>
            </visao-geral-linha-titulo-parte01>

            {/* <visao-geral-linha-titulo-parte02>
              <menu-botao>
                <botao-p>
                  <AiOutlinePlus
                    size={50}
                    style={
                      visible
                        ? {
                            transform: 'rotate(-135deg)',
                            transition: 'transform .2s ease-in-out',
                          }
                        : {
                            transform: 'rotate(0deg)',
                            transition: 'transform .2s ease-in-out',
                          }
                    }
                  />
                </botao-p>
              </menu-botao>
            </visao-geral-linha-titulo-parte02> */}
          </visao-geral-linha-titulo>
        </visao-geral-frame-parte01>
        <visao-geral-frame-parte02>
          <visao-geral-linha>
            <visao-geral-item-nome>
              <VisaoGeralLabel>
                <label-l>Nome do titular</label-l>
              </VisaoGeralLabel>
              <VisaoGeralInfo>
                {dadosVisaoGeral.nome === null ||
                dadosVisaoGeral.nome === undefined ||
                dadosVisaoGeral.nome === ''
                  ? '-'
                  : dadosVisaoGeral.nome}
              </VisaoGeralInfo>
            </visao-geral-item-nome>
            <visao-geral-item-matricula>
              <VisaoGeralLabel>
                <label-l>Matrícula</label-l>
              </VisaoGeralLabel>
              <VisaoGeralInfo>
                {dadosVisaoGeral.matricula === null ||
                dadosVisaoGeral.matricula === undefined ||
                dadosVisaoGeral.matricula === ''
                  ? '-'
                  : dadosVisaoGeral.matricula}
              </VisaoGeralInfo>
            </visao-geral-item-matricula>
            <visao-geral-item-cpf>
              <VisaoGeralLabel>
                <label-l>CPF</label-l>
              </VisaoGeralLabel>
              <VisaoGeralInfo>
                {dadosVisaoGeral.cpf === null ||
                dadosVisaoGeral.cpf === undefined ||
                dadosVisaoGeral.cpf === ''
                  ? '-'
                  : Cpf(dadosVisaoGeral.cpf)}
              </VisaoGeralInfo>
            </visao-geral-item-cpf>
          </visao-geral-linha>
          <visao-geral-linha>
            <visao-geral-item-status>
              <VisaoGeralLabel>
                <label-l>Status</label-l>
              </VisaoGeralLabel>
              <VisaoGeralInfo>
                {dadosVisaoGeral.tipoparticipante === null ||
                dadosVisaoGeral.tipoparticipante === undefined ||
                dadosVisaoGeral.tipoparticipante === ''
                  ? '-'
                  : dadosVisaoGeral.tipoparticipante}
              </VisaoGeralInfo>
            </visao-geral-item-status>
            <visao-geral-item-adesao>
              <VisaoGeralLabel>
                <label-l>Adesão</label-l>
              </VisaoGeralLabel>
              <VisaoGeralInfo>
                {dadosVisaoGeral.adesao === null ||
                dadosVisaoGeral.adesao === undefined ||
                dadosVisaoGeral.adesao === ''
                  ? '-'
                  : dadosVisaoGeral.adesao}
              </VisaoGeralInfo>
            </visao-geral-item-adesao>
            <visao-geral-item-cpf>
              <VisaoGeralLabel>
                <label-l>Regime tributário</label-l>
              </VisaoGeralLabel>
              <VisaoGeralInfo>
                {dadosVisaoGeral.regime === null ||
                dadosVisaoGeral.regime === undefined ||
                dadosVisaoGeral.regime === ''
                  ? '-'
                  : dadosVisaoGeral.regime}
              </VisaoGeralInfo>
            </visao-geral-item-cpf>
          </visao-geral-linha>
          {/* <Collapse in={visible}> */}
          <visao-geral-linha2>
            <visao-geral-destaque>
              <VisaoGeralLabel>
                <label-l>Contribuições mensais do participante (R$)</label-l>
              </VisaoGeralLabel>
              <visao-geral-valor>
                {dadosVisaoGeral.contribParticipante === null ||
                dadosVisaoGeral.contribParticipante === undefined ||
                dadosVisaoGeral.contribParticipante === '' ||
                dadosVisaoGeral.contribParticipante === 0 ||
                dadosVisaoGeral.contribParticipante === '0' ||
                dadosVisaoGeral.contribParticipante === 0.0 ||
                dadosVisaoGeral.contribParticipante === '0,00'
                  ? '-'
                  : ValorMascarar(dadosVisaoGeral.contribParticipante)}
              </visao-geral-valor>
            </visao-geral-destaque>
            <visao-geral-destaque>
              <VisaoGeralLabel>
                <label-l>Contribuições mensais da patrocinadora (R$)</label-l>
              </VisaoGeralLabel>
              <visao-geral-valor>
                {dadosVisaoGeral.contribPatrocinadora === null ||
                dadosVisaoGeral.contribPatrocinadora === undefined ||
                dadosVisaoGeral.contribPatrocinadora === '' ||
                dadosVisaoGeral.contribPatrocinadora === 0 ||
                dadosVisaoGeral.contribPatrocinadora === '0' ||
                dadosVisaoGeral.contribPatrocinadora === 0.0 ||
                dadosVisaoGeral.contribPatrocinadora === '0,00'
                  ? '-'
                  : dadosVisaoGeral.contribPatrocinadora}
              </visao-geral-valor>
            </visao-geral-destaque>
            <visao-geral-destaque-rentabilidade>
              <VisaoGeralLabel>
                <label-l>{`Rentabilidade mensal (${
                  dadosVisaoGeral.rentabilidade === null ||
                  dadosVisaoGeral.rentabilidade === undefined ||
                  dadosVisaoGeral.rentabilidade === ''
                    ? ''
                    : dadosVisaoGeral.rentabilidade
                }%)`}</label-l>
              </VisaoGeralLabel>
              <visao-geral-valor>
                {dadosVisaoGeral.impactoMensal === null ||
                dadosVisaoGeral.impactoMensal === undefined ||
                dadosVisaoGeral.impactoMensal === ''
                  ? '-'
                  : dadosVisaoGeral.impactoMensal}
              </visao-geral-valor>
            </visao-geral-destaque-rentabilidade>
            <visao-geral-destaque-total>
              <VisaoGeralLabel>
                <label-l>Saldo total (R$)</label-l>
                <img-g>
                  {visibleEye ? (
                    <BsEye
                      onClick={HandleToggleVisibleEye}
                      color="#fff"
                      size={18}
                    />
                  ) : (
                    <BsEyeSlash
                      onClick={HandleToggleVisibleEye}
                      color="#fff"
                      size={18}
                    />
                  )}
                </img-g>
              </VisaoGeralLabel>
              <visao-geral-valor-total>
                {visibleEye
                  ? dadosVisaoGeral.saldoTotal === null ||
                    dadosVisaoGeral.saldoTotal === undefined ||
                    dadosVisaoGeral.saldoTotal === ''
                    ? 'Não consta'
                    : dadosVisaoGeral.saldoTotal
                  : '-----'}
              </visao-geral-valor-total>
            </visao-geral-destaque-total>
          </visao-geral-linha2>
          {/* </Collapse> */}
        </visao-geral-frame-parte02>
      </visao-geral-frame>
    </SecaoVisaoGeral>
  );
}
export default VisaoGeral;
