import styled from 'styled-components';
import IconeOlho from '../../assets/icone-mostrar-senha.svg';

export const Container = styled.div`
  font-weight: 300;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /*min-height: 100vh;*/
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  @media screen and (max-width: 991px) {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 0px;
  }

  alterar-senha-frame {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 60%;
    min-height: 83vh;
    padding-right: 25px;
    padding-left: 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-right: 1px solid #fe5a17;
    border-left: 1px solid #fe5a17;
    @media screen and (max-width: 991px) {
      width: 85%;
    }
    @media screen and (max-width: 767px) {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  botao-inicio {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 20px;
    margin-bottom: 15px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
  }

  botao-inicio-icone {
    margin-right: 10px;
  }

  botao-inicio-label {
    width: 90px;
    color: #fe5a17;
    font-size: 12px;
  }
  alterar-senha-linha-titulo {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 55px;
    margin-bottom: 75px;
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #0c2d83;
    @media screen and (max-width: 767px) {
      height: 45px;
    }
  }

  alterar-senha-titulo {
    padding-right: 25px;
    padding-left: 25px;
    color: #fff;
    font-size: 32px;
    @media screen and (max-width: 767px) {
      font-size: 28px;
    }
  }
`;

export const AlterarSenhaCorpo = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  @media screen and (max-width: 767px) {
    display: inline-block;
  }

  alterar-senha-corpo-parte01 {
    width: 50%;
    height: 100%;
    padding-right: 25px;
    @media screen and (max-width: 991px) {
      width: 60%;
    }
    @media screen and (max-width: 767px) {
      padding-right: 0px;
    }
  }

  alterar-senha-corpo-parte02 {
    width: 50%;
    height: 100%;
    padding-left: 25px;
    @media screen and (max-width: 991px) {
      width: 40%;
    }
  }

  alterar-senha-campo {
    display: flex;
    width: 100%;
    position: relative;
    margin-bottom: 25px;
  }

  form-block {
    height: 100%;
    width: 100%;
    position: relative;
    margin-bottom: 0px;
  }

  alterar-senha-aviso-frame {
    height: 200px;
  }

  alterar-senha-aviso-frame-parte01 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: #fe5a17;
    @media screen and (max-width: 991px) {
      padding: 10px;
    }
  }

  alterar-senha-aviso-frame-parte02 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 25px;
    padding: 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    box-shadow: 1px 1px 2px 2px rgb(0 0 0 / 15%);
    @media screen and (max-width: 991px) {
      padding: 10px;
    }
    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }

  alterar-senha-aviso-texto {
    margin-bottom: 0px;
    color: #0c2d83;
    margin-top: 8px;
    margin-bottom: 8px;
    @media screen and (max-width: 991px) {
      font-size: 12px;
    }
  }

  checklist-senha-titulo {
    margin-bottom: 10px;
    color: #0c2d83;
    font-size: 18px;
    font-weight: 600;
    @media screen and (max-width: 991px) {
      font-size: 16px;
    }
  }

  checklist-senha {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  checklist-senha-texto {
    margin-left: 5px;
    color: #0c2d83;
    @media screen and (max-width: 991px) {
      font-size: 12px;
    }
  }
`;

export const AlterarSenhaCampo = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 25px;

  alterar-senha-input {
    border-style: none none solid;
    border-width: 0px 0px 1px;
    height: 38px;
    padding: 8px 0px;
    margin-bottom: 10px;
    line-height: 1.42857143;
    border-color: #000 #000 #fe5a17;
  }

  input {
    border-style: none;
    position: relative;
    min-width: 90%;
    font-size: 14px;
    height: auto;
    padding: 4px 8px;
    line-height: 1.42857143;
    color: #333333;
    background-image: ${IconeOlho};

    &:disabled {
      background: #ddd;
    }
  }

  label {
    padding-top: 10px;
    color: #0c2d83;
    display: block;
  }

  span {
    color: #f93a37;
    padding-top: 10px;
    padding-right: 25px;
    float: right;
  }

  img {
    left: auto;
    right: 0%;
    width: 20px;
    position: relative;
  }
`;

export const AlterarSenhaLabel = styled.div`
  padding-top: 5px;
  padding-left: 5px;
  color: #0c2d83;

  alterar-senha-icone {
    position: absolute;
    left: auto;
    top: 9px;
    right: 0%;
    bottom: auto;
  }
`;

export const TextField = styled.input`
  width: 100%;
  font-size: 14px;
  border-style: none none solid;
  border-width: 0px 0px 1px;
  border-color: #000 #000 #fe5a17;
  display: block;
  width: 100%;
  height: 38px;
  padding: 8px 12px;
  margin-bottom: 10px;
  line-height: 1.42857143;
  color: #333333;
`;

export const FormAltSenha = styled.form`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
`;

export const BotaoAlterarSenha = styled.button`
  width: 150px;
  height: 40px;
  margin-top: 25px;
  margin-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #818181;
  transition: background-color 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
  text-align: center;
  display: inline-block;
  padding: 9px 15px;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;

  &:hover {
    background-color: #fe5a17;
  }
`;

export const ModalAlert = styled.div`
  position: fixed;
  z-index: 999;
  display: none;
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 99;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);

  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};

  popup-inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 350px;
    height: 180px;
    padding: 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    align-items: center;
  }

  pop-up-sair-texto {
    margin-top: 10px;
    margin-bottom: 10px;
    color: #fe5a17;
    font-size: 16px;
  }

  pop-sair-frame-parte02 {
  }

  button {
    width: 100px;
    margin-right: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #818181;
    -webkit-transition: background-color 200ms
      cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: background-color 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    text-align: center;
    padding: 9px 15px;
    color: white;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;

    &:hover {
      background-color: #fe5a17;
    }
  }
`;
