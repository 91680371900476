import React, { useState, useEffect } from 'react';
import { Base64 } from 'js-base64';
import { RiCreativeCommonsZeroLine } from 'react-icons/ri';
import Boletos from './boletos';
import Contracheque from './contracheque';
import InfRendimentos from './infRendimentos';
import Empty from './Empty';
import {
  SubmenusDemaisServicos,
  BotaoSubmenuBoletos,
  BotaoSubmenuContracheque,
  BotaoSubmenuInfRendimentos,
} from './styles';
import { store } from '../../store';
import api from '../../services/api';

function Corpo() {
  const [visible, setVisible] = useState(0);
  const [state, setState] = useState({
    ContrachequeGet: null,
    BoletoGet: null,
    InformeGet: null,
    Erro: '',
  });
  const [dadosMenu, setDadosMenu] = useState({});

  const { indice } = store.getState().visaoGeral;

  useEffect(() => {
    async function pegaDatasExtrato() {
      try {
        const t = store.getState().auth.token;

        const parametros = Base64.encode(`{"token":"${t}"}`);
        const parametrosFinal = Base64.encode(parametros);

        const responseCCGet = await api.get(
          `/wsContraCheque.rule?sys=PRT&Entrada=${parametrosFinal}`
        );

        const responseBoletoGet = await api.get(
          `/wsBoleto.rule?sys=PRT&Entrada=${parametrosFinal}`
        );

        const responseInformeGet = await api.get(
          `/wsInformeRendimento.rule?sys=PRT&Entrada=${parametrosFinal}`
        );

        setState({
          ContrachequeGet: responseCCGet.data,
          BoletoGet: responseBoletoGet.data,
          InformeGet: responseInformeGet.data,
        });
      } catch (err) {
        setState({ Erro: err });
      }
    }
    pegaDatasExtrato();

    async function pegaDadosMenu() {
      try {
        const { token } = store.getState().auth;
        const dadosPlano = store.getState().visaoGeral.profile.dadosPlano[
          indice
        ];
        const { codPlano } = dadosPlano;
        const { patrocinadora } = dadosPlano;
        const { matricula } = dadosPlano;

        const parametros = Base64.encode(`{
          "token": "${token}",
          "patrocinadora": "${patrocinadora}",
          "matricula": "${matricula}",
          "plano": "${codPlano}"
        }`);
        const parametrosFinal = Base64.encode(parametros);

        // const response =
        await api
          .get(`/wsMenuPortal.rule?sys=PRT&Entrada=${parametrosFinal}`)
          .then((res) => setDadosMenu(res.data.dadosMenu[0]))
          .catch(
            (res) => res.message === 'Request failed with status code 401'
          );
      } catch {}
    }
    pegaDadosMenu();
  }, []);

  if (state.ContrachequeGet === undefined || state.ContrachequeGet === null) {
    return (
      <>
        <SubmenusDemaisServicos />
      </>
    );
  }

  const { dadosDatas } = state.ContrachequeGet;
  const { dadosBoletos } = state.BoletoGet;
  const dadosInformes = state.InformeGet.dadosDatas;

  function HandleBoletoVisible() {
    setVisible(1);
  }

  function HandleContraChequeVisible() {
    setVisible(2);
  }

  function HandleInfRendimentosVisible() {
    setVisible(3);
  }

  let blocoExib = '';
  if (visible === 0) {
    blocoExib = <Empty />;
  }
  if (visible === 1) {
    blocoExib = <Boletos dadosBol={dadosBoletos} />;
  }
  if (visible === 2) {
    blocoExib = <Contracheque dadosCC={dadosDatas} />;
  }
  if (visible === 3) {
    blocoExib = <InfRendimentos dadosInf={dadosInformes} />;
  }

  return (
    <SubmenusDemaisServicos>
      <w-tab-menu>
        {dadosMenu.boleto === 'S' ? (
          <BotaoSubmenuBoletos
            visible={visible}
            onClick={visible === 1 ? '' : HandleBoletoVisible}
          >
            2ª via de boletos
          </BotaoSubmenuBoletos>
        ) : (
          <></>
        )}
        {dadosMenu.contraCheque === 'S' ? (
          <BotaoSubmenuContracheque
            visible={visible}
            onClick={visible === 2 ? '' : HandleContraChequeVisible}
          >
            Contracheque
          </BotaoSubmenuContracheque>
        ) : (
          <></>
        )}
        {dadosMenu.infRendimentos === 'S' ? (
          <BotaoSubmenuInfRendimentos
            visible={visible}
            onClick={visible === 3 ? '' : HandleInfRendimentosVisible}
          >
            Informe de rendimentos
          </BotaoSubmenuInfRendimentos>
        ) : (
          <></>
        )}
      </w-tab-menu>
      <w-tab-content>
        <w-tab-pane>{blocoExib}</w-tab-pane>
      </w-tab-content>
    </SubmenusDemaisServicos>
  );
}

export default Corpo;
