import React, { useState, useEffect } from 'react';
import { Base64 } from 'js-base64';
import Resgate from './resgate';
import Aposentadoria from './aposentadoria';

import {
  SubmenusSimulador,
  BotaoSubmenuResgate,
  BotaoSubmenuAposentadoria,
} from './styles';
import { store } from '../../store';
import api from '../../services/api';

function Corpo() {
  const HMG = false;
  const [visible, setVisible] = useState(1);
  const [dadosResgate, setDadosResgate] = useState('');
  const [dadosAposentadoria, setDadosAposentadoria] = useState('');

  useEffect(() => {
    const { indice } = store.getState().visaoGeral;
    async function pegaDadosSimulacao() {
      try {
        const { token } = store.getState().auth;
        const dadosPlano = store.getState().visaoGeral.profile.dadosPlano[
          indice
        ];
        const { codPlano } = dadosPlano;
        const { patrocinadora } = dadosPlano;
        const { matricula } = dadosPlano;

        const parametros = Base64.encode(`{
          "token": "${token}",
          "patrocinadora": "${patrocinadora}",
          "matricula": "${matricula}",
          "plano": "${codPlano}"
        }`);
        const parametrosFinal = Base64.encode(parametros);

        const response = await api.get(
          `/wsSimuladorResgate.rule?sys=PRT&Entrada=${parametrosFinal}`
        );

        const responseApos = await api.get(
          `/wsDadosAposentadoria.rule?sys=PRT&Entrada=${parametrosFinal}`
        );
        setDadosResgate(response.data);
        setDadosAposentadoria(responseApos.data);
        // eslint-disable-next-line no-empty
      } catch (err) {}
    }
    pegaDadosSimulacao();
  }, []);

  if (
    dadosResgate === undefined ||
    dadosResgate === null ||
    dadosResgate === ''
  ) {
    return (
      <SubmenusSimulador>
        <p>Desculpe, não foi possível carregar suas simulações.</p>
        <p>
          Caso você tenha feito sua adesão ao plano recentemente, suas
          informações ainda estão sendo processadas pelo sistema.
        </p>
        <p>
          Se tiver dúvidas entre em contato através do{' '}
          <a
            href="https://www.prevpepsico.com.br/#fale-conosco"
            target="_blank"
            rel="noopener noreferrer"
          >
            Fale Conosco.
          </a>
        </p>
      </SubmenusSimulador>
    );
  }

  let blocoExib = '';
  if (visible === 1) {
    blocoExib = <Resgate dadosResg={dadosResgate} />;
  }
  if (visible === 2) {
    blocoExib = <Aposentadoria dadosApos={dadosAposentadoria} />;
  }

  function HandleResgateVisible() {
    setVisible(1);
  }

  function HandleAposentadoriaVisible() {
    setVisible(2);
  }

  if (HMG === true) {
    return <SubmenusSimulador />;
  }

  return (
    <SubmenusSimulador>
      <w-tab-menu>
        <BotaoSubmenuResgate
          visible={visible}
          onClick={!visible ? '' : HandleResgateVisible}
        >
          Resgate
        </BotaoSubmenuResgate>
        <BotaoSubmenuAposentadoria
          visible={visible}
          onClick={!visible ? '' : HandleAposentadoriaVisible}
        >
          Aposentadoria
        </BotaoSubmenuAposentadoria>
      </w-tab-menu>
      <w-tab-content>
        <w-tab-pane>{blocoExib}</w-tab-pane>
      </w-tab-content>
    </SubmenusSimulador>
  );
}

export default Corpo;
