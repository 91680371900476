/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Bloco } from '../styles';

function BlocoPerfilInvestimento(props, x) {
  const dadosPlano = props;

  if (props === undefined) {
    return <></>;
  }

  const perfilInvest = dadosPlano[x].dadosPerfil;
  /* const teste = perfilInvest[0].codigo;

  function tabela() {
    let tabelaList = '';

    if (teste === undefined) {
      tabelaList = (
        <tr>
          <th>-</th>
          <th>-</th>
          <th>-</th>
          <th>-</th>
          <th>-</th>
        </tr>
      );
    } else {
      tabelaList = perfilInvest.map((n, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <tr key={index}>
          <th>{index + 1}</th>
          <th>{n.codigo == null ? '-' : n.codigo}</th>
          <th>{n.descricao == null ? '-' : n.descricao}</th>
          <th>{n.inicioVigencia == null ? '-' : n.inicioVigencia}</th>
          <th>{n.percentual == null ? '-' : n.percentual}</th>
        </tr>
      ));
    }
    return tabelaList;
  } */

  return (
    <Bloco>
      <dados-pessoais-subtitulo>
        Perfil de investimento
      </dados-pessoais-subtitulo>
      <dados-pessoais-bloco-parte01>
        <dados-pessoais-bloco-colunas>
          {/* <table>
            <tr>
              <th>
                <dados-pessoais-coluna-labels>
                  linha
                </dados-pessoais-coluna-labels>
              </th>
              <th>
                <dados-pessoais-coluna-labels>
                  Codigo
                </dados-pessoais-coluna-labels>
              </th>
              <th>
                <dados-pessoais-coluna-labels>
                  Perfil
                </dados-pessoais-coluna-labels>
              </th>
              <th>
                <dados-pessoais-coluna-labels>
                  Início da vigência
                </dados-pessoais-coluna-labels>
              </th>
              <th>
                <dados-pessoais-coluna-labels>
                  Participação %
                </dados-pessoais-coluna-labels>
              </th>
            </tr>
            {tabelaList}
            <> {tabela()}</>
          </table> */}
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {/* <TableCell align="center">Linha</TableCell>
                  <TableCell align="center">Codigo</TableCell> */}
                  <TableCell align="center">Perfil</TableCell>
                  <TableCell align="center">Início da vigência</TableCell>
                  <TableCell align="center">Participação %</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {perfilInvest === undefined ||
                perfilInvest === null ||
                perfilInvest === [] ||
                perfilInvest === '[]' ||
                perfilInvest === '' ? (
                  <TableRow>
                    {/* <TableCell align="center">-</TableCell>
                    <TableCell align="center">-</TableCell> */}
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">-</TableCell>
                  </TableRow>
                ) : (
                  perfilInvest.map((row, index) => (
                    <TableRow key={index}>
                      {/* <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {row.codigo == null || row.codigo === undefined
                          ? '-'
                          : row.codigo}
                      </TableCell> */}
                      <TableCell align="center">
                        {row.descricao == null || row.descricao === undefined
                          ? '-'
                          : row.descricao}
                      </TableCell>
                      <TableCell align="center">
                        {row.inicioVigencia == null ||
                        row.inicioVigencia === undefined
                          ? '-'
                          : row.inicioVigencia}
                      </TableCell>
                      <TableCell align="center">
                        {row.percentual == null || row.percentual === undefined
                          ? '-'
                          : row.percentual}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </dados-pessoais-bloco-colunas>
      </dados-pessoais-bloco-parte01>
    </Bloco>
  );
}

export default BlocoPerfilInvestimento;
