/* eslint-disable no-alert */
import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Base64 } from 'js-base64';
import { RiCloseCircleFill } from 'react-icons/ri';
import {
  SubmenuCorpoFrame,
  SubmenuBotaoEditar,
  SubmenuBotaoNovoBnf,
  ModalAlert,
  SubmenuObservacoes,
  ModalEdit,
  SubmenuBotaoExcluir,
  DadosBnfItemLabel,
} from './styles';
import { store } from '../../store';
import api from '../../services/api';
import { Cpf } from '../../services/masks';
import validaCPF from '../../services/validaCPF';

function Bnf(dadosBnf) {
  const [dadosBeneficiarios] = useState(dadosBnf.dadosBnf[0]);
  const [grauParentesco] = useState(dadosBnf.dadosBnf[1]);
  const [dadosNovoBeneficiario, setNovoBeneficiario] = useState({
    nome: null,
    tipo: null,
    codTipo: null,
    cpf: null,
    grauParentesco: null,
    dcrGrauParentesco: null,
    dataNascimento: null,
    pctRateio: null,
  });
  const [msg, setMsg] = useState('');
  const [alerta, setAlerta] = useState(false);
  const [edit, setEdit] = useState(false);
  const [incluir, setIncluir] = useState(false);
  const [bnfSelecionado, setBnfSelecionado] = useState('');
  const [refresh, setRefresh] = useState(false);
  const { token } = store.getState().auth;

  function handleAbreEditar(codigo) {
    setBnfSelecionado(dadosBeneficiarios[codigo]);
    setEdit(true);
  }
  function handleAbreIncluir() {
    setIncluir(true);
  }

  function HandleToggleAlerta() {
    setAlerta(!alerta);
    if (refresh) {
      window.location.reload();
    }
  }

  const parentListLegal = (
    <>
      <option value={1}>Conjuge</option>
      <option value={2}>Companheiro(a)</option>
      <option value={3}>Filho(a)</option>
      <option value={8}>Enteado(a)</option>
    </>
  );

  const parentListIndicado = (
    <>
      <option value={5}>Pai / Mãe</option>
      <option value={6}>Irmão(a)</option>
      <option value={0}>Outros</option>
    </>
  );

  let parentList = '';

  if (
    grauParentesco === undefined ||
    grauParentesco === null ||
    grauParentesco === [] ||
    grauParentesco === '[]'
  ) {
    parentList = <option value=""> </option>;
  } else {
    parentList = grauParentesco.map((n) => (
      <option value={n.codGrauParentesco}>{`${n.dcrGrauParentesco}`}</option>
    ));
  }

  async function handleEditar() {
    if (
      bnfSelecionado.nome === null ||
      bnfSelecionado.nome === undefined ||
      bnfSelecionado.codTipo === null ||
      bnfSelecionado.codTipo === undefined ||
      bnfSelecionado.cpf === null ||
      bnfSelecionado.cpf === undefined ||
      bnfSelecionado.grauParentesco === null ||
      bnfSelecionado.grauParentesco === undefined ||
      bnfSelecionado.dataNascimento === null ||
      bnfSelecionado.dataNascimento === undefined ||
      (dadosNovoBeneficiario.tipo === 'I' &&
        (dadosNovoBeneficiario.pctRateio === null ||
          dadosNovoBeneficiario.pctRateio === undefined))
    ) {
      setMsg('Existem campos obrigatórios vazios.');
      setAlerta(true);
    } else if (
      !validaCPF(
        bnfSelecionado.cpf.replace('.', '').replace('.', '').replace('-', '')
      )
    ) {
      setMsg('CPF inválido.');
      setAlerta(true);
    } else {
      const dtNascc = bnfSelecionado.dataNascimento;
      const dia = dtNascc.split('/')[0];
      const mes = dtNascc.split('/')[1];
      const ano = dtNascc.split('/')[2];
      const dataForm = `${ano}-${`0${mes}`.slice(-2)}-${`0${dia}`.slice(-2)}`;

      const parametros = Base64.encode(`{
        "token": "${token}",
        "pessoaRelac":${bnfSelecionado.pessoaBenef},
        "nomeBeneficiario":"${bnfSelecionado.nome}",
        "tipoRelacao":"${bnfSelecionado.codTipo}",
        "cpfBeneficiario":"${bnfSelecionado.cpf
          .replace('.', '')
          .replace('.', '')
          .replace('-', '')}",
        "grauParentesco":${bnfSelecionado.grauParentesco},
        "nascimentoBeneficiario":"${dataForm}",
        "pctRateio":${
          dadosNovoBeneficiario.codTipo === 'L' ? '0' : bnfSelecionado.pctRateio
        }
      }`);

      const parametrosFinal = Base64.encode(parametros);
      await api
        .post(`/wsBeneficiario.rule?sys=PRT&Entrada=${parametrosFinal}`)
        .then(() => {
          setEdit(false);
          setMsg('Dados alterados com sucesso!');
          setRefresh(true);
          setAlerta(true);
        })
        .catch((res) => {
          if (res.message === 'Request failed with status code 401') {
            setMsg('Tempo de sessão expirado');
          } else if (res.message === 'Request failed with status code 406') {
            setMsg('O percentual de Rateio total não pode ultrapassar 100%.');
          } else {
            setMsg(
              'Não foi possivel concluir a edição agora. Tente novamente mais tarde.'
            );
          }
          setAlerta(true);
        });
    }
  }

  async function handleExcluir(pessoa) {
    // eslint-disable-next-line no-restricted-globals
    const tt = confirm('Deseja realmente excluir este beneficiário?');
    if (tt) {
      const parametros = Base64.encode(`{
      "token": "${token}",
      "pessoaRelac":${pessoa}}`);

      const parametrosFinal = Base64.encode(parametros);
      await api
        .post(`/wsBeneficiarioPUT.rule?sys=PRT&Entrada=${parametrosFinal}`)
        .then(() => {
          setMsg('Beneficiário excluído com sucesso');
          setRefresh(true);
          setAlerta(true);
        })
        .catch(() => {
          setMsg('Não foi possivel excluir agora. Tente novamente mais tarde.');
          setAlerta(true);
        });
    }
  }
  /* fa dfas*dfas */
  async function handleIncluir() {
    if (
      dadosNovoBeneficiario.codTipo === null ||
      dadosNovoBeneficiario.codTipo === undefined ||
      dadosNovoBeneficiario.grauParentesco === null ||
      dadosNovoBeneficiario.grauParentesco === undefined ||
      dadosNovoBeneficiario.nome === null ||
      dadosNovoBeneficiario.nome === undefined ||
      dadosNovoBeneficiario.dataNascimento === null ||
      dadosNovoBeneficiario.dataNascimento === undefined ||
      dadosNovoBeneficiario.cpf === null ||
      dadosNovoBeneficiario.cpf === undefined ||
      (dadosNovoBeneficiario.tipo === 'I' &&
        (dadosNovoBeneficiario.pctRateio === null ||
          dadosNovoBeneficiario.pctRateio === undefined))
    ) {
      setMsg('Existem campos obrigatórios vazios.');
      setAlerta(true);
    } else if (
      !validaCPF(
        dadosNovoBeneficiario.cpf
          .replace('.', '')
          .replace('.', '')
          .replace('-', '')
      )
    ) {
      setMsg('CPF inválido.');
      setAlerta(true);
    } else {
      const dtNascc = dadosNovoBeneficiario.dataNascimento;
      const dia = dtNascc.split('/')[0];
      const mes = dtNascc.split('/')[1];
      const ano = dtNascc.split('/')[2];
      const dataForm = `${ano}-${`0${mes}`.slice(-2)}-${`0${dia}`.slice(-2)}`;

      const parametros = Base64.encode(`{
        "token": "${token}",
        "pessoaRelac":"${dadosNovoBeneficiario.pessoaBenef}",
        "nomeBeneficiario":"${dadosNovoBeneficiario.nome}",
        "tipoRelacao":"${dadosNovoBeneficiario.codTipo}",
        "cpfBeneficiario":"${dadosNovoBeneficiario.cpf
          .replace('.', '')
          .replace('.', '')
          .replace('-', '')}",
        "grauParentesco":"${dadosNovoBeneficiario.grauParentesco}",
        "nascimentoBeneficiario":"${dataForm}",
        "pctRateio":"${
          dadosNovoBeneficiario.codTipo === 'L'
            ? 0
            : dadosNovoBeneficiario.pctRateio
        }"
      }`);

      const parametrosFinal = Base64.encode(parametros);

      await api
        .get(`/wsBeneficiarioPUT.rule?sys=PRT&Entrada=${parametrosFinal}`)
        .then(() => {
          setIncluir(false);
          setMsg('Beneficiário incluído com sucesso!');
          setRefresh(true);
          setAlerta(true);
        })
        .catch((res) => {
          if (res.message === 'Request failed with status code 401') {
            setMsg('Tempo de sessão expirado');
          } else if (res.message === 'Request failed with status code 406') {
            setMsg('O percentual de Rateio total não pode ultrapassar 100%.');
          } else {
            setMsg(
              'Não foi possivel realizar a inclusão agora. Tente novamente mais tarde.'
            );
          }
          setAlerta(true);
        });
    }
  }

  function handleOnKeyDown(e, flag) {
    if (e.which === 8) {
      let val = e.target.value;
      if (val.length === 3 || val.length === 6) {
        val = val.slice(0, val.length - 1);
        if (flag === 1) {
          setBnfSelecionado({
            ...bnfSelecionado,
            dataNascimento: val,
          });
        } else {
          setNovoBeneficiario({
            ...dadosNovoBeneficiario,
            dataNascimento: val,
          });
        }
      }
    }
  }
  function handleOnChange(e, flag) {
    let val = e;
    if (val.length === 2) {
      val += '/';
    } else if (val.length === 5) {
      val += '/';
    } else if (val.length > 10) {
      if (flag === 1) {
        val = bnfSelecionado.dataNascimento;
      } else {
        val = dadosNovoBeneficiario.dataNascimento;
      }
    }
    if (flag === 1) {
      setBnfSelecionado({
        ...bnfSelecionado,
        dataNascimento: val,
      });
    } else {
      setNovoBeneficiario({
        ...dadosNovoBeneficiario,
        dataNascimento: val,
      });
    }
  }

  function handleOnKeyDownCPF(e, flag) {
    if (e.which === 8) {
      let val = e.target.value;
      if (val.length === 4 || val.length === 8 || val.length === 12) {
        val = val.slice(0, val.length - 1);
        if (flag === 1) {
          setBnfSelecionado({
            ...bnfSelecionado,
            cpf: val,
          });
        } else {
          setNovoBeneficiario({
            ...dadosNovoBeneficiario,
            cpf: val,
          });
        }
      }
    }
  }
  function handleOnChangeCPF(e, flag) {
    let val = e;
    if (val.length === 3) {
      val += '.';
    } else if (val.length === 7) {
      val += '.';
    } else if (val.length === 11) {
      val += '-';
    } else if (val.length > 14) {
      if (flag === 1) {
        val = bnfSelecionado.cpf;
      } else {
        val = dadosNovoBeneficiario.cpf;
      }
    }
    if (flag === 1) {
      setBnfSelecionado({
        ...bnfSelecionado,
        cpf: val,
      });
    } else {
      setNovoBeneficiario({
        ...dadosNovoBeneficiario,
        cpf: val,
      });
    }
  }

  let soma = 0;
  if (dadosBeneficiarios.indexOf() >= 0) {
    const aaa = dadosBeneficiarios.map((bnf) => bnf.pctRateio);
    for (let i = 0; i < aaa.length; i += 1) {
      soma += aaa[i];
    }
  }

  function handleValidaProporcao(prop, s, flag) {
    if (prop < 1 || prop > s) {
      setMsg(`O % de rateio deve ser maior que 1 e menor que ${s}`);
      setAlerta(true);
    } else if (flag === 1) {
      setBnfSelecionado({
        ...bnfSelecionado,
        pctRateio: prop,
      });
    } else {
      setNovoBeneficiario({
        ...dadosNovoBeneficiario,
        pctRateio: prop,
      });
    }
  }

  return (
    <SubmenuCorpoFrame>
      <ModalEdit visible={edit}>
        <popup-inner>
          <div style={{ direction: 'rtl' }}>
            <button
              type="button"
              style={{
                background: '#FFF',
                border: 'none',
                position: 'fixed',
                marginRight: '-22px',
                marginTop: '-22px',
                height: '25px',
                width: '25px',
                borderRadius: '50%',
                padding: '0',
              }}
              onClick={() => setEdit(false)}
            >
              <RiCloseCircleFill color="#c00202" size={25} />
            </button>
          </div>
          <dados-beneficiarios-linha-titulo>
            <dados-beneficiarios-titulo>
              Editar beneficiário legal
            </dados-beneficiarios-titulo>
          </dados-beneficiarios-linha-titulo>
          <dados-beneficiarios-bloco>
            <dados-beneficiarios-bloco-parte01>
              <form>
                <dados-beneficiarios-bloco-parte02>
                  <dados-beneficiarios-item>
                    <DadosBnfItemLabel>Nome</DadosBnfItemLabel>
                    <dados-beneficiarios-form>
                      <dados-beneficiarios-form-frame>
                        <input
                          name="nome"
                          type="text"
                          defaultValue={bnfSelecionado.nome}
                          onChange={(e) =>
                            setBnfSelecionado({
                              ...bnfSelecionado,
                              nome: e.target.value,
                            })
                          }
                        />
                      </dados-beneficiarios-form-frame>
                    </dados-beneficiarios-form>
                  </dados-beneficiarios-item>
                  <dados-beneficiarios-item>
                    <DadosBnfItemLabel>Tipo</DadosBnfItemLabel>
                    <dados-beneficiarios-form>
                      <dados-beneficiarios-form-frame>
                        <select
                          value={bnfSelecionado.codTipo}
                          onChange={(e) =>
                            setBnfSelecionado({
                              ...bnfSelecionado,
                              codTipo: e.target.value,
                            })
                          }
                        >
                          <option value="">Selecione...</option>
                          <option value="L">Legal</option>
                          <option value="I">Indicado</option>
                        </select>
                      </dados-beneficiarios-form-frame>
                    </dados-beneficiarios-form>
                  </dados-beneficiarios-item>
                  <dados-beneficiarios-item>
                    <DadosBnfItemLabel>CPF</DadosBnfItemLabel>
                    <dados-beneficiarios-form>
                      <dados-beneficiarios-form-frame>
                        <input
                          name="cpf"
                          type="text"
                          value={bnfSelecionado.cpf}
                          onKeyDown={(e) => handleOnKeyDownCPF(e, 1)}
                          onChange={(e) => handleOnChangeCPF(e.target.value, 1)}
                        />
                      </dados-beneficiarios-form-frame>
                    </dados-beneficiarios-form>
                  </dados-beneficiarios-item>
                </dados-beneficiarios-bloco-parte02>

                <dados-beneficiarios-bloco-parte02>
                  <dados-beneficiarios-item>
                    <DadosBnfItemLabel>Parentesco</DadosBnfItemLabel>
                    <dados-beneficiarios-form>
                      <dados-beneficiarios-form-frame>
                        <select
                          defaultValue={bnfSelecionado.grauParentesco}
                          onChange={(e) =>
                            setBnfSelecionado({
                              ...bnfSelecionado,
                              grauParentesco: e.target.value,
                            })
                          }
                        >
                          <option value="">Selecione...</option>
                          {parentList}
                        </select>
                      </dados-beneficiarios-form-frame>
                    </dados-beneficiarios-form>
                  </dados-beneficiarios-item>
                  <dados-beneficiarios-item>
                    <DadosBnfItemLabel>Data de nascimento</DadosBnfItemLabel>
                    <dados-beneficiarios-form>
                      <dados-beneficiarios-form-frame>
                        <input
                          name="nascimento"
                          type="text"
                          value={bnfSelecionado.dataNascimento}
                          onKeyDown={(e) => handleOnKeyDown(e, 1)}
                          onChange={(e) => handleOnChange(e.target.value, 1)}
                        />
                      </dados-beneficiarios-form-frame>
                    </dados-beneficiarios-form>
                  </dados-beneficiarios-item>
                  <dados-beneficiarios-item>
                    <DadosBnfItemLabel>% de rateio</DadosBnfItemLabel>
                    <dados-beneficiarios-form>
                      <dados-beneficiarios-form-frame>
                        <input
                          name="rateio"
                          value={
                            bnfSelecionado.pctRateio === undefined ||
                            bnfSelecionado.pctRateio === null
                              ? ''
                              : parseFloat(bnfSelecionado.pctRateio).toFixed(0)
                          }
                          type="number"
                          min="1"
                          max={soma === 0 ? 100 : 100 - soma}
                          onChange={(e) =>
                            handleValidaProporcao(
                              e.target.valueAsNumber,
                              soma === 0 ? 100 : 100 - soma,
                              1
                            )
                          }
                        />
                      </dados-beneficiarios-form-frame>
                    </dados-beneficiarios-form>
                  </dados-beneficiarios-item>
                </dados-beneficiarios-bloco-parte02>
              </form>
            </dados-beneficiarios-bloco-parte01>
          </dados-beneficiarios-bloco>
          <div>
            <SubmenuBotaoEditar type="button" onClick={() => handleEditar()}>
              Alterar
            </SubmenuBotaoEditar>
            <SubmenuBotaoEditar type="button" onClick={() => setEdit(!edit)}>
              Cancelar
            </SubmenuBotaoEditar>
          </div>
        </popup-inner>
      </ModalEdit>

      <ModalEdit visible={incluir}>
        <popup-inner>
          <div style={{ direction: 'rtl' }}>
            <button
              type="button"
              style={{
                background: '#FFF',
                border: 'none',
                position: 'fixed',
                marginRight: '-22px',
                marginTop: '-22px',
                height: '25px',
                width: '25px',
                borderRadius: '50%',
                padding: '0',
              }}
              onClick={() => setIncluir(false)}
            >
              <RiCloseCircleFill color="#c00202" size={25} />
            </button>
          </div>
          <dados-beneficiarios-linha-titulo>
            <dados-beneficiarios-titulo>
              Incluir novo beneficiário legal
            </dados-beneficiarios-titulo>
          </dados-beneficiarios-linha-titulo>
          <dados-beneficiarios-bloco>
            <dados-beneficiarios-bloco-parte01>
              <form>
                <dados-beneficiarios-bloco-parte02>
                  <dados-beneficiarios-item>
                    <DadosBnfItemLabel>Tipo</DadosBnfItemLabel>
                    <dados-beneficiarios-form>
                      <dados-beneficiarios-form-frame>
                        <select
                          onChange={(e) =>
                            setNovoBeneficiario({
                              ...dadosNovoBeneficiario,
                              codTipo: e.target.value,
                            })
                          }
                        >
                          <option value="">Selecione...</option>
                          <option value="L">Legal</option>
                          <option value="I">Indicado</option>
                        </select>
                      </dados-beneficiarios-form-frame>
                    </dados-beneficiarios-form>
                  </dados-beneficiarios-item>
                  <dados-beneficiarios-item>
                    <DadosBnfItemLabel>Nome</DadosBnfItemLabel>
                    <dados-beneficiarios-form>
                      <dados-beneficiarios-form-frame>
                        <input
                          name="newnome"
                          type="text"
                          onChange={(e) =>
                            setNovoBeneficiario({
                              ...dadosNovoBeneficiario,
                              nome: e.target.value,
                            })
                          }
                        />
                      </dados-beneficiarios-form-frame>
                    </dados-beneficiarios-form>
                  </dados-beneficiarios-item>
                  <dados-beneficiarios-item>
                    <DadosBnfItemLabel>CPF</DadosBnfItemLabel>
                    <dados-beneficiarios-form>
                      <dados-beneficiarios-form-frame>
                        <input
                          name="newcpf"
                          type="text"
                          value={dadosNovoBeneficiario.cpf}
                          onKeyDown={(e) => handleOnKeyDownCPF(e, 2)}
                          onChange={(e) => handleOnChangeCPF(e.target.value, 2)}
                        />
                      </dados-beneficiarios-form-frame>
                    </dados-beneficiarios-form>
                  </dados-beneficiarios-item>
                </dados-beneficiarios-bloco-parte02>

                <dados-beneficiarios-bloco-parte02>
                  <dados-beneficiarios-item>
                    <DadosBnfItemLabel>Parentesco</DadosBnfItemLabel>
                    <dados-beneficiarios-form>
                      <dados-beneficiarios-form-frame>
                        <select
                          onChange={(e) =>
                            setNovoBeneficiario({
                              ...dadosNovoBeneficiario,
                              grauParentesco: e.target.value,
                            })
                          }
                        >
                          <option value="">Selecione...</option>
                          {dadosNovoBeneficiario.codTipo === 'L'
                            ? parentListLegal
                            : parentListIndicado}
                        </select>
                      </dados-beneficiarios-form-frame>
                    </dados-beneficiarios-form>
                  </dados-beneficiarios-item>
                  <dados-beneficiarios-item>
                    <DadosBnfItemLabel>Data de nascimento</DadosBnfItemLabel>
                    <dados-beneficiarios-form>
                      <dados-beneficiarios-form-frame>
                        <input
                          name="newnascimento"
                          type="text"
                          value={dadosNovoBeneficiario.dataNascimento}
                          onKeyDown={(e) => handleOnKeyDown(e, 2)}
                          onChange={(e) => handleOnChange(e.target.value, 2)}
                        />
                      </dados-beneficiarios-form-frame>
                    </dados-beneficiarios-form>
                  </dados-beneficiarios-item>
                  <dados-beneficiarios-item>
                    <DadosBnfItemLabel>% de rateio</DadosBnfItemLabel>
                    <dados-beneficiarios-form>
                      <dados-beneficiarios-form-frame>
                        <input
                          name="newrateio"
                          value={
                            dadosNovoBeneficiario.pctRateio === undefined ||
                            dadosNovoBeneficiario.pctRateio === null ||
                            dadosNovoBeneficiario.codTipo !== 'I'
                              ? ''
                              : parseFloat(
                                  dadosNovoBeneficiario.pctRateio
                                ).toFixed(0)
                          }
                          type="number"
                          /* min="1"
                          max={soma === 0 ? 100 : 100 - soma} */
                          onChange={(e) =>
                            handleValidaProporcao(
                              e.target.valueAsNumber,
                              soma === 0 ? 100 : 100 - soma,
                              2
                            )
                          }
                          disabled={dadosNovoBeneficiario.codTipo !== 'I'}
                        />
                      </dados-beneficiarios-form-frame>
                    </dados-beneficiarios-form>
                  </dados-beneficiarios-item>
                </dados-beneficiarios-bloco-parte02>
              </form>
            </dados-beneficiarios-bloco-parte01>
          </dados-beneficiarios-bloco>
          <div>
            <SubmenuBotaoEditar type="button" onClick={() => handleIncluir()}>
              Incluir
            </SubmenuBotaoEditar>
            <SubmenuBotaoEditar
              type="button"
              onClick={() => setIncluir(!incluir)}
            >
              Cancelar
            </SubmenuBotaoEditar>
          </div>
        </popup-inner>
      </ModalEdit>

      <ModalAlert visible={alerta}>
        <popup-inner>
          <pop-up-sair-texto>{msg}</pop-up-sair-texto>
          <div>
            <button type="button" onClick={HandleToggleAlerta}>
              OK
            </button>
          </div>
        </popup-inner>
      </ModalAlert>
      <submenu-bloco>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="inherit">Nome</TableCell>
                <TableCell align="center">Tipo</TableCell>
                <TableCell align="center">CPF</TableCell>
                <TableCell align="center">Parentesco</TableCell>
                <TableCell align="center">Data de nascimento</TableCell>
                <TableCell align="center">% a ser recebido</TableCell>
                <TableCell align="center" />
                <TableCell align="center" />
              </TableRow>
            </TableHead>
            <TableBody>
              {dadosBeneficiarios === undefined ||
              dadosBeneficiarios === null ||
              dadosBeneficiarios === [] ||
              dadosBeneficiarios === '[]' ||
              dadosBeneficiarios === '' ? (
                <TableRow>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center" />
                  <TableCell align="center" />
                </TableRow>
              ) : (
                dadosBeneficiarios.map((row, index) => (
                  <TableRow key={row.nome}>
                    <TableCell align="inherit">
                      {row.nome == null || row.nome === undefined
                        ? '-'
                        : row.nome}
                    </TableCell>
                    <TableCell align="inherit">
                      {row.tipo == null || row.tipo === undefined
                        ? '-'
                        : row.tipo}
                    </TableCell>
                    <TableCell align="inherit" style={{ width: '108px' }}>
                      {row.cpf == null || row.cpf === undefined
                        ? '-'
                        : Cpf(row.cpf)}
                    </TableCell>
                    <TableCell align="inherit">
                      {row.dcrGrauParentesco == null ||
                      row.dcrGrauParentesco === undefined
                        ? '-'
                        : row.dcrGrauParentesco}
                    </TableCell>
                    <TableCell align="center">
                      {row.dataNascimento == null ||
                      row.dataNascimento === undefined
                        ? '-'
                        : row.dataNascimento}
                    </TableCell>
                    <TableCell align="center">
                      {row.pctRateio == null ||
                      row.pctRateio === undefined ||
                      parseFloat(row.pctRateio).toFixed(0) === 0 ||
                      parseFloat(row.pctRateio).toFixed(0) === '0'
                        ? '-'
                        : parseFloat(row.pctRateio).toFixed(0)}
                    </TableCell>
                    <TableCell align="inherit">
                      <SubmenuBotaoEditar
                        type="button"
                        onClick={() => handleAbreEditar(index)}
                      >
                        Editar
                      </SubmenuBotaoEditar>
                    </TableCell>
                    <TableCell align="inherit">
                      <SubmenuBotaoExcluir
                        type="button"
                        onClick={() => handleExcluir(row.pessoaBenef)}
                      >
                        Excluir
                      </SubmenuBotaoExcluir>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </submenu-bloco>
      <submenu-bloco>
        <SubmenuBotaoNovoBnf onClick={() => handleAbreIncluir()}>
          Incluir novo beneficiário legal
        </SubmenuBotaoNovoBnf>
      </submenu-bloco>
      <submenu-bloco>
        <submenu-titulos>Observações</submenu-titulos>
        <submenu-item-conteudo-ajustado>
          <SubmenuObservacoes>
            <p>
              <b>Beneficiário Legal:</b> cônjuge, companheiro(as) e filhos,
              incluindo enteado e adotado(a) legalmente. O percentual a ser
              recebido pelos beneficiários legais será dividido em partes
              iguais.
            </p>
            <p>
              <b>Beneficiário Indicado:</b> na ausência de beneficiários legais,
              é possível indicação e determinar a proporção que caberá a cada
              indicado.
            </p>
            <p>
              <b>Obs: </b>Caso o percentual de beneficiário indicado seja
              inferior ou superior a 100%, no momento do pagamento será efetuado
              o recálculo da divisão de forma proporcional.
            </p>
            {/* <p>
              <b>
                O percentual a ser recebido pelos beneficiários legais será
                dividido em partes iguais.
              </b>
            </p>
            <p>
              São considerados beneficiários legais: cônjuge, companheiro(a),
              filhos e enteados menores de 21 (vinte e um) anos ou 24 (vinte e
              quatro) anos de idade, se estiverem cursando ensino superior. Não
              há limite de idade para filho total e permanentemente inválido.{' '}
            </p>
            <p>
              Na ausência de beneficiários legais, o participante poderá indicar
              beneficiários para recebimento dos valores previstos em
              Regulamento. Para mais informações,{' '}
              <a
                href="https://prevpepsico.com.br/wp-content/uploads/pdfs/Regulamento_030517.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                leia os Capítulos II e IV do Regulamento do Plano de Benefícios
                Pepsico.
              </a>
            </p> */}
          </SubmenuObservacoes>
        </submenu-item-conteudo-ajustado>
      </submenu-bloco>
    </SubmenuCorpoFrame>
  );
}

export default Bnf;
