import React from 'react';
import '../../../config/ReactotronConfig';
import {
  Bloco,
  DadosPesssoaisItemLabel,
  DadosPesssoaisItemInfo,
} from '../styles';

function BlocoDadosProfissionais(props, n) {
  const dadosOrganizacao = props;

  if (props === undefined) {
    return <></>;
  }

  /* const vinculo = dadosOrganizacao[n].vinculoOrganizacao;
  let vinculoEdit = '-';

  if (vinculo === 1) {
    vinculoEdit = 'Admissão - Primeiro Emprego';
  } else if (vinculo === 2) {
    vinculoEdit = 'Admissão - Não Primeiro Emprego';
  } else if (vinculo === 3) {
    vinculoEdit = 'Transf. - com Ônus para Cedente';
  } else if (vinculo === 4) {
    vinculoEdit = 'Transf. - sem Ônus para Cedente';
  } else if (vinculo === 5) {
    vinculoEdit = 'Reintegração';
  } else if (vinculo === 6) {
    vinculoEdit = 'Recondução';
  } else if (vinculo === 7) {
    vinculoEdit = 'Reversão ou Readaptação';
  } else if (vinculo === 9) {
    vinculoEdit = 'Outros';
  } else vinculoEdit = '-'; */

  return (
    <>
      <Bloco>
        <dados-pessoais-bloco-parte01>
          <dados-pessoais-bloco-parte02>
            <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>Empresa</DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>
                {dadosOrganizacao[n].organizacao == null
                  ? '-'
                  : dadosOrganizacao[n].organizacao}
              </DadosPesssoaisItemInfo>
            </dados-pesssoais-item>
            <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>Localidade</DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>
                {dadosOrganizacao[n].lotacao == null
                  ? '-'
                  : dadosOrganizacao[n].lotacao}
              </DadosPesssoaisItemInfo>
            </dados-pesssoais-item>
            <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>
                Data de desligamento
              </DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>
                {dadosOrganizacao[n].dataDesligamento == null
                  ? '-'
                  : dadosOrganizacao[n].dataDesligamento}
              </DadosPesssoaisItemInfo>
            </dados-pesssoais-item>
            {/* <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>Justa causa?</DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>
                {dadosOrganizacao[n].justaCausa == null
                  ? '-'
                  : dadosOrganizacao[n].just}
              </DadosPesssoaisItemInfo>
            </dados-pesssoais-item> */}
          </dados-pessoais-bloco-parte02>
          <dados-pessoais-bloco-parte02>
            {/* <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>Matrícula</DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>
                {dadosOrganizacao[n].matricula == null
                  ? '-'
                  : dadosOrganizacao[n].matricula}
              </DadosPesssoaisItemInfo>
            </dados-pesssoais-item> */}
            <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>
                Data de admissão
              </DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>
                {dadosOrganizacao[n].dataAdmissao == null
                  ? '-'
                  : dadosOrganizacao[n].dataAdmissao}
              </DadosPesssoaisItemInfo>
            </dados-pesssoais-item>
            {/* <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>
                Motivo do desligamento
              </DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>
                {dadosOrganizacao[n].motivoDesligamento == null
                  ? '-'
                  : dadosOrganizacao[n].motivoDesligamento}
              </DadosPesssoaisItemInfo>
            </dados-pesssoais-item>
            <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>
                Vínculo com a organização
              </DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>{vinculoEdit}</DadosPesssoaisItemInfo>
            </dados-pesssoais-item> */}
          </dados-pessoais-bloco-parte02>
        </dados-pessoais-bloco-parte01>
      </Bloco>
    </>
  );
}

export default BlocoDadosProfissionais;
