import React from 'react';
import {
  SecaoFooter,
  FooterTelefonesLegenda,
  FooterTelefonesTexto,
} from './styles';
import Logo from '../../assets/prev-pepsico-logo-positivo.svg';

function FooterAuth() {
  return (
    <SecaoFooter>
      <secao-footer-parte1>
        <secao-footer-squares>
          <square-footer-roxo />
          <square-footer-laranja />
          <square-footer-verde />
          <square-footer-cyan />
        </secao-footer-squares>
      </secao-footer-parte1>
      <secao-footer-parte2>
        <footer-logo-1>
          <img src={Logo} alt="logo" size="12px" />
        </footer-logo-1>
      </secao-footer-parte2>
      <secao-footer-parte3>
        {/* <footer-telefones-item>
          <FooterTelefonesTexto>11 3289 2729</FooterTelefonesTexto>
          <FooterTelefonesLegenda>
            apenas para a cidade de São Paulo
          </FooterTelefonesLegenda>
        </footer-telefones-item>
        <footer-telefones-divisoria /> */}
        <footer-telefones-item>
          <FooterTelefonesTexto>0800 326 0929</FooterTelefonesTexto>
          <FooterTelefonesLegenda>
            São Paulo capital e demais regiões do país
          </FooterTelefonesLegenda>
        </footer-telefones-item>
        <footer-telefones-divisoria />
        <footer-telefones-item>
          <FooterTelefonesTexto>11 3905 5579</FooterTelefonesTexto>
          <FooterTelefonesLegenda>WhatsApp</FooterTelefonesLegenda>
          <footer-telefones-divisoria />
        </footer-telefones-item>
      </secao-footer-parte3>
    </SecaoFooter>
  );
}

export default FooterAuth;
