import produce from 'immer';

const EstadoInicial = {
  dadosMenu: {
    extrato: null,
    emprestimo: null,
    simulResgate: null,
    beneficiarios: null,
    contraCheque: null,
    boleto: null,
    infRendimentos: null,
    formularios: null,
    renovacaoContribuicao: null,
  },
};

export default function controleMenu(state = EstadoInicial, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@dadosMenu/LOAD_DADOS_PERMISSAO_MENU': {
        draft.dadosMenu = action.payload.dadosMenu;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.dadosMenu = {
          extrato: null,
          emprestimo: null,
          simulResgate: null,
          beneficiarios: null,
          contraCheque: null,
          boleto: null,
          infRendimentos: null,
          formularios: null,
          renovacaoContribuicao: null,
        };
        break;
      }
      default:
    }
  });
}
