import React, { useState } from 'react'; // , {  useEffect }
import '../../config/ReactotronConfig';
import { Link } from 'react-router-dom';
// import { FcHome } from 'react-icons/fc';
import { useDispatch } from 'react-redux';
import { IoMdClose } from 'react-icons/io';
import { SecaoHeader, ModalPlano } from './styles';
import Logo from '../../assets/prev-pepsico-logo-positivo.svg';
import { store } from '../../store';
import { pegaIndex } from '../../store/modules/visaoGeral/actions';

import Menu from '../Menu';

function Header() {
  const [trocaPlano, setTrocaPlano] = useState(false);

  let page = '';
  if (window.location.pathname === '/main') {
    page = ' início';
  } else if (window.location.pathname === '/dados-pessoais') {
    page = ' dados pessoais';
  } else if (window.location.pathname === '/alterar-senha') {
    page = ' alterar senha';
  } else if (window.location.pathname === '/eleicoes') {
    page = ' eleições';
  }

  const { nome } = store.getState().visaoGeral;
  const primNome = nome.trim().split(' ')[0];

  const { dadosPlano } = store.getState().visaoGeral.profile;

  const { indice } = store.getState().visaoGeral;
  let dtsList = '';

  if (
    dadosPlano === undefined ||
    dadosPlano === null ||
    dadosPlano === [] ||
    dadosPlano === '[]'
  ) {
    dtsList = <option value="0"> </option>;
  } else {
    dtsList = dadosPlano.map((n, index) => (
      <option value={index}>{`Matrícula ${n.matricula} - ${
        n.tipoParticipante === undefined ? '' : n.tipoParticipante
      }`}</option>
    ));
  }

  const dispatch = useDispatch();

  function handletesteee(v) {
    dispatch(pegaIndex(v));
  }

  function handleTrocaPlano() {
    setTrocaPlano(!trocaPlano);
  }

  return (
    <SecaoHeader>
      <secao-header-parte00>
        <secao-header-parte01>
          <header-logo>
            <Link to="/main">
              <img src={Logo} height="50px" alt="logo" />
              {/* <FcHome size="50px" alt="logo" /> */}
            </Link>
          </header-logo>
        </secao-header-parte01>
        <secao-header-parte02>
          <div-block-4>
            <header-nome-area>Área do participante</header-nome-area>
            <header-breadcrumb>\{page}</header-breadcrumb>
          </div-block-4>
          <header-identificacao>
            <header-ola>Olá,</header-ola>
            <header-nome-participante>{primNome}</header-nome-participante>
          </header-identificacao>
        </secao-header-parte02>
        <secao-header-parte03>
          <titulo-plano>Matrícula: {dadosPlano[indice].matricula}</titulo-plano>
          {dadosPlano.length > 1 ? (
            <label-a onClick={handleTrocaPlano}>
              Selecionar outra matrícula
            </label-a>
          ) : (
            <></>
          )}
        </secao-header-parte03>
        <secao-header-menu>
          <Menu />
        </secao-header-menu>
        <secao-header-parte04>
          <secao-header-squares>
            <square-roxo />
            <square-laranja />
            <square-verde />
            <square-cyan />
          </secao-header-squares>
        </secao-header-parte04>
      </secao-header-parte00>
      <secao-header-parte05 />
      <ModalPlano trocaPlano={trocaPlano}>
        <popup-inner>
          <icone-sair>
            <IoMdClose size={20} color="#818181" onClick={handleTrocaPlano} />
          </icone-sair>
          <select onChange={(e) => handletesteee(e.target.value)}>
            <option value="">Escolha o plano</option>
            {dtsList}
          </select>
        </popup-inner>
      </ModalPlano>
    </SecaoHeader>
  );
}

export default Header;
