import { takeLatest, call, put, all } from 'redux-saga/effects';

import { Base64 } from 'js-base64';

import api from '../../../services/api';
import { pegaVisaoGeral, mudaIndex } from './actions';
import { signFailure } from '../auth/actions';
import history from '../../../services/history';

function* buscaVisaoGeral({ payload }) {
  try {
    const { token } = payload;
    const parametrosGet = Base64.encode(`{"token": "${token}"}`);

    const parametrosFinalGet = Base64.encode(parametrosGet);

    const responseGet = yield call(
      api.get,
      `/wsVisaoGeral.rule?sys=PRT&Entrada=${parametrosFinalGet}`
    );
    const dadosPlano = responseGet.data;

    const { patrocinadora } = responseGet.data.dadosPlano[0];
    const { matricula } = responseGet.data.dadosPlano[0];
    const { codPlano } = responseGet.data.dadosPlano[0];

    const parametrosPost = Base64.encode(`{
      "token": "${token}",
      "patrocinadora": "${patrocinadora}",
      "matricula": "${matricula}",
      "plano": "${codPlano}"
    }`);

    const parametrosFinalPost = Base64.encode(parametrosPost);

    const responsePost = yield call(
      api.post,
      `/wsVisaoGeral.rule?sys=PRT&Entrada=${parametrosFinalPost}`
    );

    const nomeParticipante = responsePost.data.nome;

    yield put(pegaVisaoGeral(dadosPlano, nomeParticipante));
    // yield put(pegaDadosMenu(token, codPlano, patrocinadora, matricula));
    history.push('/main');
  } catch (err) {
    yield put(signFailure());
    // console.tron.log(`Erro. Payload: ${payload}`);
  }
}

function* pegaIndex({ payload }) {
  yield put(mudaIndex(payload.index));
  // alert('testeeeeeindex');
  document.location.reload(true);
}

export default all([
  takeLatest('@auth/SIGN_IN_SUCCESS', buscaVisaoGeral),
  takeLatest('@visaoGeral/PEGA_INDEX', pegaIndex),
]);
