/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
import React, { useState, useEffect } from 'react';
import { Base64 } from 'js-base64';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
// import { IoMdClose } from 'react-icons/io';
// import {
//   // HiOutlineEmojiHappy,
//   // HiOutlineEmojiSad,
//   HiHeart,
// } from 'react-icons/hi';
import api from '../../services/api';
import { store } from '../../store';
import {
  PainelCorpo,
  PainelItem,
  Divisoria,
  PainelCorpoLista,
  // PainelCorpoAvatar,
  PainelCorpoSombra,
  ModalConfirmacao,
  // ModalPesquisa,
} from './styles';
import IconePainel from '../../assets/icone-painel.svg';
import IconeSair from '../../assets/icone-sair.svg';
import { signOut } from '../../store/modules/auth/actions';

// function resizeBase64Img(base64, newWidth, newHeight) {
//   return new Promise((resolve, reject) => {
//     const canvas = document.createElement('canvas');
//     const ctx = canvas.getContext('2d');
//     canvas.width = newWidth;
//     canvas.height = newHeight;
//     const img = document.createElement('img');
//     img.src = base64;
//     ctx.drawImage(img, 0, 0, newWidth, newHeight);
//     resolve(canvas.toDataURL());
//   });
// }

function Menu() {
  const [visibleMenu, setVisibleMenu] = useState(false);
  const [visibleAvatar, setVisibleAvatar] = useState(false);
  const [sair, setSair] = useState(false);
  // const [showPesquisa] = useState(false);
  // const [nivel, setNivel] = useState(0);
  // const [preview, setPreview] = useState(IconePainel);
  const [avatar, setAvatar] = useState(IconePainel);
  const [temEleicao, setTemEleicao] = useState(false);

  const { indice } = store.getState().visaoGeral;
  const { token } = store.getState().auth;
  const { patrocinadora } = store.getState().visaoGeral.profile.dadosPlano[
    indice
  ];
  const { codPlano } = store.getState().visaoGeral.profile.dadosPlano[indice];
  const { matricula } = store.getState().visaoGeral.profile.dadosPlano[indice];

  const dispatch = useDispatch();

  async function urlCertificado() {
    try {
      const parametros = Base64.encode(
        `{"token": "${token}", "patrocinadora": "${patrocinadora}", "plano": "${codPlano}", "matricula": "${matricula}"}`
      );
      const parametrosFinal = Base64.encode(parametros);

      const response = await api.get(
        `/wsCertificadoParticipante.rule?sys=PRT&Entrada=${parametrosFinal}`
      );

      window.open(response.data.urlCertificado);
    } catch (err) {
      alert(
        'Não foi possível gerar o certificado agora. Tente novamente mais tarde.'
      );
    }
  }

  function handleSignOut() {
    dispatch(signOut());
  }

  function HandleToggleVisibleMenu() {
    setVisibleMenu(!visibleMenu);
    setVisibleAvatar(false);
  }

  // function HandleToggleVisibleAvatar() {
  //   setVisibleAvatar(!visibleAvatar);
  //   setVisibleMenu(false);
  // }

  function HandleToggleSair() {
    setSair(!sair);
  }
  /* function HandleToggleShowPesquisa() {
    alert('teste'); // setPesquisa(!pesquisa);
  } */
  // function HandleToggleNivel(v) {
  //   setNivel(v);
  // }

  // function handleChange(e) {
  //   const file = e.target.files[0];

  //   /* setPreview(URL.createObjectURL(file));
  //   setTesteImagem(file.size);

  //   const fileReader = new FileReader();
  //   fileReader.onloadend = function aux() {
  //     setPreview(fileReader.result);
  //   };
  //   fileReader.readAsDataURL(file);
  // }

  // async function handleGravarAvatar() {
  //   /* resizeBase64Img(preview, 50, 50).then((result) => {
  //     setTesteImagem(result);
  //   }); */
  //   try {
  //     resizeBase64Img(preview, 50, 50).then(async (result) => {

  //       const parametros = Base64.encode(
  //         `{"token": "${token}", "teste": "${result}"}`
  //       );
  //       const parametrosFinal = Base64.encode(parametros);

  //       setAvatar(result);
  //       /* const response = await api.get(
  //         `/wsAvatar.rule?sys=PRT&Entrada=${parametrosFinal}`,
  //         preview
  //       ); */
  //     });
  //   } catch (err) {
  //     alert('deu ruim');
  //   }
  // }

  useEffect(() => {
    async function verificaEleicao() {
      try {
        const parametros = Base64.encode(`{
          "token": "${token}"
        }`);
        const parametrosFinal = Base64.encode(parametros);

        await api
          .get(`/wsEleicao.rule?sys=PRT&Entrada=${parametrosFinal}`)
          .then((res) =>
            res.data[0] !== undefined && res.data[0].codCargo > 0
              ? setTemEleicao(true)
              : setTemEleicao(false)
          )
          .catch(setTemEleicao(false));
      } catch {
        setTemEleicao(false);
      }
    }
    verificaEleicao();
  }, [token]);

  return (
    <>
      <ModalConfirmacao sair={sair}>
        <popup-inner>
          <icone-sair>
            <img src={IconeSair} alt="IconeSair" size={50} />
          </icone-sair>
          <pop-up-sair-titulo>Deseja realmente sair?</pop-up-sair-titulo>
          <div>
            <button onClick={handleSignOut}>Sim</button>
            <button onClick={HandleToggleSair}>Não</button>
          </div>
        </popup-inner>
      </ModalConfirmacao>
      {/* * ************ * */}
      {/* <ModalPesquisa pesquisa={showPesquisa}>
        <popup-inner>
          <icone-sair>
            <div onClick={handleSignOut}>
              <IoMdClose size={20} color="#818181 " />
            </div>
          </icone-sair>
          <label>Como você avalia o portal?</label>
          <div>
            <HiHeart
              size={nivel === '1' ? 50 : 40}
              color="#D93232"
              onClick={() => HandleToggleNivel('1')}
            />
            <HiHeart
              size={nivel === '2' ? 50 : 40}
              color="#F26241"
              onClick={() => HandleToggleNivel('2')}
            />
            <HiHeart
              size={nivel === '3' ? 50 : 40}
              color="#F29F05"
              onClick={() => HandleToggleNivel('3')}
            />
            <HiHeart
              size={nivel === '4' ? 50 : 40}
              color="#D4D955"
              onClick={() => HandleToggleNivel('4')}
            />
            <HiHeart
              size={nivel === '5' ? 50 : 40}
              color="#64B968"
              onClick={() => HandleToggleNivel('5')}
            />
          </div>
          <label>Você conseguiu fazer o que queria?</label>
          <div>
            <button>sim</button>
            <button>não</button>
          </div>
          <textarea placeholder="o que você não conseguiu fazer?" />
          {/* <pop-up-sair-titulo>OLHA A PESQUISA AÍ</pop-up-sair-titulo> *
          <div>
            <button onClick={handleSignOut}>Enviar</button>
          </div>
        </popup-inner>
      </ModalPesquisa> */}
      {/* * ************ * */}
      <PainelCorpo>
        <painel-corpo-parte01>
          <header-painel-frame>
            <painel-icone>
              {/* onClick={HandleToggleVisibleAvatar} */}
              {/* <label> */}
              <img src={avatar} alt="IconePainel" />
              {/* </label> */}
            </painel-icone>
            <painel-p onClick={HandleToggleVisibleMenu}>
              <painel-titulo>Painel</painel-titulo>
              <painel-seta> {visibleMenu ? '⇧' : '⇩'}</painel-seta>
            </painel-p>
          </header-painel-frame>
        </painel-corpo-parte01>
        <PainelCorpoLista
          visible={visibleMenu}
          size={temEleicao ? 'big' : 'normal'}
        >
          <Divisoria />
          <painel-corpo-parte02 onClick={HandleToggleVisibleMenu}>
            <PainelItem>
              <Link to="/dados-pessoais">Dados pessoais</Link>
            </PainelItem>
            <PainelItem onClick={urlCertificado}>
              <label>Certificado de participante</label>
            </PainelItem>
            <PainelItem>
              <Link to="/alterar-senha">Alterar senha</Link>
            </PainelItem>
            {temEleicao ? (
              <PainelItem>
                <Link to="/eleicoes">Eleições</Link>
              </PainelItem>
            ) : (
              <></>
            )}
            <PainelItem onClick={HandleToggleSair}>
              <label>Sair</label>
            </PainelItem>
          </painel-corpo-parte02>
        </PainelCorpoLista>
        {/* <PainelCorpoAvatar visible={visibleAvatar}>
          <Divisoria />
          <painel-corpo-parte02-avatar onClick={HandleToggleVisibleAvatar}>
            <painel-foto>
              <label htmlFor="avatar">
                <img src={preview} alt="IconePainel" />
                <input
                  type="file"
                  id="avatar"
                  accept="image/*"
                  onChange={(e) => handleChange(e)}
                />
              </label>
            </painel-foto>
            <foto-item>Carregar uma nova foto</foto-item>
            <alterar-foto-frame-botoes>
              <foto-botao onClick={handleGravarAvatar}>Gravar</foto-botao>
              <foto-botao-cancelar onClick={HandleToggleVisibleAvatar}>
                Cancelar
              </foto-botao-cancelar>
            </alterar-foto-frame-botoes>
          </painel-corpo-parte02-avatar>
        </PainelCorpoAvatar> */}
      </PainelCorpo>

      <PainelCorpoSombra
        visible={visibleMenu || visibleAvatar}
        size={temEleicao ? 'big' : 'normal'}
      />
    </>
  );
}

export default Menu;
