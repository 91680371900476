import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

export default (reducers) => {
  const persistedReducer = persistReducer(
    {
      key: 'portaldoparticipante',
      storage,
      whtelist: ['auth', 'visaoGeral', 'controleMenu'],
    },
    reducers
  );
  return persistedReducer;
};
