import styled from 'styled-components';

export const Container = styled.div`
  font-weight: 300;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /*min-height: 100vh;*/
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  @media screen and (max-width: 991px) {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 0px;
  }

  eleicoes-frame {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 60%;
    min-height: 83vh;
    padding: 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-right: 1px solid #fe5a17;
    border-left: 1px solid #fe5a17;
    @media screen and (max-width: 991px) {
      width: 85%;
    }
    @media screen and (max-width: 767px) {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  botao-inicio {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 20px;
    margin-bottom: 15px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
  }

  botao-inicio-icone {
    margin-right: 10px;
  }

  botao-inicio-label {
    width: 90px;
    color: #fe5a17;
    font-size: 12px;
  }
  eleicoes-linha-titulo {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 55px;
    margin-bottom: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #0c2d83;
    @media screen and (max-width: 767px) {
      height: 45px;
    }
  }

  eleicoes-titulo {
    padding-right: 25px;
    padding-left: 25px;
    color: #fff;
    font-size: 32px;
    @media screen and (max-width: 767px) {
      font-size: 28px;
    }
  }

  eleicoes-texto-intro {
    width: 100%;
    color: #0c2d83;
    text-align: left;
    margin-top: 0;
    margin-bottom: 15px;
    padding-left: 0;
    padding-right: 25px;
    font-size: 16px;
    line-height: 1.4;
  }

  eleicoes-chevron-bloco {
    width: 100%;
    margin-bottom: 15px;
    display: flex;
  }
  eleicoes-chevron {
    width: 33%;
    height: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;

    eleicoes-chevron-label {
      color: #fff;
      font-size: 18px;
      z-index: 50;
    }
    > img {
      z-index: 1;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0%;
      bottom: 0%;
      left: 0%;
      right: 0%;
    }
  }
`;

export const EleicoesCorpo = styled.div`
  width: 100%;
  border: 1px #000;
  border-left: 3px solid #fe5a17;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  display: flex;
  @media screen and (max-width: 767px) {
    display: inline-block;
  }

  eleicoes-subtitulo {
    color: #fe5a17;
    font-size: 16px;
    font-weight: 700;
    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
  eleicoes-subtitulo-2 {
    color: #000;
    font-size: 16px;
    font-weight: 700;
  }

  > p {
    margin-top: 15px;
    font-size: 12px;
  }

  eleicoes-botao-bloco {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    display: flex;
  }
  eleicoes-botao-bloco-direita {
    width: 100%;
    align-items: center;
    margin-top: 25px;
    display: flex;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    justify-content: flex-end;
  }

  eleicoes-texto-escolha {
    width: 100%;
    height: 30px;
    border: 1px solid #fe5a17;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    display: flex;
    color: #fe5a17;
    font-size: 12px;
    font-weight: 700;
  }

  eleicoes-nomes-bloco {
    width: 100%;
    display: flex;
  }

  eleicoes-nomes-coluna {
    width: 50%;
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    flex-direction: column;
    display: flex;
  }

  eleicoes-cv-placeholder {
    width: 90%;
    height: 300px;
    border-top: 1px solid #fe5a17;
    border-bottom: 1px solid #fe5a17;
    margin-top: 10px;
    padding: 10px;
  }
  image-2 {
    width: 100px;
    height: 100px;
    display: flex;
  }
  eleicoes-msg-sucesso-bloco {
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
    display: flex;
  }
  eleicoes-msg-sucesso-box {
    width: 100%;
    height: 60px;
    background-color: #fe5a17;
    border: 1px solid #fe5a17;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    display: flex;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
  }
`;

export const BotaoEleicoes = styled.button`
  width: 150px;
  height: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #818181;
  transition: background-color 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
  text-align: center;
  font-weight: 600;
  display: inline-block;
  padding: 9px 15px;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;

  &:hover {
    background-color: #fe5a17;
  }
`;
export const BotaoEleicoesPequeno = styled.button`
  width: 75px;
  height: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #818181;
  transition: background-color 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
  text-align: center;
  font-weight: 600;
  display: inline-block;
  padding: 9px 15px;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;

  &:hover {
    background-color: #fe5a17;
  }
`;

export const ModalAlert = styled.div`
  position: fixed;
  z-index: 999;
  display: none;
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 99;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);

  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};

  popup-inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 350px;
    height: 180px;
    padding: 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    align-items: center;
  }

  pop-up-sair-texto {
    margin-top: 10px;
    margin-bottom: 10px;
    color: #fe5a17;
    font-size: 16px;
  }

  pop-sair-frame-parte02 {
  }

  button {
    width: 100px;
    margin-right: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #818181;
    -webkit-transition: background-color 200ms
      cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: background-color 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    text-align: center;
    padding: 9px 15px;
    color: white;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;

    &:hover {
      background-color: #fe5a17;
    }
  }
`;

export const WCheckbox = styled.div`
  display: flex;
  margin-bottom: 5px;
  margin-left: 0px;
  cursor: pointer;
  > label {
    margin-left: 4px;
  }
`;

export const SubmenuLabelsCheck = styled.div`
  color: #000;
  margin-top: 2px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  margin-left: 5px;
`;

export const EleicoesDisclaimer = styled.p`
  margin-top: 15px;
  font-size: 12px;
`;
