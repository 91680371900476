import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import Route from './Route';

import Main from '../pages/Main';
import Login from '../pages/Login';
import DadosPessoais from '../pages/DadosPessoais';
import AlterarSenha from '../pages/AlterarSenha';
import Eleicoes from '../pages/Eleicoes';
import RecuperarSenha from '../pages/RecuperarSenha';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/esqueci-minha-senha" component={RecuperarSenha} />
        <Route path="/main" component={Main} isPrivate />
        <Route path="/dados-pessoais" component={DadosPessoais} isPrivate />
        <Route path="/eleicoes" component={Eleicoes} isPrivate />
        <Route path="/alterar-senha" component={AlterarSenha} isPrivate />
      </Switch>
    </BrowserRouter>
  );
}
