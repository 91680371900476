import React, { useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import Collapse from '@material-ui/core/Collapse';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import { Base64 } from 'js-base64';
import {
  BlocoCadastro,
  DadosPesssoaisItemLabel,
  // CorpoForm,
  CampoInput,
  Div,
  BotaoCadastrar,
  ModalAlert,
} from '../styles';
import api from '../../../services/api';
import { store } from '../../../store';

const schema = Yup.object().shape({
  email: Yup.string().email('Insira um eimail válido.'),
});

function CadastrarEmail() {
  const [visible, setVisible] = useState(false);
  const [eee, setEmail] = useState('');
  const [msg, setMsg] = useState('');
  const [alerta, setAlerta] = useState(false);
  const [flag, setFlag] = useState(false);

  const { token } = store.getState().auth;

  async function handleAddEmail() {
    const parametros = Base64.encode(
      `{"token": "${token}", "email": "${eee}"}`
    );
    const parametrosFinal = Base64.encode(parametros);
    await api
      .post(`/wsEmail.rule?sys=PRT&Entrada=${parametrosFinal}`)
      .then(() => {
        setMsg('E-mail atualizado com sucesso');
        setAlerta(true);
        setFlag(true);
      })
      .catch(() => {
        setMsg(
          'Não foi possível atualizar o e-mail agora. Tente novamente em alguns minutos.'
        );
        setAlerta(true);
      });
  }

  function HandleToggleVisible() {
    setVisible(!visible);
  }

  function HandleToggleAlerta() {
    setAlerta(!alerta);
    if (flag === true) {
      document.location.reload(true);
    }
  }

  return (
    <BlocoCadastro>
      <ModalAlert visible={alerta}>
        <popup-inner>
          <pop-up-sair-texto>{msg}</pop-up-sair-texto>
          <div>
            <button type="button" onClick={HandleToggleAlerta}>
              OK
            </button>
          </div>
        </popup-inner>
      </ModalAlert>
      <dados-pessoais-abrir-cadastro onClick={HandleToggleVisible}>
        <dados-pessoais-subtitulo-white>
          Atualizar e-mail pessoal
        </dados-pessoais-subtitulo-white>
        <cadastro-botao-frame>
          <cadastro-botao visible={visible}>
            <AiOutlinePlus
              size={30}
              style={
                visible
                  ? {
                      transform: 'rotate(-135deg)',
                      transition: 'transform .2s ease-in-out',
                    }
                  : {
                      transform: 'rotate(0deg)',
                      transition: 'transform .2s ease-in-out',
                    }
              }
            />
          </cadastro-botao>
          {/* <cadastro-botao-barra-horizontal />
          <cadastro-botao-barra-vertical /> */}
        </cadastro-botao-frame>
      </dados-pessoais-abrir-cadastro>
      <Div>
        <Collapse in={visible}>
          <Form schema={schema} onSubmit={handleAddEmail}>
            <dados-pessoais-bloco-parte02-100>
              <dados-pesssoais-item-endereco>
                <DadosPesssoaisItemLabel>e-mail</DadosPesssoaisItemLabel>
                <dados-pessoais-form>
                  <dados-pessoais-form-frame>
                    <CampoInput>
                      <Input
                        type="text"
                        name="email"
                        placeholder="Inserir info"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </CampoInput>
                    <BotaoCadastrar type="submit">Atualizar</BotaoCadastrar>
                  </dados-pessoais-form-frame>
                </dados-pessoais-form>
              </dados-pesssoais-item-endereco>
            </dados-pessoais-bloco-parte02-100>
          </Form>
        </Collapse>
      </Div>
    </BlocoCadastro>
  );
}

export default CadastrarEmail;
