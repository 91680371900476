import React, { useState } from 'react';

import IconeDocs from '../../assets/icone-relatorios-e-documentos-2.svg';
import { SubmenuCorpoFrame } from './styles';

// eslint-disable-next-line react/prop-types
function Documentos({ forms }) {
  const [lista] = useState(forms);

  let listaDocs = '';
  if (lista === [] || lista === undefined || lista === null || lista === '[]') {
    listaDocs = '';
  } else {
    listaDocs = lista.map((n) => (
      <relatorios-e-documentos-item-frame onClick={() => window.open(n.link)}>
        <img src={IconeDocs} alt="IconeDocs1" height="75px" />
        <relatorios-e-documentos-item-divisoria />
        <relatorios-e-documentos-item-titulo>
          {n.nome}
        </relatorios-e-documentos-item-titulo>
      </relatorios-e-documentos-item-frame>
    ));
  }

  return (
    <SubmenuCorpoFrame>
      <submenu-bloco>{listaDocs}</submenu-bloco>
    </SubmenuCorpoFrame>
  );
}

export default Documentos;
