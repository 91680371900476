import React from 'react';
import '../../../config/ReactotronConfig';
import {
  Bloco,
  DadosPesssoaisItemLabel,
  DadosPesssoaisItemInfo,
} from '../styles';

function BlocoAdesao(props, n) {
  const dadosPlano = props;

  if (props === undefined) {
    return <></>;
  }

  return (
    <>
      <Bloco>
        <dados-pessoais-subtitulo>Adesão ao plano</dados-pessoais-subtitulo>
        <dados-pessoais-bloco-parte01>
          <dados-pessoais-bloco-parte02>
          <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>Plano</DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>
                {dadosPlano[n].plano == null ? '-' : dadosPlano[n].plano}
              </DadosPesssoaisItemInfo>
            </dados-pesssoais-item>
            <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>Patrocinadora</DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>
                {dadosPlano[n].patrocinadora == null
                  ? '-'
                  : dadosPlano[n].patrocinadora}
              </DadosPesssoaisItemInfo>
            </dados-pesssoais-item>
            <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>
                Status
              </DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>
                {dadosPlano[n].tipoParticipacao == null
                  ? '-'
                  : dadosPlano[n].tipoParticipacao}
              </DadosPesssoaisItemInfo>
            </dados-pesssoais-item>
            <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>
                Início da vigência
              </DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>
                {dadosPlano[n].inicioVigencia == null
                  ? '-'
                  : dadosPlano[n].inicioVigencia}
              </DadosPesssoaisItemInfo>
            </dados-pesssoais-item>
            <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>Fim da vigência</DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>
                {dadosPlano[n].fimVigencia == null
                  ? '-'
                  : dadosPlano[n].fimVigencia}
              </DadosPesssoaisItemInfo>
            </dados-pesssoais-item>
            {/* <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>
                Situação previdenciária
              </DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>
                {dadosPlano[n].situacaoPrevidenciaria == null
                  ? '-'
                  : dadosPlano[n].situacaoPrevidenciaria}
              </DadosPesssoaisItemInfo>
            </dados-pesssoais-item> */}
            {/* <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>Data de opção</DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>
                {dadosPlano[n].dataOpcao == null
                  ? '-'
                  : dadosPlano[n].dataOpcao}
              </DadosPesssoaisItemInfo>
            </dados-pesssoais-item> */}
          </dados-pessoais-bloco-parte02>
          <dados-pessoais-bloco-parte02>
            {/* <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>Matrícula</DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>
                {dadosPlano[n].matricula == null
                  ? '-'
                  : dadosPlano[n].matricula}
              </DadosPesssoaisItemInfo>
            </dados-pesssoais-item> */}
            <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>Regime de Tributação</DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>
                {dadosPlano[n].opcaoTributaria == null
                  ? '-'
                  : dadosPlano[n].opcaoTributaria}
              </DadosPesssoaisItemInfo>
            </dados-pesssoais-item>
            <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>Data de opção</DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>
                {dadosPlano[n].dataOpcao == null
                  ? '-'
                  : dadosPlano[n].dataOpcao}
              </DadosPesssoaisItemInfo>
            </dados-pesssoais-item>
          </dados-pessoais-bloco-parte02>
        </dados-pessoais-bloco-parte01>
      </Bloco>
    </>
  );
}

export default BlocoAdesao;
