import { takeLatest, call, put, all } from 'redux-saga/effects';

import { Base64 } from 'js-base64';

import history from '../../../services/history';
import api from '../../../services/api';
import { signInSuccess, signFailure } from './actions';

function* signIn({ payload }) {
  try {
    const { idCliente, cpfUsuario, senhaUsuario } = payload;
    const parametros = Base64.encode(
      `{"cliente":"${idCliente}", "usuario":"${cpfUsuario
        .replaceAll('.', '')
        .replaceAll('-', '')
        .replaceAll(' ', '')}","senha":"${senhaUsuario}","sistema":"PRT"}`
    );

    const parametrosFinal = Base64.encode(parametros);

    const response = yield call(
      api.post,
      `/wsAutenticacao.rule?sys=PRT&Entrada=${parametrosFinal}`
    );

    const { token } = response.data;

    yield put(signInSuccess(token));
  } catch (err) {
    yield put(signFailure());
  }
}

function signOut() {
  history.push('/');
}

export default all([
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_OUT', signOut),
]);
