/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import { Base64 } from 'js-base64';
import { Valor } from '../../services/masks';

import {
  SubmenuCorpoFrame,
  Form,
  ContribuicaoField,
  SubmenuBotao,
  // SubmenuBotaoContribuicao,
  SliderContribuicao,
  ModalAlert,
  SubmenuObservacoes,
} from './styles';
import { store } from '../../store';
import api from '../../services/api';

function Flexibilização({ dadosCtb }) {
  const dadoscontrib = dadosCtb[0];
  const [codRecad] = useState(dadosCtb[1]);
  const [salario] = useState(dadosCtb[2]);
  const [suplementar, setSuplementar] = useState(
    dadoscontrib.filter((d) => d.conta === 1 || d.conta === 31)[0]
  );
  const [adicional, setAdicional] = useState(
    dadoscontrib.filter((d) => d.conta === 2 || d.conta === 32)[0]
  );

  const [percentSupl, setPercentSupl] = useState(
    suplementar === undefined ? 0 : suplementar.percentualAtual
  );
  const [percentAdic, setPercentAdic] = useState(
    adicional === undefined ? 0 : adicional.percentualAtual
  );
  const [vlrContSupl, setVlrContSupl] = useState(
    suplementar === undefined
      ? 0
      : (suplementar.valorBase * (suplementar.percentualAtual / 100)).toFixed(2)
  );
  const [vlrContAdic, setVlrContAdic] = useState(
    adicional === undefined
      ? 0
      : (salario * (adicional.percentualAtual / 100)).toFixed(2)
  );
  const [msg, setMsg] = useState('');
  const [alerta, setAlerta] = useState(false);
  const URP = 753.24; // 725.23;

  const valorBase = salario - 7 * URP;

  // console.log(adicional);

  if (suplementar === undefined) {
    setSuplementar({
      conta: 1,
      percentualAtual: 0,
      percentualMaximo: null,
      percentualMinimo: null,
      valorBase,
    });
  }
  if (adicional === undefined) {
    setAdicional({
      conta: 2,
      percentualAtual: 0,
      percentualMaximo: null,
      percentualMinimo: null,
      valorBase: salario,
    });
  }

  let maxSup = '';
  if (
    suplementar === undefined ||
    suplementar.percentualMaximo === null ||
    suplementar.percentualMaximo === undefined ||
    suplementar.percentualMaximo === ''
  ) {
    maxSup = salario >= 7 * URP ? 5 : 2;
  } else {
    maxSup = suplementar.percentualMaximo;
  }

  let minSup = '';
  if (
    suplementar === undefined ||
    suplementar.percentualMinimo === null ||
    suplementar.percentualMinimo === undefined ||
    suplementar.percentualMinimo === ''
  ) {
    minSup = 0;
  } else {
    minSup = suplementar.percentualMinimo;
  }

  let maxAdc = '';
  if (
    adicional === undefined ||
    adicional.percentualMaximo === null ||
    adicional.percentualMaximo === undefined ||
    adicional.percentualMaximo === ''
  ) {
    maxAdc = 20;
  } else {
    maxAdc = adicional.percentualMaximo;
  }

  let minAdc = '';
  if (
    adicional === undefined ||
    adicional.percentualMinimo === null ||
    adicional.percentualMinimo === undefined ||
    adicional.percentualMinimo === ''
  ) {
    minAdc = 0;
  } else {
    minAdc = adicional.percentualMinimo;
  }
  const marksSuplementar = [];
  if (salario >= 7 * URP) {
    for (let i = minSup; i <= maxSup; i += 0.5) {
      marksSuplementar.push({ value: i, label: i });
    }
  } else {
    for (let i = 0; i <= 2; i += 2) {
      marksSuplementar.push({ value: i, label: i });
    }
  }

  const marksAdicional = [];
  for (let i = minAdc; i <= maxAdc; i += 1) {
    marksAdicional.push({ value: i, label: i });
  }

  const { indice } = store.getState().visaoGeral;
  const t = store.getState().auth.token;
  const dadosPlano = store.getState().visaoGeral.profile.dadosPlano[indice];
  const pl = dadosPlano.codPlano;
  const pt = dadosPlano.patrocinadora;
  const m = dadosPlano.matricula;

  async function HandleChangeContribuicao() {
    for (let x = 1; x <= 2; x += 1) {
      let recad = 0;
      let pctAnterior = 0;
      let pctAtual = 0;

      if (x === 1) {
        recad = codRecad;
        pctAnterior = suplementar.percentualAtual;
        pctAtual = percentSupl;
      } else if (x === 2) {
        recad = codRecad;
        pctAnterior = adicional.percentualAtual;
        pctAtual = percentAdic;
      }
      if (
        (x === 1 && percentSupl !== pctAnterior) ||
        (x === 2 && percentAdic !== pctAnterior)
      ) {
        const parametros = Base64.encode(
          `{"token": "${t}",
          "patrocinadora": "${pt}",
          "matricula": "${m}",
          "plano" : "${pl}",
          "recadastramento": ${recad},
          "conta": ${x},
          "percentualAnterior": "${pctAnterior}",
          "percentualAtual": "${pctAtual}"}`
        );

        const parametrosFinal = Base64.encode(parametros);
        // const response =
        // eslint-disable-next-line no-await-in-loop
        await api
          .post(
            `/wsRenovacaoContribuicao.rule?sys=PRT&Entrada=${parametrosFinal}`
          )
          .then((res) => {
            setMsg(res.data.mensagem);
            setAlerta(true);
          })
          .catch(() => {
            setMsg('Ops, algo deu errado. Tente novamente mais tarde.');
            setAlerta(true);
          });
      }
    }
  }

  /* async function HandleCancelaContribuicao() {
    const parametros = Base64.encode(
      `{"token": "${t}","patrocinadora": "${pt}","matricula": "${m}",
      "plano" : "${pl}","recadastramento": ${codRecad}}`
    );

    const parametrosFinal = Base64.encode(parametros);
    // const response =
    // eslint-disable-next-line no-await-in-loop
    await api
      .post(
        `/wsRenovacaoContribuicaoDELETE.rule?sys=PRT&Entrada=${parametrosFinal}`
      )
      .then((res) => {
        setMsg(res.data.mensagem);
        setAlerta(true);
        setPercentSupl(0);
        setVlrContSupl('0');
        setPercentAdic(0);
        setVlrContAdic('0');
      })
      .catch(() => {
        setMsg('Ops, algo deu errado. Tente novamente mais tarde.');
        setAlerta(true);
      });
  } */

  const handleChangeSuplementar = (event, newValue) => {
    setPercentSupl(newValue);
    const vlrAux = suplementar.valorBase * (newValue / 100);
    if (newValue === 0) {
      setVlrContSupl(0);
    } else if (vlrAux < URP * 0.14 && salario >= 7 * URP && vlrAux > 0) {
      setVlrContSupl((URP * 0.14).toFixed(2));
    } else if (vlrAux >= URP * 0.14 && salario >= 7 * URP && vlrAux > 0) {
      setVlrContSupl((suplementar.valorBase * (newValue / 100)).toFixed(2));
    } else if (salario < 7 * URP) {
      setVlrContSupl((salario * (newValue / 100)).toFixed(2));
    }
    if (newValue < maxSup) {
      setPercentAdic(0);
      setVlrContAdic('0');
    }
  };

  const handleChangeAdicional = (event, newValue) => {
    setPercentAdic(newValue);
    setVlrContAdic((salario * (newValue / 100)).toFixed(2));
  };

  function HandleToggleAlerta() {
    setAlerta(!alerta);
  }
  if (suplementar === undefined) {
    return (
      <submenu-bloco>
        <submenu-bloco-parte01>
          <label-observacao>
            Informações de contribuição indisponíveis no momeno.
          </label-observacao>
        </submenu-bloco-parte01>
      </submenu-bloco>
    );
  }
  return (
    <SubmenuCorpoFrame>
      <ModalAlert visible={alerta}>
        <popup-inner>
          <pop-up-sair-texto>{msg}</pop-up-sair-texto>
          <div>
            <button type="button" onClick={HandleToggleAlerta}>
              OK
            </button>
          </div>
        </popup-inner>
      </ModalAlert>
      {/** ******* */}
      {suplementar === undefined ? (
        <></>
      ) : (
        <submenu-bloco>
          <submenu-titulos>% contribuição suplementar</submenu-titulos>
          <submenu-bloco-parte01>
            {salario >= 7 * URP ? (
              <submenu-bloco-parte02>
                <SliderContribuicao
                  max={maxSup}
                  min={minSup}
                  value={percentSupl}
                  step={0.5}
                  valueLabelDisplay="auto"
                  marks={marksSuplementar}
                  onChange={handleChangeSuplementar}
                  // disabled={salario < 7 * URP}
                  // onChange={() => teste(1)}
                />
              </submenu-bloco-parte02>
            ) : (
              <submenu-bloco-parte02>
                <SliderContribuicao
                  max={maxSup}
                  min={minSup}
                  value={percentSupl}
                  step={2}
                  valueLabelDisplay="auto"
                  marks={marksSuplementar}
                  onChange={handleChangeSuplementar}
                  // disabled={salario < 7 * URP}
                  // onChange={() => teste(1)}
                />
              </submenu-bloco-parte02>
            )}
            <submenu-bloco-coluna-small>
              <submenu-item-coluna>
                <w-form>
                  <Form>
                    <ContribuicaoField
                      placeholder="00,00"
                      value={`${percentSupl} %`}
                    />
                  </Form>
                </w-form>
                <w-form>
                  <Form>
                    <ContribuicaoField
                      placeholder="00,00"
                      value={Valor(
                        `${
                          vlrContSupl < URP * 0.14 &&
                          vlrContSupl > 0 &&
                          salario >= URP * 7
                            ? (URP * 0.14).toFixed(2)
                            : vlrContSupl
                        }`
                      )}
                    />
                  </Form>
                </w-form>
              </submenu-item-coluna>
            </submenu-bloco-coluna-small>
          </submenu-bloco-parte01>
          <label-observacao>
            {`Para salários superiores a 7 URP, o valor mínimo de contribuição será
          de 0,14 URP, equivalente a ${Valor((URP * 0.14).toFixed(2))}.`}
          </label-observacao>
        </submenu-bloco>
      )}
      {adicional === undefined ? (
        <></>
      ) : (
        <submenu-bloco>
          <submenu-titulos>% contribuição adicional</submenu-titulos>
          <submenu-bloco-parte01>
            <submenu-bloco-parte02>
              <SliderContribuicao
                max={maxAdc}
                min={minAdc}
                value={percentAdic}
                step={0.5}
                valueLabelDisplay="auto"
                marks={marksAdicional}
                onChange={handleChangeAdicional}
                // onChange={teste(2)}
                // disabled={salario >= 7 * URP && percentSupl !== maxSup}
                disabled={percentSupl > 0 && percentSupl !== maxSup}
              />
            </submenu-bloco-parte02>
            <submenu-bloco-coluna-small>
              <submenu-item-coluna>
                <w-form>
                  <Form>
                    <ContribuicaoField
                      placeholder="00,00"
                      value={`${percentAdic} %`}
                    />
                  </Form>
                </w-form>
                <w-form>
                  <Form>
                    <ContribuicaoField
                      placeholder="00,00"
                      value={Valor(`${vlrContAdic}`)}
                    />
                  </Form>
                </w-form>
              </submenu-item-coluna>
            </submenu-bloco-coluna-small>
          </submenu-bloco-parte01>
        </submenu-bloco>
      )}
      <submenu-bloco>
        <submenu-bloco-parte01>
          <SubmenuBotao onClick={HandleChangeContribuicao}>
            Alterar % de contribuição
          </SubmenuBotao>
          {/* <SubmenuBotaoContribuicao onClick={HandleCancelaContribuicao}>
            Suspender contribuições
            </SubmenuBotaoContribuicao>
          <label-observacao>
            Participantes que recebem variável de vendas, o salário de
            participação será fixado nos meses de janeiro e julho, considerando
            a média dos últimos 6 meses
          </label-observacao> */}
        </submenu-bloco-parte01>
      </submenu-bloco>
      <submenu-bloco>
        <submenu-titulos>Observações</submenu-titulos>
        <submenu-item-conteudo-ajustado>
          {/* <SubmenuObservacoes>{`Valor da URP: ${Valor(
            URP.toFixed(2)
          )}`}</SubmenuObservacoes> */}
          <SubmenuObservacoes>
            Na <b>Contribuição Suplementar de Participante</b> a empresa
            contribuirá com 200% de contrapartida.
          </SubmenuObservacoes>
          <SubmenuObservacoes>
            Participantes que recebem variável de vendas, o salário de
            participação será fixado nos meses de janeiro e julho, considerando
            a média dos últimos 6 meses.
          </SubmenuObservacoes>
        </submenu-item-conteudo-ajustado>
      </submenu-bloco>
    </SubmenuCorpoFrame>
  );
}

export default Flexibilização;
