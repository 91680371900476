/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import { ValorMascarar } from '../../services/masks';
// import Collapse from '@material-ui/core/Collapse';
import { SubmenuCorpoFrame, SubmenuObservacoes, Divisoria } from './styles';

function Resgate({ dadosResg }) {
  // const [visible, setVisible] = useState(false);
  const [qtdParcelas, setQtdParcelas] = useState(null);
  const [pctResgateAV, setPctResgateAV] = useState('');
  const [valorParcela, setValorParcela] = useState(0);

  function handleVisible(v) {
    setQtdParcelas(v);
    // if (visible === '') {
    // setVisible(false);
    // } else {
    if (v === 0) {
      setPctResgateAV('100%');
      setValorParcela('0,00');
    } else {
      setPctResgateAV('0%');
      setValorParcela(
        ValorMascarar(
          `${(
            parseFloat(
              dadosResg.valorResgatavelTotal
                .replaceAll('.', '')
                .replace(',', '.'),
              10
            ) / v
          ).toFixed(2)}`
        )
      );
    }
    // setVisible(true);
    // }
  }

  return (
    <SubmenuCorpoFrame>
      <submenu-bloco>
        <submenu-titulos>Informações do cálculo</submenu-titulos>
        <frame-horizontal>
          <submenu-bloco-coluna-simulador-01>
            <submenu-coluna-labels-simulador> </submenu-coluna-labels-simulador>
            <submenu-coluna-labels-simulador>
              Participante
            </submenu-coluna-labels-simulador>
            <submenu-coluna-labels-simulador>
              Patrocinadora
            </submenu-coluna-labels-simulador>
            <submenu-coluna-labels-simulador>
              Total
            </submenu-coluna-labels-simulador>
            <Divisoria />
            <submenu-coluna-labels-simulador>
              Valor tributável
            </submenu-coluna-labels-simulador>
            <submenu-coluna-labels-simulador>
              Parcela isenta
            </submenu-coluna-labels-simulador>
          </submenu-bloco-coluna-simulador-01>
          <submenu-bloco-coluna-simulador-02>
            <form>
              <submenu-coluna-labels-simulador>
                Total (R$)
              </submenu-coluna-labels-simulador>
              <input
                type="text"
                placeholder="Valor"
                value={dadosResg.valorTotalPartic}
              />
              <input
                type="text"
                placeholder="Valor"
                value={dadosResg.valorTotalPatroc}
              />
              <input
                type="text"
                placeholder="Valor"
                value={dadosResg.valorTotal}
              />
              <Divisoria />
              <input
                type="text"
                placeholder="Valor"
                value={dadosResg.parteTributavel}
              />
              <input
                type="text"
                placeholder="Valor"
                value={dadosResg.ParteIsenta}
              />
            </form>
          </submenu-bloco-coluna-simulador-02>
          <submenu-bloco-coluna-simulador-03>
            <form>
              <submenu-coluna-labels-simulador>
                % resgatável
              </submenu-coluna-labels-simulador>
              <input
                type="text"
                placeholder="Valor"
                value={dadosResg.percentualResgatePatic}
              />
              <input
                type="text"
                placeholder="Valor"
                value={dadosResg.percentualResgatePatroc}
              />
              <input
                type="text"
                placeholder="Valor"
                value={dadosResg.percentualResgateTotal}
              />
            </form>
          </submenu-bloco-coluna-simulador-03>
          <submenu-bloco-coluna-simulador-04>
            <form>
              <submenu-coluna-labels-simulador>
                Valor resgatável (R$)
              </submenu-coluna-labels-simulador>
              <input
                type="text"
                placeholder="Valor"
                value={dadosResg.valorResgatavelPartic}
              />
              <input
                type="text"
                placeholder="Valor"
                value={dadosResg.valorResgatavelPartroc}
              />
              <input
                type="text"
                placeholder="Valor"
                value={dadosResg.valorResgatavelTotal}
              />
            </form>
          </submenu-bloco-coluna-simulador-04>
        </frame-horizontal>
      </submenu-bloco>
      <submenu-bloco-horizontal>
        <submenu-bloco-coluna-simulador-01>
          <submenu-coluna-labels-simulador>
            Quantidade de parcelas
          </submenu-coluna-labels-simulador>
          <submenu-coluna-labels-simulador>
            Valor bruto (R$)
          </submenu-coluna-labels-simulador>
        </submenu-bloco-coluna-simulador-01>
        <submenu-bloco-coluna-simulador-05>
          <form>
            <select
              onChange={(e) => handleVisible(parseInt(e.target.value, 10))}
            >
              <option value={null}>Escolha uma...</option>
              <option value={0}>1x</option>
              <option value={2}>2x</option>
              <option value={3}>3x</option>
              <option value={4}>4x</option>
              <option value={5}>5x</option>
              <option value={6}>6x</option>
              <option value={7}>7x</option>
              <option value={8}>8x</option>
              <option value={9}>9x</option>
              <option value={10}>10x</option>
              <option value={11}>11x</option>
              <option value={12}>12x</option>
            </select>
            <input
              type="text"
              placeholder="Valor"
              value={
                qtdParcelas === 0
                  ? `${dadosResg.valorResgatavelTotal}`
                  : valorParcela
              }
            />

            {/* <input
              type="text"
              disabled
              placeholder="Valor"
              value={pctResgateAV}
            /> */}
          </form>
        </submenu-bloco-coluna-simulador-05>
        <submenu-bloco-coluna-simulador-06>
          {/* <button
            type="button"
            disabled={qtdParcelas === ''}
            onClick={handleVisible}
          >
            Simular
          </button> */}
        </submenu-bloco-coluna-simulador-06>
      </submenu-bloco-horizontal>
      {/* <Collapse in={visible}> */}
      {/* <submenu-bloco>
        <submenu-titulos>Resultado da simulação</submenu-titulos>
        <frame-horizontal>
          <submenu-bloco-coluna-simulador-01>
            <submenu-coluna-labels-simulador> </submenu-coluna-labels-simulador>
            <submenu-coluna-labels-simulador>
              Parcelas
            </submenu-coluna-labels-simulador>
            <submenu-coluna-labels-simulador>
              % à vista
            </submenu-coluna-labels-simulador>
          </submenu-bloco-coluna-simulador-01>
          <form>
            <submenu-coluna-labels-simulador> </submenu-coluna-labels-simulador>
            <input
              type="text"
              placeholder="Qtd"
              value={
                qtdParcelas === 0 || qtdParcelas === null
                  ? '0'
                  : `${qtdParcelas}x`
              }
            />
            <input type="text" placeholder="%" value={pctResgateAV} />
          </form>
          <submenu-bloco-coluna-simulador-02>
            <form>
              <submenu-coluna-labels-simulador>
                Valor bruto (R$)
              </submenu-coluna-labels-simulador>
              <input type="text" placeholder="Valor" value={valorParcela} />
              <input
                type="text"
                placeholder="Valor"
                value={
                  qtdParcelas === 0
                    ? `${dadosResg.valorResgatavelTotal}`
                    : '0,00'
                }
              />
            </form>
          </submenu-bloco-coluna-simulador-02>

        </frame-horizontal>
      </submenu-bloco> */}
      <submenu-bloco>
        <submenu-titulos>Observações</submenu-titulos>
        <submenu-item-conteudo-ajustado>
          <SubmenuObservacoes>
            A simulação não representa promessa de rentabilidade ou garantia de
            nível de benefício.
          </SubmenuObservacoes>
        </submenu-item-conteudo-ajustado>
      </submenu-bloco>
      {/* </Collapse> */}
    </SubmenuCorpoFrame>
  );
}

export default Resgate;
