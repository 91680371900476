import React, { useEffect, useState } from 'react'; //
// import { useLocation } from 'react-router-dom';

import { Container } from './styles';

function BotaoTopo() {
  // const { pathname } = useLocation();

  const [visible, setVisible] = useState(false);
  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
  }, [visible]);

  function HandleToggleVisible() {
    setVisible(!visible);
  }

  return (
    <Container>
      <botao-top-link onClick={HandleToggleVisible}>⇧</botao-top-link>
    </Container>
  );
}

export default BotaoTopo;
