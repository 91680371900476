import styled from 'styled-components';

export const SecaoHeader = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;

  secao-header-parte00 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    box-shadow: 0 4px 3px 0 rgba(0, 0, 0, 0.25);
    @media screen and (max-width: 767px) {
      height: 80px;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
    > div {
      display: flex;
      width: 100%;
    }
  }

  secao-header-parte01 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 20%;
    height: 100%;
    padding-left: 50px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    @media screen and (max-width: 991px) {
      width: 20%;
      padding-left: 20px;
    }
    @media screen and (max-width: 767px) {
      width: 30%;
      padding-left: 20px;
    }
  }

  header-logo {
    height: 50px;
    img {
      height: 50px;
      @media screen and (max-width: 767px) {
        height: 42px;
      }
      @media screen and (max-width: 510px) {
        height: 36px;
      }
    }
  }

  secao-header-parte02 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 40%;
    height: 100%;
    padding-right: 25px;
    padding-left: 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    @media screen and (max-width: 991px) {
      width: 40%;
    }
    @media screen and (max-width: 767px) {
      display: none;
      width: 40%;
    }
  }

  secao-header-parte03 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    width: 20%;
    height: 100%;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    @media screen and (max-width: 991px) {
      width: 25%;
      padding-right: 44px;
    }
    @media screen and (max-width: 767px) {
      width: 33%;
      padding-right: 20px;
    }
    @media screen and (max-width: 510px) {
      width: 45%;
      padding-right: 12px;
    }

    titulo-plano {
      font-size: 16px;
      color: #fe5a17;
      font-weight: 600;
      padding-bottom: 5px;
      @media screen and (max-width: 991px) {
        font-size: 14px;
      }
      @media screen and (max-width: 767px) {
        font-size: 12px;
      }
    }

    label-a {
      font-size: 12px;
      color: #0c2d83;
      cursor: pointer;
      &:hover {
        color: #96005a;
      }
      @media screen and (max-width: 991px) {
        font-size: 10px;
      }
      @media screen and (max-width: 767px) {
        font-size: 8px;
      }
    }
  }

  secao-header-parte04 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    height: 100%;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    /* @media screen and (max-width: 991px) {
      width: 25%;
    }
    @media screen and (max-width: 767px) {
      width: 25%;
    }*/
  }

  secao-header-parte05 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  secao-header-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 16%;
    min-width: 180px;
    height: 100%;
    padding-right: 25px;
    padding-left: 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    @media screen and (max-width: 991px) {
      width: 25%;
      padding-left: 15px;
    }
    @media screen and (max-width: 767px) {
      width: 30%;
    }
    @media screen and (max-width: 510px) {
      width: 30%;
      min-width: 80px;
    }
  }

  div-block-4 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  header-nome-area {
    margin-bottom: 5px;
    color: #0c2d83;
    font-size: 15px;
  }
  header-breadcrumb {
    margin-bottom: 5px;
    margin-left: 10px;
    color: #fe5a17;
    font-size: 15px;
    font-weight: 700;
  }

  header-identificacao {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: flex;
    margin-top: 5px;
    padding-top: 2px;
    padding-right: 50px;
    padding-bottom: 2px;
    border-top: 1px solid #fe5a17;
  }

  header-ola {
    margin-right: 10px;
    color: #0c2d83;
    font-size: 28px;
  }

  header-nome-participante {
    color: #0c2d83;
    font-size: 28px;
  }

  secao-header-squares {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 25px;
    height: 100px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    @media screen and (max-width: 767px) {
      height: 80px;
    }
  }

  square-roxo {
    width: 25px;
    height: 25px;
    background-color: #96005a;
  }

  square-laranja {
    width: 25px;
    height: 25px;
    background-color: #fe5a17;
  }

  square-verde {
    width: 25px;
    height: 25px;
    background-color: #008240;
  }

  square-cyan {
    width: 25px;
    height: 25px;
    background-color: #0189b4;
  }
`;

export const ModalPlano = styled.div`
  position: fixed;
  z-index: 999;
  display: none;
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 99;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);

  visibility: ${(props) => (props.trocaPlano ? 'visible' : 'hidden')};

  popup-inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 400px;
    height: 130px;
    padding: 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: end;
    background-color: #fff;
    box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.4);
  }

  select {
    width: 150px;
    border-radius: 0px;
    background-color: #fff;
    color: #000;
    display: block;
    min-width: 80%;
    height: 38px;
    padding: 8px 12px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.428571429;
    border: solid 1px #fe5a17;
    align-self: center;
  }

  icone-sair {
    align-self: flex-end;
    cursor: pointer;
  }
`;
