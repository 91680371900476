import React, { useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import Collapse from '@material-ui/core/Collapse';
import IconeEmprestimo from '../../assets/icone-emprestimo-white.svg';
import Corpo from './corpo';
import { Container, CorpoEmprestimo } from './styles';

function Emprestimo() {
  const [visible, setVisible] = useState(false);

  function HandleToggleVisible() {
    setVisible(!visible);
  }

  return (
    <Container>
      <menu-header onClick={HandleToggleVisible}>
        <menu-header-parte01>
          <menu-icone>
            <img src={IconeEmprestimo} alt="IconeEmprestimo" />
          </menu-icone>
          <menu-titulo>Empréstimo</menu-titulo>
        </menu-header-parte01>
        <menu-header-parte02>
          <menu-botao>
            <botao-p visible={visible}>
              <AiOutlinePlus
                size={40}
                style={
                  visible
                    ? {
                        transform: 'rotate(-135deg)',
                        transition: 'transform .2s ease-in-out',
                      }
                    : {
                        transform: 'rotate(0deg)',
                        transition: 'transform .2s ease-in-out',
                      }
                }
              />
            </botao-p>
          </menu-botao>
        </menu-header-parte02>
      </menu-header>
      <CorpoEmprestimo>
        <Collapse in={visible}>
          <Corpo />
        </Collapse>
      </CorpoEmprestimo>
    </Container>
  );
}

export default Emprestimo;
