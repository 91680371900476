import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Base64 } from 'js-base64';
import api from '../../services/api';
import {
  Container,
  EleicoesCorpo,
  BotaoEleicoes,
  ModalAlert,
  WCheckbox,
  SubmenuLabelsCheck,
  EleicoesDisclaimer,
  BotaoEleicoesPequeno,
} from './styles';
import BotaoInicio from '../../assets/icone-home-orange.svg';
import ChevronCinza1 from '../../assets/chevron-cinza1.svg';
import ChevronAzul1 from '../../assets/chevron-azul1.svg';
import ChevronCinza2 from '../../assets/chevron-cinza2.svg';
import ChevronAzul2 from '../../assets/chevron-azul2.svg';
import Footer from '../../components/Footer';
import { store } from '../../store';

function Eleicoes() {
  const [page, setPage] = useState(1);
  const [votoEmAberto, setVotoEmAberto] = useState(true);
  const [eleicaoAtiva, setEleicaoAtiva] = useState(true);
  const [flagAceite, setFlagAceite] = useState(false);
  const [msg, setMsg] = useState('');
  const [alerta, setAlerta] = useState(false);
  const { token } = store.getState().auth;

  const [arrVoto] = useState([
    { cargo: null, voto: null, nome: '', foto: '', cv: '', dcrCargo: '' },
  ]);

  const [arrCandidatos] = useState([
    {
      codCargo: null,
      pessoa: null,
      nome: '',
      foto: '',
      curriculo: '',
    },
  ]);

  const [arrVotoDet, setArrVotoDet] = useState([
    { cargo: null, voto: null, nome: '', foto: '', cv: '', dcrCargo: '' },
  ]);

  function groupBy(array, key) {
    return array.reduce((hash, obj) => {
      if (obj[key] === undefined) return hash;
      return Object.assign(hash, {
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      });
    }, {});
  }

  const [retorno, setRetorno] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [txtCabecalho, setTxtCabecalho] = useState('');
  const [txtDisclaimer, setTxtDisclaimer] = useState('');

  useEffect(() => {
    async function verificaEleicao() {
      try {
        const parametros = Base64.encode(`{
          "token": "${token}"
        }`);
        const parametrosFinal = Base64.encode(parametros);

        await api
          .get(`/wsEleicao.rule?sys=PRT&Entrada=${parametrosFinal}`)
          .then((res) => {
            if (res.data[0] !== undefined && res.data[0].codCargo > 0) {
              setRetorno(res.data);
              const groupedArray = groupBy(res.data, 'cargo');
              setCargos(groupedArray);
              setTxtCabecalho(res.data[0].textoCabecalho);
              setTxtDisclaimer(res.data[0].textoDisclaimer);
              setEleicaoAtiva(true);
            } else {
              setEleicaoAtiva(false);
            }
          })
          // eslint-disable-next-line no-console
          .catch((res) => console.log(res));
      } catch {
        // setAlerta(false);
      }
    }
    verificaEleicao();
  }, [token]);

  async function handleConfirmarVoto() {
    try {
      const parametros = Base64.encode(
        `{"token": "${token}",
        "votos": [${arrVoto.map(
          (v) => `
          {"codCargo":"${v.cargo}","candidato":"${v.voto}"}`
        )}]
      }`
      );

      const parametrosFinal = Base64.encode(parametros);

      await api
        .post(`/wsEleicao.rule?sys=PRT&Entrada=${parametrosFinal}`)
        .then((res) => {
          setVotoEmAberto(false);
        })
        .catch(() => {
          setMsg('Voto não computado. Tente novamente mais tarde.');
          setAlerta(true);
        });
    } catch (err) {
      setMsg('Voto não computado. Tente novamente mais tarde.');
      setAlerta(true);
    }
  }

  const [, setBlocoDetalhes] = useState(
    <eleicoes-cv-placeholder>
      <image-2>
        <img
          src="https://assets.website-files.com/645a5a015b8c02027ae611cf/645a792bfbe64d363e5d2e68_silhueta-masculina.png"
          alt="imgpessoa"
        />
      </image-2>
      <EleicoesDisclaimer>
        <p>...</p>
      </EleicoesDisclaimer>
      <EleicoesDisclaimer>
        <p>Selecione um nome para visualizar as informações...</p>
      </EleicoesDisclaimer>
    </eleicoes-cv-placeholder>
  );

  const handleEscolhe = useCallback(
    (idx, checked, codCarg, codPessoa) => {
      arrVoto.forEach((item) => {
        if (item.cargo === codCarg) {
          if (checked) {
            item.voto = codPessoa;
            item.nome = arrCandidatos.filter(
              (c) => c.pessoa === codPessoa && c.codCargo === codCarg
            )[0].nome;
            item.foto = arrCandidatos.filter(
              (c) => c.pessoa === codPessoa && c.codCargo === codCarg
            )[0].foto;
            item.cv = arrCandidatos.filter(
              (c) => c.pessoa === codPessoa && c.codCargo === codCarg
            )[0].curriculo;
          } else {
            item.voto = null;
            item.nome = '...';
            item.foto =
              'https://assets.website-files.com/645a5a015b8c02027ae611cf/645a792bfbe64d363e5d2e68_silhueta-masculina.png';
            item.cv = 'Selecione um nome para visualizar as informações...';
          }
        }
      });
      setBlocoDetalhes(
        arrVotoDet === [] || arrVotoDet === undefined ? (
          <></>
        ) : (
          <eleicoes-cv-placeholder>
            <image-2>
              <img
                src={
                  arrCandidatos.filter(
                    (c) => c.pessoa === codPessoa && c.codCargo === codCarg
                  )[0].foto
                }
                alt="imgpessoa"
              />
            </image-2>
            <EleicoesDisclaimer>
              {arrVotoDet[idx].voto === undefined ||
              arrVotoDet[idx].voto === 0 ||
              arrVotoDet[idx].voto === null ||
              arrVotoDet[idx].voto === '' ? (
                <p>...</p>
              ) : (
                <p>
                  {
                    arrCandidatos.filter(
                      (c) => c.pessoa === codPessoa && c.codCargo === codCarg
                    )[0].nome
                  }
                </p>
              )}
            </EleicoesDisclaimer>
            <EleicoesDisclaimer>
              <p>
                {
                  arrCandidatos.filter(
                    (c) => c.pessoa === codPessoa && c.codCargo === codCarg
                  )[0].curriculo
                }
              </p>
            </EleicoesDisclaimer>
          </eleicoes-cv-placeholder>
        )
      );
    },
    [arrCandidatos, arrVoto, arrVotoDet, setBlocoDetalhes]
  );

  useEffect(() => {
    setArrVotoDet(arrVoto);
  }, [arrVoto, handleEscolhe]);

  function handlePage(pageAtual) {
    if (pageAtual === 1) {
      if (flagAceite) {
        setPage(2);
      } else {
        setMsg('Assinale o check para continuar');
        setAlerta(true);
      }
    } else if (pageAtual === 2) {
      const cont = arrVoto.length;
      for (let i = 0; i < cont; i += 1) {
        if (arrVoto[i].voto === null) {
          setMsg('Escolha os candidatos para continuar');
          setAlerta(true);
          return;
        }
      }
      setPage(3);
    }
  }

  let listaVotacao = '';
  if (
    retorno === [] ||
    retorno === undefined ||
    retorno === null ||
    retorno === '[]'
  ) {
    listaVotacao = '';
  } else {
    listaVotacao = Object.keys(cargos).map((n, idx) => (
      <eleicoes-nomes-coluna>
        <eleicoes-subtitulo>{n}</eleicoes-subtitulo>
        <form>
          {cargos[n].map((c) => (
            <div id={idx}>
              <WCheckbox>
                <input
                  type="radio"
                  id={`${idx}${c.codCargo}${c.pessoa}`}
                  name={`${idx}${c.codCargo}`}
                  value={c.pessoa}
                  checked={
                    arrVotoDet[idx] === undefined || arrVotoDet[idx] === null
                      ? false
                      : arrVotoDet[idx].voto === c.pessoa
                  }
                  onChange={(e) => {
                    handleEscolhe(idx, e.target.checked, c.codCargo, c.pessoa);
                  }}
                />
                <label htmlFor={`${idx}${c.codCargo}${c.pessoa}`}>
                  {c.nome}
                </label>
              </WCheckbox>
            </div>
          ))}
        </form>
        {arrVotoDet[idx] === undefined ||
        arrVotoDet[idx] === [] ||
        arrVotoDet[idx] === null ? (
          <eleicoes-cv-placeholder>
            <image-2>
              <img
                src="https://assets.website-files.com/645a5a015b8c02027ae611cf/645a792bfbe64d363e5d2e68_silhueta-masculina.png"
                alt="imgpessoa"
              />
            </image-2>
            <EleicoesDisclaimer>
              <p>...</p>
            </EleicoesDisclaimer>
            <EleicoesDisclaimer>
              <p>Selecione um nome para visualizar as informações...</p>
            </EleicoesDisclaimer>
          </eleicoes-cv-placeholder>
        ) : (
          <eleicoes-cv-placeholder>
            <image-2>
              <img
                src={
                  arrVotoDet[idx].foto === '' ||
                  arrVotoDet[idx].foto === undefined ||
                  arrVotoDet[idx].foto === null
                    ? 'https://assets.website-files.com/645a5a015b8c02027ae611cf/645a792bfbe64d363e5d2e68_silhueta-masculina.png'
                    : arrVotoDet[idx].foto
                }
                alt="imgpessoa"
              />
            </image-2>
            <EleicoesDisclaimer>
              <p>
                {arrVotoDet[idx].nome === '' ||
                arrVotoDet[idx].nome === undefined ||
                arrVotoDet[idx].nome === null
                  ? '...'
                  : arrVotoDet[idx].nome}
              </p>
            </EleicoesDisclaimer>
            <EleicoesDisclaimer>
              <p>
                {arrVotoDet[idx].cv === '' ||
                arrVotoDet[idx].cv === undefined ||
                arrVotoDet[idx].cv === null
                  ? 'Selecione um nome para visualizar as informações...'
                  : arrVotoDet[idx].cv}
              </p>
            </EleicoesDisclaimer>
          </eleicoes-cv-placeholder>
        )}
      </eleicoes-nomes-coluna>
    ));
  }

  const [listaPg3, setListaPg3] = useState(
    <eleicoes-nomes-coluna>
      <eleicoes-subtitulo>Cargo</eleicoes-subtitulo>
      <eleicoes-subtitulo-2>...</eleicoes-subtitulo-2>
    </eleicoes-nomes-coluna>
  );

  useEffect(() => {
    let lista3 = '';
    if (
      arrVotoDet !== [] &&
      arrVotoDet !== undefined &&
      arrVotoDet !== null &&
      arrVotoDet !== '[]'
    ) {
      lista3 = arrVotoDet.map((v) => (
        <eleicoes-nomes-coluna>
          <eleicoes-subtitulo>{v.dcrCargo}</eleicoes-subtitulo>
          <eleicoes-subtitulo-2>{v.nome}</eleicoes-subtitulo-2>
        </eleicoes-nomes-coluna>
      ));

      setListaPg3(lista3);
    }
  }, [arrVotoDet, page]);

  useEffect(() => {
    arrVoto.pop();
    Object.keys(cargos).map((n) =>
      arrVoto.push({
        cargo: cargos[n][0].codCargo,
        voto: null,
        nome: '...',
        foto: '',
        cv: '',
        dcrCargo: cargos[n][0].cargo,
      })
    );
  }, [arrVoto, cargos]);

  useEffect(() => {
    if (
      retorno !== [] &&
      retorno !== undefined &&
      retorno !== null &&
      retorno !== '[]'
    ) {
      arrCandidatos.pop();
      retorno.map((r) =>
        arrCandidatos.push({
          codCargo: r.codCargo,
          pessoa: r.pessoa,
          nome: r.nome,
          foto: r.foto,
          curriculo: r.curriculo,
          dcrCargo: r.cargo,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retorno]);

  function HandleToggleAlerta() {
    setAlerta(!alerta);
  }

  return (
    <>
      <Container>
        <ModalAlert visible={alerta}>
          <popup-inner>
            <pop-up-sair-texto>{msg}</pop-up-sair-texto>
            <div>
              <button type="button" onClick={HandleToggleAlerta}>
                OK
              </button>
            </div>
          </popup-inner>
        </ModalAlert>
        <eleicoes-frame>
          <eleicoes-linha-titulo>
            <eleicoes-titulo>Eleições 2023</eleicoes-titulo>
          </eleicoes-linha-titulo>
          {eleicaoAtiva || votoEmAberto ? (
            <>
              <eleicoes-texto-intro>{txtCabecalho}</eleicoes-texto-intro>
              <eleicoes-chevron-bloco>
                <eleicoes-chevron>
                  <eleicoes-chevron-label>Passo 1</eleicoes-chevron-label>
                  <img
                    src={page === 1 ? ChevronAzul1 : ChevronCinza1}
                    alt="1"
                  />
                </eleicoes-chevron>
                <eleicoes-chevron>
                  <eleicoes-chevron-label>Passo 2</eleicoes-chevron-label>
                  <img
                    src={page === 2 ? ChevronAzul2 : ChevronCinza2}
                    alt="2"
                  />
                </eleicoes-chevron>
                <eleicoes-chevron>
                  <eleicoes-chevron-label>Passo 3</eleicoes-chevron-label>
                  <img
                    src={page === 3 ? ChevronAzul2 : ChevronCinza2}
                    alt="3"
                  />
                </eleicoes-chevron>
              </eleicoes-chevron-bloco>
              {page === 1 ? (
                <EleicoesCorpo>
                  <eleicoes-subtitulo>
                    Disclaimer legal sobre o processo de votação
                  </eleicoes-subtitulo>
                  <p>{txtDisclaimer}</p>
                  <eleicoes-botao-bloco>
                    <WCheckbox>
                      <input
                        type="checkbox"
                        checked={flagAceite}
                        onChange={(e) => setFlagAceite(e.target.checked)}
                      />
                      <SubmenuLabelsCheck
                        onClick={() => setFlagAceite(!flagAceite)}
                      >
                        Estou ciente e de acordo com os termos apresentados
                      </SubmenuLabelsCheck>
                    </WCheckbox>
                    <BotaoEleicoes type="button" onClick={() => handlePage(1)}>
                      Iniciar a votação
                    </BotaoEleicoes>
                  </eleicoes-botao-bloco>
                </EleicoesCorpo>
              ) : (
                <></>
              )}
              {page === 2 ? (
                <EleicoesCorpo>
                  <eleicoes-texto-escolha>
                    Escolha um nome para o Conselho Fiscal e um nome para o
                    Conselho Deliberativo
                  </eleicoes-texto-escolha>
                  <eleicoes-nomes-bloco>{listaVotacao}</eleicoes-nomes-bloco>
                  <eleicoes-botao-bloco-direita>
                    <BotaoEleicoes
                      type="button"
                      onClick={() => {
                        handlePage(2);
                      }}
                    >
                      Selecionar
                    </BotaoEleicoes>
                  </eleicoes-botao-bloco-direita>
                </EleicoesCorpo>
              ) : (
                <></>
              )}
              {page === 3 ? (
                <EleicoesCorpo>
                  <eleicoes-texto-escolha>
                    {!votoEmAberto ? '' : 'Confirme os nomes que você escolheu'}
                  </eleicoes-texto-escolha>
                  <eleicoes-nomes-bloco>{listaPg3}</eleicoes-nomes-bloco>

                  {!votoEmAberto ? (
                    <eleicoes-msg-sucesso-bloco>
                      <eleicoes-msg-sucesso-box>
                        Votação realizada com sucesso. Obrigado por participar!
                      </eleicoes-msg-sucesso-box>
                      <Link to="/main">
                        <botao-inicio>
                          <botao-inicio-icone>
                            <img
                              src={BotaoInicio}
                              alt="BotaoInicio"
                              width="30"
                            />
                          </botao-inicio-icone>
                          <botao-inicio-label>
                            Voltar à página inicial
                          </botao-inicio-label>
                        </botao-inicio>
                      </Link>
                    </eleicoes-msg-sucesso-bloco>
                  ) : (
                    <eleicoes-botao-bloco-direita>
                      <BotaoEleicoesPequeno
                        type="button"
                        onClick={() => setPage(2)}
                      >
                        Voltar
                      </BotaoEleicoesPequeno>
                      <BotaoEleicoes
                        type="button"
                        onClick={() => handleConfirmarVoto()}
                      >
                        Confirmar votos
                      </BotaoEleicoes>
                    </eleicoes-botao-bloco-direita>
                  )}
                </EleicoesCorpo>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              {!eleicaoAtiva ? (
                <EleicoesCorpo>
                  <eleicoes-msg-sucesso-bloco>
                    <eleicoes-msg-sucesso-box>
                      Eleição não disponível
                    </eleicoes-msg-sucesso-box>
                    <Link to="/main">
                      <botao-inicio>
                        <botao-inicio-icone>
                          <img src={BotaoInicio} alt="BotaoInicio" width="30" />
                        </botao-inicio-icone>
                        <botao-inicio-label>
                          Voltar à página inicial
                        </botao-inicio-label>
                      </botao-inicio>
                    </Link>
                  </eleicoes-msg-sucesso-bloco>
                </EleicoesCorpo>
              ) : (
                <></>
              )}
            </>
          )}
        </eleicoes-frame>
      </Container>
      <Footer />
    </>
  );
}

export default Eleicoes;
