export function pegaVisaoGeral(dadosPlano, nomeParticipante) {
  return {
    type: '@visaoGeral/GET_DADOS_PLANO',
    payload: { dadosPlano, nomeParticipante },
  };
}
export function pegaDadosMenu(token, codPlano, patrocinadora, matricula) {
  return {
    type: '@visaoGeral/GET_DADOS_PERMISSAO_MENU',
    payload: { token, codPlano, patrocinadora, matricula },
  };
}

export function pegaIndex(index) {
  return {
    type: '@visaoGeral/PEGA_INDEX',
    payload: { index },
  };
}
export function mudaIndex(index) {
  return {
    type: '@visaoGeral/MUDA_INDEX',
    payload: { index },
  };
}
