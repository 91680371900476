import React from 'react';
import PropTypes from 'prop-types';

import HeaderAuth from '../../../components/HeaderAuth';
import FaleConosco from '../../../components/FaleConosco';

import { Container } from './styles';

export default function AuthLayout({ children }) {
  return (
    <Container>
      <HeaderAuth />
      <FaleConosco />
      {children}
    </Container>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
