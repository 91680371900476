import React from 'react'; // , { useState, useEffect }
import '../../config/ReactotronConfig';
import { Link } from 'react-router-dom';
import { SecaoHeader } from './styles';
import Logo from '../../assets/prev-pepsico-logo-positivo.svg';

function HeaderAuth() {
  return (
    <SecaoHeader>
      <secao-header-parte00>
        <secao-header-parte01>
          <header-logo-link>
            <Link to="/main">
              <img src={Logo} height="50px" alt="logo" />
              {/* <FcHome size="50px" alt="logo" /> */}
            </Link>
          </header-logo-link>
        </secao-header-parte01>
        <secao-header-parte02>
          <div-block-4 />
        </secao-header-parte02>
        <secao-header-parte03>
          <secao-header-squares>
            <square-roxo />
            <square-laranja />
            <square-verde />
            <square-cyan />
          </secao-header-squares>
        </secao-header-parte03>
      </secao-header-parte00>
      <secao-header-parte05 />
    </SecaoHeader>
  );
}

export default HeaderAuth;
