import styled from 'styled-components';

export const SecaoVisaoGeral = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  font-weight: 300;
  align-items: center;

  visao-geral-frame {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 60%;
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    background-color: #0c2d83;
    box-shadow: inset 0 4px 3px 0 rgba(0, 0, 0, 0.25);
    @media screen and (max-width: 991px) {
      width: 90%;
    }
  }

  visao-geral-frame-parte01 {
    width: 100%;
  }

  visao-geral-linha-titulo {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    margin-top: 15px;
    margin-bottom: 15px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    //cursor: pointer;
  }

  visao-geral-linha-titulo-parte01 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 75%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  visao-geral-linha-titulo-texto {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  visao-geral-linha-titulo-parte02 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 25%;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  image {
    width: 85px;
    height: 85px;
    margin-right: 25px;
    margin-left: 25px;
    padding: 10px;
    vertical-align: center;
    align-items: center;
    @media screen and (max-width: 480px) {
      width: 60px;
      height: 60px;
    }
  }

  menu-botao {
    position: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 65px;
    height: 65px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    cursor: pointer;
    align-self: right;
  }

  botao-p {
    position: relative;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    color: #fff;
  }

  visao-geral-titulo {
    color: #fff;
    font-size: 38px;

    @media screen and (max-width: 767px) {
      font-size: 34px;
    }

    @media screen and (max-width: 460px) {
      font-size: 28px;
    }
  }

  visao-geral-linha-atualizacao {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 5px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  visao-geral-atualizacao-label {
    margin-right: 5px;
    color: #fff;
    font-size: 14px;
    @media screen and (max-width: 767px) {
      font-size: 12px;
    }
  }

  visao-geral-atualizacao-data {
    color: #fff;
    font-size: 18px;
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }

  visao-geral-frame-parte02 {
    width: 100%;
    padding-right: 25px;
    padding-left: 25px;
  }

  visao-geral-linha {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 25px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  visao-geral-linha2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 25px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  }

  visao-geral-item-matricula {
    width: 85px;
    padding-left: 5px;
    border-left: 1px solid #fff;
    @media screen and (max-width: 767px) {
      width: 108px;
    }
  }

  visao-geral-item-cpf {
    width: 125px;
    padding-left: 5px;
    border-left: 1px solid #fff;
  }

  visao-geral-item-nome {
    width: 300px;
    padding-left: 5px;
    border-left: 1px solid #fff;
    @media screen and (max-width: 767px) {
      width: 270px;
    }
    @media screen and (max-width: 480px) {
      width: 170px;
    }
  }

  visao-geral-item-adesao {
    width: 85px;
    padding-left: 5px;
    border-left: 1px solid #fff;
    @media screen and (max-width: 767px) {
      width: 108px;
    }
  }

  visao-geral-item-status {
    width: 300px;
    padding-left: 5px;
    border-left: 1px solid #fff;
    @media screen and (max-width: 767px) {
      width: 200px;
    }
    @media screen and (max-width: 480px) {
      width: 150px;
    }
  }

  visao-geral-label-ajustado {
    padding-right: 10px;
    margin-bottom: 5px;
    color: #fff;
    font-size: 11px;
  }
  visao-geral-destaque {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 20%;
    height: 100px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border: 1px solid #fff;
    border-radius: 4px;
    @media screen and (max-width: 991px) {
      width: 21%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      height: 50px;
      margin-bottom: 0px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  visao-geral-destaque-total {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 34%;
    height: 100px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border: 1px solid #fff;
    border-radius: 4px;
    @media screen and (max-width: 991px) {
      width: 33%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      height: 50px;
      margin-bottom: 0px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  visao-geral-destaque-rentabilidade {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 18%;
    height: 100px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border: 1px solid #fff;
    border-radius: 4px;
    @media screen and (max-width: 991px) {
      width: 17%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      height: 50px;
      margin-bottom: 0px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  visao-geral-valor {
    color: #fff;
    font-size: 24px;
    text-align: right;
    @media screen and (max-width: 767px) {
      font-size: 20px;
    }
  }

  visao-geral-valor-total {
    color: #fff;
    text-align: right;
    font-size: 34px;
    @media screen and (max-width: 767px) {
      margin-top: -10px;
      font-size: 24px;
    }
  }
`;

export const VisaoGeralLabel = styled.div`
  margin-bottom: 5px;
  align-content: space-between;
  @media screen and (max-width: 767px) {
    margin-bottom: 0px;
  }

  label-l {
    color: #fff;
    font-size: 11px;
    font-weight: 700;
    font-size: 10px;
  }

  img-g {
    left: auto;
    right: 0%;
    position: relative;
    float: right;
    cursor: pointer;
  }
`;

export const VisaoGeralInfo = styled.div`
  margin-top: 5px;
  color: #fff;
  font-size: 16px;
  @media screen and (max-width: 767px) {
    font-size: 14px;
  }
`;
