import React, { useState } from 'react';
import { Base64 } from 'js-base64';
import api from '../../services/api';
import {
  SubmenuCorpoFrame,
  SubmenuLabels,
  Form,
  WCheckbox,
  SubmenuLabelsCheck,
  SubmenuBotao,
  ModalAlert,
  SubmenuObservacoes,
} from './styles';
import { store } from '../../store';

// eslint-disable-next-line react/prop-types
function SaldoIndividual({ dadosDts }) {
  const [datasExtratoSaldo] = useState(dadosDts);
  const [chaveConsolid, setChaveConsolid] = useState(false);
  const [chaveIni, setChaveIni] = useState('');
  const [chaveFim, setChaveFim] = useState('');
  const [msg, setMsg] = useState('');
  const [alerta, setAlerta] = useState(false);

  const { indice } = store.getState().visaoGeral;
  const { token } = store.getState().auth;
  const { dadosPlano } = store.getState().visaoGeral.profile;
  const dp = dadosPlano[indice];
  const { codPlano } = dp;
  const { patrocinadora } = dp;
  const { matricula } = dp;

  let dtsList = '';

  if (
    datasExtratoSaldo === undefined ||
    datasExtratoSaldo === null ||
    datasExtratoSaldo === [] ||
    datasExtratoSaldo === '[]'
  ) {
    dtsList = <option value="0"> </option>;
  } else {
    dtsList = datasExtratoSaldo.map((n) => (
      <option value={n.chave}>{`${n.lista}`}</option>
    ));
  }

  async function handleGerarExtrato() {
    if (chaveIni !== '' && chaveFim !== '' && chaveIni <= chaveFim) {
      // try {
      const parametros = Base64.encode(`{
          "token": "${token}",
          "matricula": ${matricula},
          "plano": ${codPlano},
          "organizacao": ${patrocinadora},
          "consolidado": ${chaveConsolid},
          "anoMesInicio": ${chaveIni},
          "anoMesFim": ${chaveFim}
        }`);

      const parametrosFinal = Base64.encode(parametros);
      // const response =
      await api
        .post(`/wsExtratoSaldo.rule?sys=PRT&Entrada=${parametrosFinal}`)
        .then((response) => {
          window.open(response.data.link);
        })
        .catch(() => {
          setMsg(
            'Não foi possiível gerar o extrato agora. Tente novamente mais tarde.'
          );
          setAlerta(true);
        });
    } else if (chaveIni === '' && chaveFim === '') {
      setMsg('Nenhuma data selecionada.');
      setAlerta(true);
    } else if (chaveIni === '' && chaveFim !== '') {
      setMsg('Por favor, selecione a data de início.');
      setAlerta(true);
    } else if (chaveIni !== '' && chaveFim === '') {
      setMsg('Por favor, selecione a data de término.');
      setAlerta(true);
    } else if (chaveIni > chaveFim) {
      setMsg('A data de término não pode ser menor que a data de início.');
      setAlerta(true);
    }
  }

  function HandleToggleAlerta() {
    setAlerta(!alerta);
  }

  return (
    <SubmenuCorpoFrame>
      <ModalAlert visible={alerta}>
        <popup-inner>
          <pop-up-sair-texto>{msg}</pop-up-sair-texto>
          <div>
            <button type="button" onClick={HandleToggleAlerta}>
              OK
            </button>
          </div>
        </popup-inner>
      </ModalAlert>
      <submenu-bloco>
        <submenu-bloco-parte01>
          <submenu-bloco-parte02>
            <submenu-item-conteudo-data>
              <SubmenuLabels>Data de início</SubmenuLabels>
              <w-form>
                <Form>
                  {/* <DataInput placeholder="DD/MM/AAAA" /> */}
                  <select
                    nome="dataIni"
                    onChange={(e) => setChaveIni(e.target.value)}
                  >
                    <option value="">Escolha uma...</option>
                    {dtsList}
                  </select>
                </Form>
              </w-form>
            </submenu-item-conteudo-data>
          </submenu-bloco-parte02>

          <submenu-bloco-parte02>
            <submenu-item-conteudo-data>
              <SubmenuLabels>Data de término</SubmenuLabels>
              <w-form>
                <Form>
                  <select
                    nome="dataFim"
                    onChange={(e) => setChaveFim(e.target.value)}
                  >
                    <option value="">Escolha uma...</option>
                    {dtsList}
                  </select>
                </Form>
              </w-form>
            </submenu-item-conteudo-data>
          </submenu-bloco-parte02>
        </submenu-bloco-parte01>
        {dadosPlano.length > 1 ? (
          <WCheckbox>
            <input
              type="checkbox"
              onChange={(e) => setChaveConsolid(e.target.checked)}
            />
            <SubmenuLabelsCheck>
              Consolidado (todas as patrocinadoras e planos)
            </SubmenuLabelsCheck>
          </WCheckbox>
        ) : (
          <></>
        )}
        <submenu-bloco-parte01>
          <SubmenuBotao onClick={handleGerarExtrato}>
            Gerar extrato
          </SubmenuBotao>
        </submenu-bloco-parte01>
      </submenu-bloco>
      <submenu-bloco>
        <submenu-titulos>Observações</submenu-titulos>
        <submenu-item-conteudo-ajustado>
          <SubmenuObservacoes>
            A partir de janeiro de 2023 a contribuição da empresa possui
            contrapartida de 200% da contribuição suplementar do participante.
          </SubmenuObservacoes>
        </submenu-item-conteudo-ajustado>
      </submenu-bloco>
    </SubmenuCorpoFrame>
  );
}

export default SaldoIndividual;
