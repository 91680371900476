import styled from 'styled-components';

export const Container = styled.div`
  font-weight: 300;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  @media screen and (max-width: 991px) {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  recupera-senha-frame {
    justify-content: space-between;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    min-width: 50%;
    min-height: 60vh;
    margin-top: 25px;
    padding-right: 25px;
    padding-left: 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid #fe5a17;
  }

  recupera-senha-corpo00 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: auto;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  recupera-senha-corpo-parte00 {
    width: 60%;
    @media screen and (max-width: 991px) {
      width: 90%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
`;

export const SenhaTitulo = styled.div`
  width: 100%;
  margin-top: 10%;
  margin-bottom: 15px;
  padding-right: 0px;
  padding-left: 0px;
  color: #0c2d83;
  font-size: 28px;
  line-height: 1.4;
  text-align: left;
  @media screen and (max-width: 767px) {
    width: 300px;
    margin-top: 50px;
    margin-bottom: 25px;
    font-size: 24px;
  }
`;

export const SenhaSubTitulo = styled.div`
  width: 100%;
  margin-top: 0px;
  margin-bottom: 25px;
  padding-right: 25px;
  padding-left: 0px;
  color: #0c2d83;
  font-size: 18px;
  line-height: 1.4;
  text-align: left;
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 25px;
    font-size: 16px;
  }
`;

export const RecuperaSenhaCorpo = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;

  form {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: block;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    margin-bottom: 0px;
    width: 60%;
    height: 100%;
    padding-right: 0px;
    @media screen and (max-width: 991px) {
      width: 90%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }

    a {
      display: block;
      padding-top: 0px;
      padding-left: 5px;
      color: #0c2d83;
      font-size: 11px;
      cursor: pointer;
    }

    input[type='radio'] + label {
      color: #000;
      margin-left: 5px;
      margin-right: 30px;
    }
  }
`;

export const RecuperaSenhaCampo = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 25px;

  recupera-senha-input {
    border-style: none none solid;
    border-width: 0px 0px 1px;
    border-color: #000 #000 #fe5a17;
    height: 38px;
    padding: 8px 0px;
    margin-bottom: 10px;
    line-height: 1.42857143;
  }

  input {
    border-style: none;
    position: relative;
    min-width: 90%;
    font-size: 14px;
    height: auto;
    padding: 4px 8px;
    line-height: 1.42857143;
    -webkit-box-shadow: 0 0 0 30px #fff inset;
  }

  label {
    padding-top: 10px;
    color: #0c2d83;
    display: block;
  }

  span {
    color: #f93a37;
    padding-top: 10px;
    padding-right: 25px;
    float: right;
  }

  img {
    left: auto;
    right: 0%;
    width: 20px;
    position: relative;
  }
`;

export const WCheckbox = styled.label`
  display: block;
  margin-bottom: 25px;

  span {
    padding-top: 5px;
    padding-left: 5px;
    margin-bottom: 5px;
    color: #0c2d83;
    font-size: 11px;
    font-weight: 400;
    cursor: pointer;
  }
`;

export const RecuperaSenhaCampo2 = styled.div`
  height: 0px;
  visibility: hidden;

  input {
    visibility: hidden;
  }
`;

export const DivBotao = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10%;
  margin-bottom: 10%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;

  button {
    width: 175px;
    height: 40px;
    margin-bottom: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #818181;
    transition: background-color 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    text-align: center;
    display: inline-block;
    color: white;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;

    &:hover {
      background-color: #fe5a17;
    }
  }
`;

export const ModalAlert = styled.div`
  position: fixed;
  z-index: 999;
  display: none;
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 99;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);

  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};

  popup-inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 350px;
    height: 180px;
    padding: 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    align-items: center;
  }

  pop-up-sair-texto {
    margin-top: 10px;
    margin-bottom: 10px;
    color: #fe5a17;
    font-size: 16px;
  }

  pop-sair-frame-parte02 {
  }

  button {
    width: 100px;
    margin-right: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #818181;
    -webkit-transition: background-color 200ms
      cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: background-color 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    text-align: center;
    padding: 9px 15px;
    color: white;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;

    &:hover {
      background-color: #fe5a17;
    }
  }
`;
