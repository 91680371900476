/* eslint-disable no-alert */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Base64 } from 'js-base64';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import api from '../../services/api';
import {
  SubmenuCorpoFrame,
  SubmenuBotao,
  SubmenuColunaCheckbox,
  ModalAlert,
} from './styles';
import { store } from '../../store';
import { ValorMascarar } from '../../services/masks';

function Solicitacao(dadosSolict) {
  const [solicitacaoEmp] = useState(dadosSolict);
  const [contrato, setContrato] = useState('');
  const [msg, setMsg] = useState('');
  const [alerta, setAlerta] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const { token } = store.getState().auth;

  if (
    solicitacaoEmp === null ||
    solicitacaoEmp === {} ||
    solicitacaoEmp === undefined
  ) {
    return <></>;
  }

  function handleCheck(e, v) {
    if (e === true) {
      setContrato(v);
    } else {
      setContrato('');
    }
  }

  async function handleCancelaSolicitacao() {
    if (contrato !== '') {
      try {
        const parametros = Base64.encode(
          `{"token":"${token}","contrato":"${contrato}"}`
        );
        const parametrosFinal = Base64.encode(parametros);

        await api.post(
          `/wsEmprestimoSolicitacaoDELETE.rule?sys=PRT&Entrada=${parametrosFinal}`
        );

        setMsg('Cancelado com sucesso.');
        setAlerta(true);
        setRefresh(true);
      } catch (err) {
        setMsg('Não foi possível cancelar agora. Tente novamente mais tarde.');
        setAlerta(true);
      }
    } else {
      setMsg('Contrato não selecionado.');
      setAlerta(true);
    }
  }

  async function handleImprimirContrato() {
    if (contrato !== '') {
      try {
        const parametros = Base64.encode(
          `{"token":"${token}","contrato":"${contrato}"}`
        );
        const parametrosFinal = Base64.encode(parametros);

        const response = await api.get(
          `/wsEmprestimoContrato.rule?sys=PRT&Entrada=${parametrosFinal}`
        );

        window.open(response.data.link);
      } catch (err) {
        setMsg('Ops, algo deu errado. Tente novamente mais tarde.');
        setAlerta(true);
      }
    } else {
      setMsg('Contrato não selecionado.');
      setAlerta(true);
    }
  }

  function HandleToggleAlerta() {
    setAlerta(!alerta);
    if (refresh) {
      window.location.reload();
    }
  }

  return (
    <SubmenuCorpoFrame>
      <ModalAlert visible={alerta}>
        <popup-inner>
          <pop-up-sair-texto>{msg}</pop-up-sair-texto>
          <div>
            <button type="button" onClick={HandleToggleAlerta}>
              OK
            </button>
          </div>
        </popup-inner>
      </ModalAlert>
      <submenu-bloco>
        <submenu-titulos>Simulação</submenu-titulos>
        <submenu-bloco-parte01>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Situação</TableCell>
                  <TableCell align="center">Contrato</TableCell>
                  <TableCell align="center">Espécie</TableCell>
                  <TableCell align="center">Solicitação</TableCell>
                  <TableCell align="center">Valor (R$)</TableCell>
                  <TableCell align="center">Prestação (R$)</TableCell>
                  <TableCell align="center">Qtd. prestações</TableCell>
                  <TableCell align="center">Prest. pagas</TableCell>
                  <TableCell align="center">Data de crédito</TableCell>
                  <TableCell align="center">Valor creditado</TableCell>
                  <TableCell align="center">Quitação</TableCell>
                  <TableCell align="center">Opção</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {solicitacaoEmp.dadosSolicit === undefined ||
                solicitacaoEmp.dadosSolicit === null ||
                solicitacaoEmp.dadosSolicit === [] ||
                solicitacaoEmp.dadosSolicit === '[]' ||
                solicitacaoEmp.dadosSolicit === '' ? (
                  <TableRow>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">-</TableCell>
                  </TableRow>
                ) : (
                  solicitacaoEmp.dadosSolicit.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">
                        {row.situacao == null || row.situacao === undefined
                          ? '-'
                          : row.situacao}
                      </TableCell>
                      <TableCell align="center">
                        {row.contrato == null || row.contrato === undefined
                          ? '-'
                          : row.contrato}
                      </TableCell>
                      <TableCell align="center">
                        {row.especie == null || row.especie === undefined
                          ? '-'
                          : row.especie}
                      </TableCell>
                      <TableCell align="center">
                        {row.data == null || row.data === undefined
                          ? '-'
                          : row.data}
                      </TableCell>
                      <TableCell align="center">
                        {row.valor == null || row.valor === undefined
                          ? '-'
                          : ValorMascarar(row.valor)}
                      </TableCell>
                      <TableCell align="center">
                        {row.prestacao == null || row.prestacao === undefined
                          ? '-'
                          : ValorMascarar(row.prestacao)}
                      </TableCell>
                      <TableCell align="center">
                        {row.quantidadePrestacao == null ||
                        row.quantidadePrestacao === undefined
                          ? '-'
                          : row.quantidadePrestacao}
                      </TableCell>
                      <TableCell align="center">
                        {row.quantidadePrestacaoPagas == null ||
                        row.quantidadePrestacaoPagas === undefined
                          ? '-'
                          : row.quantidadePrestacaoPagas}
                      </TableCell>
                      <TableCell align="center">
                        {row.dataCredito == null ||
                        row.dataCredito === undefined
                          ? '-'
                          : row.dataCredito}
                      </TableCell>
                      <TableCell align="center">
                        {row.valorCreditado == null ||
                        row.valorCreditado === undefined
                          ? '-'
                          : ValorMascarar(row.valorCreditado)}
                      </TableCell>
                      <TableCell align="center">
                        {row.dataQuitacao == null ||
                        row.dataQuitacao === undefined
                          ? '-'
                          : row.dataQuitacao}
                      </TableCell>
                      <TableCell align="center">
                        <SubmenuColunaCheckbox
                          type="checkbox"
                          disabled={row.situacao === 'Cancelado'}
                          checked={
                            row.contrato === contrato &&
                            contrato !== null &&
                            contrato !== ''
                          }
                          onChange={(e) =>
                            handleCheck(e.currentTarget.checked, row.contrato)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </submenu-bloco-parte01>
      </submenu-bloco>
      <submenu-bloco>
        <submenu-bloco-parte01>
          {/* <SubmenuBotao>Documentação do empréstimo</SubmenuBotao> */}
          <SubmenuBotao name="btnCancelar" onClick={handleCancelaSolicitacao}>
            Cancelar a solicitação
          </SubmenuBotao>
          <SubmenuBotao name="btnContrato" onClick={handleImprimirContrato}>
            Imprimir contrato
          </SubmenuBotao>
        </submenu-bloco-parte01>
      </submenu-bloco>
    </SubmenuCorpoFrame>
  );
}

export default Solicitacao;
