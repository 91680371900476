import React from 'react';
import '../../../config/ReactotronConfig';
import {
  Bloco,
  DadosPesssoaisItemLabel,
  DadosPesssoaisItemInfo,
} from '../styles';

function BlocoDadosBancarios(props, n) {
  const dadosBancarios = props;

  if (props === undefined) {
    return <></>;
  }

  /* const lancamento = dadosBancarios[n].formaLancamento;
  let lancamentoEdit = '-';

  if (lancamento === '01') {
    lancamentoEdit = 'Crédito Conta Corrente (01)';
  } else if (lancamento === '02') {
    lancamentoEdit = 'Cheque Administrativo (02)';
  } else if (lancamento === '03') {
    lancamentoEdit = 'Transferência outro Banco (03)';
  } else if (lancamento === '04') {
    lancamentoEdit = 'Crédito Conta Salário (04)';
  } else if (lancamento === '05') {
    lancamentoEdit = 'Crédito Poupança / Real Time (05)';
  } else if (lancamento === '10') {
    lancamentoEdit = 'Ordem Pagamento (10)';
  } else if (lancamento === '41') {
    lancamentoEdit = 'TED outra Titularidade (41)';
  } else lancamentoEdit = '-'; */

  return (
    <>
      <Bloco>
        <dados-pessoais-bloco-parte01>
          <dados-pessoais-bloco-parte02>
            <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>Banco</DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>
                {dadosBancarios[n].nomeBanco == null
                  ? '-'
                  : dadosBancarios[n].nomeBanco}
              </DadosPesssoaisItemInfo>
            </dados-pesssoais-item>
            <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>Número da conta</DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>
                {dadosBancarios[n].numeroConta == null
                  ? '-'
                  : dadosBancarios[n].numeroConta}
              </DadosPesssoaisItemInfo>
            </dados-pesssoais-item>
            <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>Tipo de conta</DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>
                {dadosBancarios[n].tipoConta == null
                  ? '-'
                  : dadosBancarios[n].tipoConta}
              </DadosPesssoaisItemInfo>
            </dados-pesssoais-item>
            {/* <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>
                Início da vigência
              </DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>
                {dadosBancarios[n].inicioVigencia == null
                  ? '-'
                  : dadosBancarios[n].inicioVigencia}
              </DadosPesssoaisItemInfo>
                </dados-pesssoais-item> */}
          </dados-pessoais-bloco-parte02>
          <dados-pessoais-bloco-parte02>
            <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>Agência</DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>
                {dadosBancarios[n].agencia == null
                  ? '-'
                  : dadosBancarios[n].agencia}
              </DadosPesssoaisItemInfo>
            </dados-pesssoais-item>
            <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>
                Dígito verificador da conta
              </DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>
                {dadosBancarios[n].digitoConta == null
                  ? '-'
                  : dadosBancarios[n].digitoConta}
              </DadosPesssoaisItemInfo>
            </dados-pesssoais-item>
            {/* <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>
                Forma de lançamento
              </DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>{lancamentoEdit}</DadosPesssoaisItemInfo>
            </dados-pesssoais-item>
            <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>Fim da vigência</DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>
                {dadosBancarios[n].fimVigencia == null
                  ? '-'
                  : dadosBancarios[n].fimVigencia}
              </DadosPesssoaisItemInfo>
            </dados-pesssoais-item> */}
          </dados-pessoais-bloco-parte02>
        </dados-pessoais-bloco-parte01>
      </Bloco>
    </>
  );
}

export default BlocoDadosBancarios;
