import React, { useState, useEffect } from 'react';
import { Base64 } from 'js-base64';
import Documentos from './documentos';
import { store } from '../../store';
import api from '../../services/api';

import { SubmenusRelDocs } from './styles';

function Corpo() {
  const [state, setState] = useState({
    Formularios: null,
    Erro: '',
  });

  useEffect(() => {
    const { indice } = store.getState().visaoGeral;
    async function pegaDatasExtrato() {
      try {
        const t = store.getState().auth.token;
        const dadosPlano = store.getState().visaoGeral.profile.dadosPlano[
          indice
        ];
        const pl = dadosPlano.codPlano;
        const pt = dadosPlano.patrocinadora;
        const m = dadosPlano.matricula;

        const parametros = Base64.encode(
          `{"token":"${t}",
          "patrocinadora":"${pt}",
          "matricula":"${m}",
          "plano":"${pl}"}`
        );
        const parametrosFinal = Base64.encode(parametros);

        const response = await api.get(
          `/wsFormulario.rule?sys=PRT&Entrada=${parametrosFinal}`
        );

        setState({
          Formularios: response.data,
        });
      } catch (err) {
        setState({ Erro: err });
      }
    }
    pegaDatasExtrato();
  }, []);

  if (
    state.Formularios === undefined ||
    state.Formularios === null ||
    state.Formularios === [] ||
    state.Formularios === '[]'
  ) {
    return (
      <>
        <SubmenusRelDocs />
      </>
    );
  }

  const { dadosFormularios } = state.Formularios;

  return (
    <SubmenusRelDocs>
      {/* <w-tab-menu>
        <BotaoSubmenuResgate
        /* visible={visible}
          onClick={!visible ? '' : HandleSimulVisible} *
        >
          Resgate
        </BotaoSubmenuResgate>
      </w-tab-menu> */}
      <w-tab-content>
        <w-tab-pane>
          <Documentos forms={dadosFormularios} />
          {/* visible ? <Solicitacao /> : <Simulador /> */}
        </w-tab-pane>
      </w-tab-content>
    </SubmenusRelDocs>
  );
}

export default Corpo;
