/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Base64 } from 'js-base64';
import api from '../../services/api';

import {
  SubmenuCorpoFrame,
  SubmenuBotaoAjustado,
  SubmenuLabels,
  ModalAlert,
} from './styles';

import { store } from '../../store';

function Contracheque(dadosDts) {
  const [datasCC] = useState(dadosDts.dadosCC);
  const [dtPgto, setDtPgto] = useState('');
  const [msg, setMsg] = useState('');
  const [alerta, setAlerta] = useState(false);
  // const { dadosPlano } = store.getState().visaoGeral.profile;

  const { indice } = store.getState().visaoGeral;
  const { token } = store.getState().auth;
  const { patrocinadora } = store.getState().visaoGeral.profile.dadosPlano[
    indice
  ];
  const { codPlano } = store.getState().visaoGeral.profile.dadosPlano[indice];
  const { matricula } = store.getState().visaoGeral.profile.dadosPlano[indice];

  let dtsList = '';
  if (datasCC === undefined || datasCC === '[]') {
    dtsList = <option value=""> </option>;
  } else {
    dtsList = datasCC.map((n) => (
      <option value={n.chave}>{`${n.lista}`}</option>
    ));
  }

  function HandleToggleAlerta() {
    setAlerta(!alerta);
  }

  async function handleGerarCC() {
    if (dtPgto !== '') {
      try {
        const parametros = Base64.encode(`{
          "token": "${token}",
          "matricula": "${matricula}",
          "patrocinadora": "${patrocinadora}",
          "plano": "${codPlano}",
          "dtPgto": "${dtPgto}"
        }`);

        const parametrosFinal = Base64.encode(parametros);

        const response = await api.post(
          `/wsContraCheque.rule?sys=PRT&Entrada=${parametrosFinal}`
        );

        window.open(response.data.link);
      } catch (err) {
        setMsg(
          'Não foi possível gerar o contracheque agora. Tente novamente mais tarde.'
        );
        setAlerta(true);
      }
    } else if (dtPgto === '' || dtPgto === null) {
      setMsg('Selecione uma data no campo acima');
      setAlerta(true);
    }
  }

  return (
    <SubmenuCorpoFrame>
      <ModalAlert visible={alerta}>
        <popup-inner>
          <pop-up-sair-texto>{msg}</pop-up-sair-texto>
          <div>
            <button type="button" onClick={HandleToggleAlerta}>
              OK
            </button>
          </div>
        </popup-inner>
      </ModalAlert>
      <submenu-bloco>
        <submenu-bloco-parte01>
          <div>
            <SubmenuLabels>Data de pagamento</SubmenuLabels>
            <select
              nome="dtPagamento"
              onChange={(e) => setDtPgto(e.target.value)}
            >
              <option value="">Selecione...</option>
              {dtsList}
            </select>
          </div>
          <SubmenuBotaoAjustado onClick={handleGerarCC}>
            Gerar contracheque
          </SubmenuBotaoAjustado>
        </submenu-bloco-parte01>
      </submenu-bloco>
    </SubmenuCorpoFrame>
  );
}

export default Contracheque;
