import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { SubmenuCorpoFrame, SubmenuBotaoBoleto, ModalAlert } from './styles';

function Bnf(dadosBol) {
  const [dadosBoletos] = useState(dadosBol.dadosBol);
  const [msg, setMsg] = useState('');
  const [alerta, setAlerta] = useState(false);

  function teste(codigo) {
    setMsg(codigo);
    setAlerta(true);
  }

  function HandleToggleAlerta() {
    setAlerta(!alerta);
  }

  return (
    <SubmenuCorpoFrame>
      <ModalAlert visible={alerta}>
        <popup-inner>
          <pop-up-sair-texto>{msg}</pop-up-sair-texto>
          <div>
            <button type="button" onClick={HandleToggleAlerta}>
              OK
            </button>
          </div>
        </popup-inner>
      </ModalAlert>
      <submenu-bloco>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">Número do boleto</TableCell>
                <TableCell align="center">Data de vencimento</TableCell>
                <TableCell align="center">Valor (R$)</TableCell>
                <TableCell align="center">Código de barras</TableCell>
                {/* <TableCell align="center">Data de pagamento</TableCell> */}
                <TableCell align="center" />
              </TableRow>
            </TableHead>
            <TableBody>
              {dadosBoletos === undefined ||
              dadosBoletos === null ||
              dadosBoletos === [] ||
              dadosBoletos === '[]' ||
              dadosBoletos === '' ? (
                <TableRow>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">
                    <SubmenuBotaoBoleto>Gerar 2ª via</SubmenuBotaoBoleto>
                  </TableCell>
                </TableRow>
              ) : (
                dadosBoletos.map((row) => (
                  <TableRow key={row.numero}>
                    <TableCell align="center">
                      {row.numero == null || row.numero === undefined
                        ? '-'
                        : row.numero}
                    </TableCell>
                    <TableCell align="center">
                      {row.vencimento == null || row.vencimento === undefined
                        ? '-'
                        : row.vencimento}
                    </TableCell>
                    <TableCell align="center">
                      {row.valor == null || row.valor === undefined
                        ? '-'
                        : row.valor}
                    </TableCell>
                    {/* <TableCell align="center">
                      {row.codigo == null || row.codigo === undefined
                        ? '-'
                        : row.codigo}
                    </TableCell> */}
                    <TableCell align="center">
                      <SubmenuBotaoBoleto
                        type="button"
                        onClick={() => teste(row.codigo)}
                      >
                        Ver código
                      </SubmenuBotaoBoleto>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </submenu-bloco>
    </SubmenuCorpoFrame>
  );
}

export default Bnf;
