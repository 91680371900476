import React, { useState, useEffect } from 'react';
import { Base64 } from 'js-base64';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import VisaoGeral from '../../components/VisaoGeral';
import Beneficiarios from '../../components/Beneficiarios';
import Extratos from '../../components/Extratos';
import Contribuicao from '../../components/Contribuicao';
import Emprestimo from '../../components/Emprestimo';
import Simulador from '../../components/Simulador';
import DemaisServicos from '../../components/DemaisServicos';
import RelatoriosDocumentos from '../../components/RelatoriosDocumentos';
import BotaoTopo from '../../components/BotaoTopo';
import Footer from '../../components/Footer';
import { store } from '../../store';
import api from '../../services/api';
import { signOut } from '../../store/modules/auth/actions';
import IconeDocs from '../../assets/icone-relatorios-e-documentos-2.svg';

import { Container, SecaoMenus, ModalAlert } from './styles';

function Main() {
  const [dadosMenu, setDadosMenu] = useState({});

  const [alerta, setAlerta] = useState(true);
  const [msg] = useState('Eleições 2023');
  const { token } = store.getState().auth;

  const { indice } = store.getState().visaoGeral;

  const dispatch = useDispatch();

  useEffect(() => {
    async function pegaDadosMenu() {
      try {
        const dadosPlano = store.getState().visaoGeral.profile.dadosPlano[
          indice
        ];
        const { codPlano } = dadosPlano;
        const { patrocinadora } = dadosPlano;
        const { matricula } = dadosPlano;

        const parametros = Base64.encode(`{
          "token": "${token}",
          "patrocinadora": "${patrocinadora}",
          "matricula": "${matricula}",
          "plano": "${codPlano}"
        }`);
        const parametrosFinal = Base64.encode(parametros);

        // const response =
        await api
          .get(`/wsMenuPortal.rule?sys=PRT&Entrada=${parametrosFinal}`)
          .then((res) => setDadosMenu(res.data.dadosMenu[0]))
          .catch((res) =>
            res.message === 'Request failed with status code 401'
              ? dispatch(signOut())
              : setDadosMenu({
                  extrato: 'N',
                  emprestimo: 'N',
                  simulResgate: 'N',
                  beneficiarios: 'N',
                  contraCheque: 'N',
                  boleto: 'N',
                  infRendimentos: 'N',
                  formularios: 'N',
                  renovacaoContribuicao: 'N',
                })
          );
      } catch {
        /* setDadosMenu({
          extrato: 'N',
          emprestimo: 'N',
          simulResgate: 'N',
          beneficiarios: 'N',
          contraCheque: 'N',
          boleto: 'N',
          infRendimentos: 'N',
          formularios: 'N',
          renovacaoContribuicao: 'N',
        }); */
        dispatch(signOut());
      }
    }
    pegaDadosMenu();
  }, [dispatch, token]);

  useEffect(() => {
    async function verificaEleicao() {
      try {
        const parametros = Base64.encode(`{
          "token": "${token}"
        }`);
        const parametrosFinal = Base64.encode(parametros);

        await api
          .get(`/wsEleicao.rule?sys=PRT&Entrada=${parametrosFinal}`)
          .then((res) =>
            res.data[0] !== undefined && res.data[0].codCargo > 0
              ? setAlerta(true)
              : setAlerta(false)
          )
          .catch(setAlerta(false));
      } catch {
        setAlerta(false);
      }
    }
    verificaEleicao();
  }, [token]);

  function HandleToggleAlerta() {
    setAlerta(!alerta);
  }

  return (
    <>
      <Container>
        <ModalAlert visible={alerta}>
          <popup-inner>
            <div className="title">
              <img src={IconeDocs} alt="IconeDocs1" height="50px" />
              <pop-up-sair-texto>{msg}</pop-up-sair-texto>
            </div>
            <div>
              <button
                className="medio"
                type="button"
                onClick={HandleToggleAlerta}
              >
                <Link to="/eleicoes">Iniciar votação</Link>
              </button>
              <button
                className="pequeno"
                type="button"
                onClick={HandleToggleAlerta}
              >
                Agora não
              </button>
            </div>
          </popup-inner>
        </ModalAlert>
        <VisaoGeral />

        <SecaoMenus>
          {dadosMenu.extrato === 'S' ? <Extratos /> : <></>}
          {dadosMenu.renovacaoContribuicao === 'S' ? <Contribuicao /> : <></>}
          {dadosMenu.emprestimo === 'S' ? <Emprestimo /> : <></>}
          {dadosMenu.simulResgate === 'S' ? <Simulador /> : <></>}
          {dadosMenu.beneficiarios === 'S' ? <Beneficiarios /> : <></>}
          {dadosMenu.boleto === 'S' ||
          dadosMenu.contraCheque === 'S' ||
          dadosMenu.infRendimentos === 'S' ? (
            <DemaisServicos />
          ) : (
            <></>
          )}
          {dadosMenu.formularios === 'S' ? <RelatoriosDocumentos /> : <></>}
        </SecaoMenus>
      </Container>
      <BotaoTopo />
      <Footer />
    </>
  );
}

export default Main;
