import React from 'react';

import IconeEmail from '../../assets/gericke-icone-e-mail-white.svg';
import IconeTelefone from '../../assets/gericke-icone-telefone-white.svg';
import { Container, FaleConoscoCorpo } from './styles';

function FaleConosco() {
  return (
    <Container>
      <painel-fale-com-parte01>
        <text-block-6>Fale Conosco</text-block-6>
      </painel-fale-com-parte01>
      <FaleConoscoCorpo>
        <icone-e-mail>
          <a
            href="https://www.prevpepsico.com.br/#fale-conosco"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={IconeEmail} alt="IconeEmail" />
          </a>
        </icone-e-mail>
        <fale-conosco-tel>Formulário de Contato</fale-conosco-tel>
        <painel-fale-com-espacador />
        <icone-telefone>
          <a href="tel:1132892729">
            <img src={IconeTelefone} alt="IconeTelefone" />
          </a>
        </icone-telefone>
        {/* <fale-conosco-tel>11 3289 2729</fale-conosco-tel>
        <fale-conosco-tel-legenda>
          apenas para a Cidade de São Paulo
        </fale-conosco-tel-legenda> */}
        <fale-conosco-tel>0800 326 0929</fale-conosco-tel>
        <fale-conosco-tel-legenda>
          São Paulo capital e demais regiões do país
        </fale-conosco-tel-legenda>
        <fale-conosco-tel>11 3905 5579</fale-conosco-tel>
        <fale-conosco-tel-legenda>WhatsApp</fale-conosco-tel-legenda>
      </FaleConoscoCorpo>
    </Container>
  );
}

export default FaleConosco;
