import React from 'react';
import '../../../config/ReactotronConfig';
import {
  Bloco,
  DadosPesssoaisItemInfo,
  DadosPesssoaisItemLabel,
} from '../styles';
import CadastrarEmail from './13cadastraremail';

function BlocoEmails(props, n) {
  const dadosPessoa = props;

  if (props === undefined) {
    return <></>;
  }

  return (
    <>
      <Bloco>
        <dados-pessoais-subtitulo>E-mail cadastrados</dados-pessoais-subtitulo>
        <dados-pessoais-bloco-parte01>
          <dados-pessoais-bloco-parte02-100>
            <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>Principal</DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>
                {dadosPessoa[n].email == null ? '-' : dadosPessoa[n].email}
              </DadosPesssoaisItemInfo>
            </dados-pesssoais-item>
            <dados-pesssoais-item>
              <DadosPesssoaisItemLabel>Pessoal</DadosPesssoaisItemLabel>
              <DadosPesssoaisItemInfo>
                {dadosPessoa[n].emailPessoal == null
                  ? '-'
                  : dadosPessoa[n].emailPessoal}
              </DadosPesssoaisItemInfo>
            </dados-pesssoais-item>
          </dados-pessoais-bloco-parte02-100>
        </dados-pessoais-bloco-parte01>
      </Bloco>
      <CadastrarEmail />
    </>
  );
}

export default BlocoEmails;
