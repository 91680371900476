/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import '../../../config/ReactotronConfig';
import { Bloco } from '../styles';

function BlocoCusteioFaixa(props, x) {
  const dadosPlano = props;

  if (props === undefined) {
    return <></>;
  }
  const custeioFaixa = dadosPlano[x].dadosCusteioFaixa;

  return (
    <Bloco>
      <dados-pessoais-subtitulo>
        Custeio individual por faixa
      </dados-pessoais-subtitulo>

      <dados-pessoais-bloco-parte01>
        <dados-pessoais-bloco-colunas>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Faixa</TableCell>
                  <TableCell align="center">Início de vigência</TableCell>
                  <TableCell align="center">Contribuição %</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {custeioFaixa === undefined ||
                custeioFaixa === null ||
                custeioFaixa === [] ||
                custeioFaixa === '[]' ||
                custeioFaixa === '' ? (
                  <TableRow>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">-</TableCell>
                  </TableRow>
                ) : (
                  custeioFaixa.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">
                        {row.conta == null || row.conta === undefined
                          ? '-'
                          : row.conta}
                      </TableCell>
                      <TableCell align="center">
                        {row.inicioVigencia == null ||
                        row.inicioVigencia === undefined
                          ? '-'
                          : row.inicioVigencia}
                      </TableCell>
                      <TableCell align="center">
                        {row.contribuicao == null ||
                        row.contribuicao === undefined
                          ? '-'
                          : row.contribuicao}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </dados-pessoais-bloco-colunas>
      </dados-pessoais-bloco-parte01>
    </Bloco>
  );
}

export default BlocoCusteioFaixa;
