/* eslint-disable react/no-array-index-key */
import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CadastrarTelefone from './11cadastrartelefone';
import { Bloco } from '../styles';

function BlocoTelefones(props) {
  const dadosTelefone = props;

  if (props === undefined) {
    return <></>;
  }
  const { tipoTel } = dadosTelefone[0];

  return (
    <>
      <Bloco>
        <dados-pessoais-subtitulo>
          Telefones cadastrados
        </dados-pessoais-subtitulo>
        <dados-pessoais-bloco-parte01>
          <dados-pessoais-bloco-parte02-100>
            {/** **
            <dados-pessoais-bloco-parte02> */}
            <List dense>
              {tipoTel === undefined ||
              tipoTel === null ||
              tipoTel === [] ||
              tipoTel === '[]' ||
              tipoTel === '' ? (
                <ListItem>
                  <ListItemText primary="Telefone" secondary="-" />
                </ListItem>
              ) : (
                dadosTelefone.map((n, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={`Telefone ${index + 1}${
                        n.descTipoTel == null || n.descTipoTel === ' '
                          ? ' '
                          : ` (${n.descTipoTel})`
                      }`}
                      secondary={`${
                        (n.ddi == null ? ' ' : `(+${n.ddi}) `) +
                        (n.ddd == null ? ' ' : `(${n.ddd}) `) +
                        (n.numero == null ? ' ' : n.numero)
                      }`}
                    />
                  </ListItem>
                ))
              )}
            </List>
          </dados-pessoais-bloco-parte02-100>
        </dados-pessoais-bloco-parte01>
      </Bloco>
      <CadastrarTelefone />
    </>
  );
}

export default BlocoTelefones;
