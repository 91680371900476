import { takeLatest, call, put, all } from 'redux-saga/effects';

import { Base64 } from 'js-base64';

import history from '../../../services/history';
import api from '../../../services/api';
import { carregaDadosMenu } from './actions';

function* buscaControleMenu({ payload }) {
  try {
    const { token, codPlano, patrocinadora, matricula } = payload;

    const parametrosPost = Base64.encode(`{
      "token": "${token}",
      "patrocinadora": "${patrocinadora}",
      "matricula": "${matricula}",
      "plano": "${codPlano}"
    }`);

    const parametrosFinal = Base64.encode(parametrosPost);
    const response = yield call(
      api.get,
      `/wsMenuPortal.rule?sys=PRT&Entrada=${parametrosFinal}`
    );
    const dadosMenu = response.data.dadosMenu[0];

    yield put(carregaDadosMenu(dadosMenu));

    history.push('/main');
  } catch (err) {
    alert('Algo deu errado. Tente novamente mais tarde.');
  }
}
export default all([
  takeLatest('@visaoGeral/GET_DADOS_PERMISSAO_MENU', buscaControleMenu),
]);
