/* eslint-disable no-else-return */
import React, { useState, useEffect } from 'react'; // , { useState }
import { Base64 } from 'js-base64';
import Flexibilização from './flexibilização';

import { SubmenusContribuicao, BotaoSubmenuRenovacao } from './styles';
import { store } from '../../store';
import api from '../../services/api';

function Corpo() {
  // const [visible, setVisible] = useState(false);

  const [state, setState] = useState({
    ContribuicaoGet: null,
    Erro: '',
  });

  /* function HandleSimulVisible() {
    setVisible(!visible);
  } */

  useEffect(() => {
    const { indice } = store.getState().visaoGeral;
    async function pegaDatasExtrato() {
      try {
        const t = store.getState().auth.token;
        const dadosPlano = store.getState().visaoGeral.profile.dadosPlano[
          indice
        ];
        const pl = dadosPlano.codPlano;
        const pt = dadosPlano.patrocinadora;
        const m = dadosPlano.matricula;

        const parametros = Base64.encode(
          `{"token":"${t}", "recadastramento":"2", "plano":"${pl}", "patrocinadora":"${pt}", "matricula":"${m}"}`
        );

        const parametrosFinal = Base64.encode(parametros);

        const responseContGet = await api.get(
          `/wsRenovacaoContribuicao.rule?sys=PRT&Entrada=${parametrosFinal}`
        );

        setState({
          ContribuicaoGet: responseContGet.data,
        });
      } catch (err) {
        console.log(err);
        setState({ Erro: err });
      }
    }
    pegaDatasExtrato();
  }, []);
  if (
    state.ContribuicaoGet === undefined ||
    state.ContribuicaoGet === null ||
    state.ContribuicaoGet === 'null' ||
    state.ContribuicaoGet === [] ||
    state.ContribuicaoGet === '[]'
  ) {
    return (
      <SubmenusContribuicao>
        <p>Desculpe, não foi possível carregar suas contribuições.</p>
        <p>
          Caso você tenha feito sua adesão ao plano recentemente, suas
          informações ainda estão sendo processadas pelo sistema.
        </p>
        <p>
          Se tiver dúvidas entre em contato através do{' '}
          <a
            href="https://www.prevpepsico.com.br/#fale-conosco"
            target="_blank"
            rel="noopener noreferrer"
          >
            Fale Conosco.
          </a>
        </p>
      </SubmenusContribuicao>
    );
  }

  const { dadosContribuicao } = state.ContribuicaoGet;
  const { salario } = state.ContribuicaoGet;
  const { codRecad } = state.ContribuicaoGet;

  return (
    <SubmenusContribuicao>
      {/* <w-tab-menu>
        <BotaoSubmenuRenovacao
        /* visible={visible}
          onClick={!visible ? '' : HandleSimulVisible}
        >
          Flexibilização
        </BotaoSubmenuRenovacao>
      </w-tab-menu> */}
      <w-tab-content>
        <w-tab-pane>
          <Flexibilização dadosCtb={[dadosContribuicao, codRecad, salario]} />
          {/* visible ? <Solicitacao /> : <Simulador /> */}
        </w-tab-pane>
      </w-tab-content>
    </SubmenusContribuicao>
  );
}

export default Corpo;
