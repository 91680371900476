/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Base64 } from 'js-base64';
import Collapse from '@material-ui/core/Collapse';
import {
  SubmenuCorpoFrame,
  SubmenuObservacoes,
  SubmenuLabels,
  SubmenuInfos,
  Divisoria,
  SliderContribuicao,
  ModalAlert,
} from './styles';
import { ValorMascarar, Valor } from '../../services/masks';
import { store } from '../../store';
import api from '../../services/api';

function Aposentadoria({ dadosApos }) {
  const dsApos = dadosApos.infoContribuicao[0];
  const [utilizado, setUtilizado] = useState({
    vlrInfo1: dsApos.vlrInfo1,
    vlrInfo2: dsApos.vlrInfo2,
    vlrInfo3: dsApos.vlrInfo3,
    vlrInfo4: dsApos.vlrInfo4,
    vlrInfo5: dsApos.vlrInfo5,
    vlrInfo6: dsApos.vlrInfo6,
    vlrInfoTotal: dsApos.vlrInfoTotal,
  });
  const [msg, setMsg] = useState('');
  const [alerta, setAlerta] = useState(false);
  const [pctResgate, setPctResgate] = useState(0);
  const [idade, setIdade] = useState(null);
  const [seqSimulacao] = useState(dsApos.seqSimul);
  const [formaRecebimento, setFormaRecebimento] = useState(null);
  const [pctSobreSaldo, setPctSobreSaldo] = useState(0);
  const [prazoAnos, setPrazoAnos] = useState(0);
  const [valorReal, setValorReal] = useState('0');
  const [saldoProjetado, setSaldoProjetado] = useState({
    dcrCampo1: 'Saldo inicial',
    vlrCampo1: 'R$ 0,00',
    exibCampo1: 'S',
    dcrCampo2: 'Contribuição mensal',
    vlrCampo2: 'R$ 0,00',
    exibCampo2: 'S',
    dcrCampo3: '(A) Saldo inicial projetado',
    vlrCampo3: 'R$ 0,00',
    exibCampo3: 'S',
    dcrCampo4: '(B) Poupança projetada das contribuições mensais',
    vlrCampo4: 'R$ 0,00',
    exibCampo4: 'S',
    dcrCampo5: '(C) Poupança total estimada (A + B)',
    vlrCampo5: 'R$ 0,00',
    exibCampo5: 'S',
    dcrCampo6: 'Rentabilidade anual projetada',
    vlrCampo6: 'R$ 0,00',
    exibCampo6: 'S',
    dcrCampo7: 'Campo7',
    vlrCampo7: '0',
    exibCampo7: 'N',
  });

  const [calculoRenda, setCalculoRenda] = useState({
    dcrCampo1: 'Poupança total estimada',
    vlrCampo1: 'R$ 0,00',
    dcrCampo2: 'Resgate à vista',
    vlrCampo2: 'R$ 0,00',
    dcrCampo3: 'Poupança total estimada para pagamento do benefício',
    vlrCampo3: 'R$ 0,00',
    dcrCampo4: 'Forma de recebimento',
    vlrCampo4: '0% ao mês',
    exibeCampo4: 'S',
    dcrCampo5: 'Renda inicial mensal',
    vlrCampo5: 'R$ 0,00',
    exibeCampo5: 'S',
    dcrCampo6: 'Campo6',
    vlrCampo6: '0',
    exibeCampo6: 'N',
    dcrCampo7: 'Campo7',
    vlrCampo7: '0',
    exibeCampo7: 'N',
    dcrCampo8: 'Campo7',
    vlrCampo8: '0',
    exibeCampo8: 'N',
    dcrCampo9: 'Campo7',
    vlrCampo9: '0',
    exibeCampo9: 'N',
    dcrCampo10: 'Campo7',
    vlrCampo10: '0',
    exibeCampo10: 'N',
    msg1: '',
    msg2: '',
  });

  // eslint-disable-next-line no-unused-vars
  const [listaIdades, setlistaIdades] = useState(dadosApos.listaIdade);

  const [flagSimul, setFlagSimul] = useState(false);
  const [flagCalc, setFlagCalc] = useState(false);

  const { indice } = store.getState().visaoGeral;
  const { token } = store.getState().auth;
  const dadosPlano = store.getState().visaoGeral.profile.dadosPlano[indice];
  const { codPlano } = dadosPlano;
  const { patrocinadora } = dadosPlano;
  const { matricula } = dadosPlano;

  let inputFormaRecebimento = <></>;

  if (formaRecebimento === '1') {
    inputFormaRecebimento = (
      <select
        value={pctSobreSaldo}
        onChange={(e) => {
          setPctSobreSaldo(e.target.value);
          setFlagCalc(false);
        }}
      >
        <option value={0}>Selecione...</option>
        <option value={0.1}>0,1%</option>
        <option value={0.2}>0,2%</option>
        <option value={0.3}>0,3%</option>
        <option value={0.4}>0,4%</option>
        <option value={0.5}>0,5%</option>
        <option value={0.6}>0,6%</option>
        <option value={0.7}>0,7%</option>
        <option value={0.8}>0,8%</option>
        <option value={0.9}>0,9%</option>
        <option value={1}>1%</option>
        <option value={1.1}>1,1%</option>
        <option value={1.2}>1,2%</option>
        <option value={1.3}>1,3%</option>
        <option value={1.4}>1,4%</option>
        <option value={1.5}>1,5%</option>
        <option value={1.6}>1,6%</option>
        <option value={1.7}>1,7%</option>
        <option value={1.8}>1,8%</option>
        <option value={1.9}>1,9%</option>
        <option value={2}>2%</option>
        <option value={2.1}>2,1%</option>
        <option value={2.2}>2,2%</option>
        <option value={2.3}>2,3%</option>
        <option value={2.4}>2,4%</option>
        <option value={2.5}>2,5%</option>
        <option value={2.6}>2,6%</option>
        <option value={2.7}>2,7%</option>
        <option value={2.8}>2,8%</option>
        <option value={2.9}>2,9%</option>
        <option value={3}>3%</option>
      </select>
    );
  } else if (formaRecebimento === '5') {
    inputFormaRecebimento = (
      <select
        value={prazoAnos}
        onChange={(e) => {
          setPrazoAnos(e.target.value);
          setFlagCalc(false);
        }}
      >
        <option value={0}>Selecione...</option>
        <option value={5}>5 anos</option>
        <option value={6}>6 anos</option>
        <option value={7}>7 anos</option>
        <option value={8}>8 anos</option>
        <option value={9}>9 anos</option>
        <option value={10}>10 anos</option>
        <option value={11}>11 anos</option>
        <option value={12}>12 anos</option>
        <option value={13}>13 anos</option>
        <option value={14}>14 anos</option>
        <option value={15}>15 anos</option>
        <option value={16}>16 anos</option>
        <option value={17}>17 anos</option>
        <option value={18}>18 anos</option>
        <option value={19}>19 anos</option>
        <option value={20}>20 anos</option>
      </select>
    );
  } else if (formaRecebimento === '7') {
    inputFormaRecebimento = (
      <input
        type="text"
        placeholder="Valor real"
        value={ValorMascarar(`${valorReal}`)}
        onChange={(e) => {
          setValorReal(e.target.value);
          setFlagCalc(false);
        }}
      />
    );
  } else {
    inputFormaRecebimento = (
      <input type="number" placeholder="% ou anos" disabled />
    );
  }

  const marksSuplementar = [];
  for (let i = 0; i <= 25; i += 1) {
    marksSuplementar.push({ value: i, label: i });
  }

  const handleChangePctResgate = (event, newValue) => {
    setPctResgate(newValue);
    setFlagCalc(false);
  };

  async function handleModificarContrib(valor, flag) {
    setFlagSimul(false);
    setFlagCalc(false);
    const parametros = Base64.encode(`{
      "token": "${token}",
      "patrocinadora": "${patrocinadora}",
      "matricula": "${matricula}",
      "plano": "${codPlano}",
      "seqSimulacao": "${dsApos.seqSimul}",
      "valorContribuicao": "${
        valor === '' || valor === undefined ? '0,00' : valor.replaceAll('.', '')
      }",
      "flagContribuicao": "${flag}"
    }`);

    const parametrosFinal = Base64.encode(parametros);
    await api
      .post(`/wsDadosAposentadoria.rule?sys=PRT&Entrada=${parametrosFinal}`)
      .then((res) => {
        if (res.data.codRetorno === '1') {
          setMsg(res.data.msgRetorno);
          setAlerta(true);
        }
        setUtilizado({
          vlrInfo1: res.data.infoContrib[0].vlrInfo1,
          vlrInfo2: res.data.infoContrib[0].vlrInfo2,
          vlrInfo3: res.data.infoContrib[0].vlrInfo3,
          vlrInfo4: res.data.infoContrib[0].vlrInfo4,
          vlrInfo5: res.data.infoContrib[0].vlrInfo5,
          vlrInfo6: res.data.infoContrib[0].vlrInfo6,
          vlrInfoTotal: res.data.infoContrib[0].vlrTotal,
        });
      })
      .catch(() => {
        setMsg(
          'Não foi possível calcular os valores agora. Tente novamente mais tarde'
        );
        setAlerta(true);
        setUtilizado({
          vlrInfo1: dsApos.vlrInfo1,
          vlrInfo2: dsApos.vlrInfo2,
          vlrInfo3: dsApos.vlrInfo3,
          vlrInfo4: dsApos.vlrInfo4,
          vlrInfo5: dsApos.vlrInfo5,
          vlrInfo6: dsApos.vlrInfo6,
          vlrInfoTotal: dsApos.vlrInfoTotal,
        });
      });
  }

  async function handleSimular() {
    const parametros = Base64.encode(`{
      "token": "${token}",
      "patrocinadora": "${patrocinadora}",
      "matricula": "${matricula}",
      "plano": "${codPlano}",
      "idade": "${idade}",
      "seqSimulacao": "${seqSimulacao}",
      "tipoBeneficio": "${1}",
      "pctResgate": "${`${pctResgate}`.replace('.', ',')}",
      "formaRecebimeno": "${formaRecebimento}",
      "valorReal": "${valorReal.replaceAll('.', '')}",
      "pctSaldo": "${`${pctSobreSaldo}`.replace('.', ',')}",
      "prazoAnos": "${prazoAnos}"
    }`);

    const parametrosFinal = Base64.encode(parametros);
    // const response =
    await api
      .get(`/wsSimuladorAposentadoria.rule?sys=PRT&Entrada=${parametrosFinal}`)
      .then((res) => {
        setSaldoProjetado(res.data.saldoProjetado[0]);
        setFlagSimul(true);
      })
      .catch(() => {
        setMsg('Não foi possível simular agora. Tente novamente mais tarde');
        setAlerta(true);
      });
  }

  async function handleCalcular() {
    const v = valorReal.replace(',', '').replaceAll('.', '');
    const m = Math.floor(v.length - 2);
    const a = `${v.substr(0, m)}.${v.substr(m)}`;
    const f = parseFloat(a);
    const parametros = Base64.encode(`{
          "token": "${token}",
          "patrocinadora": "${patrocinadora}",
          "matricula": "${matricula}",
          "plano": "${codPlano}",
          "idade": "${idade}",
          "seqSimulacao": "${seqSimulacao}",
          "tipoBeneficio": "${1}",
          "pctResgate": "${`${pctResgate}`.replace('.', ',')}",
          "formaRecebimeno": "${formaRecebimento}",
          "valorReal": "${f.toString().replace('.', ',')}",
          "pctSaldo": "${`${pctSobreSaldo}`.replace('.', ',')}",
          "prazoAnos": "${prazoAnos}"
        }`);

    const parametrosFinal = Base64.encode(parametros);
    // const response =
    await api
      .post(`/wsSimuladorAposentadoria.rule?sys=PRT&Entrada=${parametrosFinal}`)
      .then(
        (res) => {
          setCalculoRenda(res.data.resultadoSimulacao[0]);
          setFlagCalc(true);
        }
        // setSimulacao(res.data);
      )
      .catch(() => {
        setMsg('Não foi possível calcular agora. Tente novamente mais tarde');
        setAlerta(true);
      });
  }

  function HandleToggleAlerta() {
    setAlerta(!alerta);
  }

  return (
    <SubmenuCorpoFrame>
      <ModalAlert visible={alerta}>
        <popup-inner>
          <pop-up-sair-texto>{msg}</pop-up-sair-texto>
          <div>
            <button type="button" onClick={HandleToggleAlerta}>
              OK
            </button>
          </div>
        </popup-inner>
      </ModalAlert>
      <submenu-bloco>
        <submenu-titulos>Base salarial e contribuições</submenu-titulos>
        <submenu-bloco-aposent>
          <submenu-item-conteudo-aposent>
            <SubmenuLabels>{dsApos.dcrSal1}</SubmenuLabels>
            <SubmenuInfos>
              {dsApos.vlrSal1 === undefined
                ? '0,00'
                : ValorMascarar(dsApos.vlrSal1)}
            </SubmenuInfos>
          </submenu-item-conteudo-aposent>
          {dsApos.exibeSal2 === 'S' ? (
            <submenu-item-conteudo-aposent>
              <SubmenuLabels>{dsApos.dcrSal2}</SubmenuLabels>
              <SubmenuInfos>
                {dsApos.vlrSal2 === undefined
                  ? '0,00'
                  : ValorMascarar(dsApos.vlrSal2)}
              </SubmenuInfos>
            </submenu-item-conteudo-aposent>
          ) : (
            <></>
          )}
        </submenu-bloco-aposent>
        <frame-horizontal-aposent>
          <submenu-item-coluna-aposent>
            <submenu-coluna-labels-simulador>
              Contribuição
            </submenu-coluna-labels-simulador>
            <submenu-coluna-info-aposent>
              {dsApos.dcrContrib1}
            </submenu-coluna-info-aposent>
            {dsApos.exibeContrib2 === 'S' ? (
              <submenu-coluna-info-aposent>
                {dsApos.dcrContrib2}
              </submenu-coluna-info-aposent>
            ) : (
              <></>
            )}
            {dsApos.exibeContrib3 === 'S' ? (
              <submenu-coluna-info-aposent>
                {dsApos.dcrContrib3}
              </submenu-coluna-info-aposent>
            ) : (
              <></>
            )}
            {dsApos.exibeContrib4 === 'S' ? (
              <submenu-coluna-info-aposent>
                {dsApos.dcrContrib4}
              </submenu-coluna-info-aposent>
            ) : (
              <></>
            )}
            {dsApos.exibeContrib5 === 'S' ? (
              <submenu-coluna-info-aposent>
                {dsApos.dcrContrib5}
              </submenu-coluna-info-aposent>
            ) : (
              <></>
            )}
            {dsApos.exibeContrib6 === 'S' ? (
              <submenu-coluna-info-aposent>
                {dsApos.dcrContrib6}
              </submenu-coluna-info-aposent>
            ) : (
              <></>
            )}
            <Divisoria />
            <submenu-coluna-info-aposent-bold>
              Valor total das contribuições
            </submenu-coluna-info-aposent-bold>
          </submenu-item-coluna-aposent>

          <submenu-item-coluna-aposent-01>
            <submenu-coluna-labels-simulador>
              Valor atual
            </submenu-coluna-labels-simulador>
            <submenu-coluna-info-aposent>
              {dsApos.vlrContrib1 === undefined
                ? '0,00'
                : ValorMascarar(dsApos.vlrContrib1)}
            </submenu-coluna-info-aposent>
            {dsApos.exibeContrib2 === 'S' ? (
              <submenu-coluna-info-aposent>
                {dsApos.vlrContrib2 === undefined
                  ? '0,00'
                  : ValorMascarar(dsApos.vlrContrib2)}
              </submenu-coluna-info-aposent>
            ) : (
              <></>
            )}
            {dsApos.exibeContrib3 === 'S' ? (
              <submenu-coluna-info-aposent>
                {dsApos.vlrContrib3 === undefined
                  ? '0,00'
                  : ValorMascarar(dsApos.vlrContrib3)}
              </submenu-coluna-info-aposent>
            ) : (
              <></>
            )}
            {dsApos.exibeContrib4 === 'S' ? (
              <submenu-coluna-info-aposent>
                {dsApos.vlrContrib4 === undefined
                  ? '0,00'
                  : ValorMascarar(dsApos.vlrContrib4)}
              </submenu-coluna-info-aposent>
            ) : (
              <></>
            )}
            {dsApos.exibeContrib5 === 'S' ? (
              <submenu-coluna-info-aposent>
                {dsApos.vlrContrib5 === undefined
                  ? '0,00'
                  : ValorMascarar(dsApos.vlrContrib5)}
              </submenu-coluna-info-aposent>
            ) : (
              <></>
            )}
            {dsApos.exibeContrib6 === 'S' ? (
              <submenu-coluna-info-aposent>
                {dsApos.vlrContrib6 === undefined
                  ? '0,00'
                  : ValorMascarar(dsApos.vlrContrib6)}
              </submenu-coluna-info-aposent>
            ) : (
              <></>
            )}
            <Divisoria />
            <submenu-coluna-info-aposent-bold
              style={{
                textAlign: 'end',
              }}
            >
              {ValorMascarar(
                `${
                  dsApos.vlrContribTotal === undefined
                    ? '0,00'
                    : ValorMascarar(dsApos.vlrContribTotal)
                }`
              )}
            </submenu-coluna-info-aposent-bold>
          </submenu-item-coluna-aposent-01>
          <submenu-bloco-coluna-simulador-aposent>
            <simulador-form-frame-aposent>
              <form>
                <submenu-coluna-labels-simulador>
                  Valor utilizado
                </submenu-coluna-labels-simulador>
                <input
                  type="text"
                  placeholder="Valor"
                  value={ValorMascarar(`${utilizado.vlrInfo1}`)}
                  onChange={(e) =>
                    setUtilizado({
                      ...utilizado,
                      vlrInfo1: e.target.value,
                    })
                  }
                  onBlur={(e) => handleModificarContrib(e.target.value, 1)}
                />
                {dsApos.exibeContrib2 === 'S' ? (
                  <input
                    type="text"
                    placeholder="Valor"
                    value={ValorMascarar(`${utilizado.vlrInfo2}`)}
                    onChange={(e) =>
                      setUtilizado({
                        ...utilizado,
                        vlrInfo2: e.target.value,
                      })
                    }
                    onBlur={(e) => handleModificarContrib(e.target.value, 2)}
                  />
                ) : (
                  <></>
                )}
                {dsApos.exibeContrib3 === 'S' ? (
                  <input
                    type="text"
                    placeholder="Valor"
                    value={ValorMascarar(`${utilizado.vlrInfo3}`)}
                    onChange={(e) =>
                      setUtilizado({
                        ...utilizado,
                        vlrInfo3: e.target.value,
                      })
                    }
                    onBlur={(e) => handleModificarContrib(e.target.value, 3)}
                    disabled
                  />
                ) : (
                  <></>
                )}
                {dsApos.exibeContrib4 === 'S' ? (
                  <input
                    type="text"
                    placeholder="Valor"
                    value={ValorMascarar(`${utilizado.vlrInfo4}`)}
                    onChange={(e) =>
                      setUtilizado({
                        ...utilizado,
                        vlrInfo4: e.target.value,
                      })
                    }
                    onBlur={(e) => handleModificarContrib(e.target.value, 4)}
                    disabled
                  />
                ) : (
                  <></>
                )}
                {dsApos.exibeContrib5 === 'S' ? (
                  <input
                    type="text"
                    placeholder="Valor"
                    value={ValorMascarar(`${utilizado.vlrInfo5}`)}
                    onChange={(e) =>
                      setUtilizado({
                        ...utilizado,
                        vlrInfo5: e.target.value,
                      })
                    }
                    onBlur={(e) => handleModificarContrib(e.target.value, 5)}
                    disabled
                  />
                ) : (
                  <></>
                )}
                {dsApos.exibeContrib6 === 'S' ? (
                  <input
                    type="text"
                    placeholder="Valor"
                    value={ValorMascarar(`${utilizado.vlrInfo6}`)}
                    onChange={(e) =>
                      setUtilizado({
                        ...utilizado,
                        vlrInfo6: e.target.value,
                      })
                    }
                    onBlur={(e) => handleModificarContrib(e.target.value, 6)}
                    disabled
                  />
                ) : (
                  <></>
                )}
                <Divisoria />
                <submenu-coluna-info-aposent-bold
                  style={{
                    textAlign: 'end',
                    justifyContent: 'right',
                    paddingRight: '12px',
                  }}
                >
                  {ValorMascarar(`${utilizado.vlrInfoTotal}`)}
                </submenu-coluna-info-aposent-bold>
              </form>
            </simulador-form-frame-aposent>
          </submenu-bloco-coluna-simulador-aposent>
        </frame-horizontal-aposent>
      </submenu-bloco>
      <submenu-bloco-horizontal>
        <div>
          <submenu-bloco-coluna-simulador-08>
            <submenu-coluna-labels-simulador>
              Idade na aposentadoria
            </submenu-coluna-labels-simulador>
          </submenu-bloco-coluna-simulador-08>
          <submenu-bloco-coluna-simulador-07>
            <form>
              <select
                onChange={(e) => {
                  setIdade(e.target.value);
                  setFlagSimul(false);
                  setFlagCalc(false);
                }}
              >
                <option value={null}>Idade...</option>
                {listaIdades.map((n) => (
                  <option value={n}>{`${n}`}</option>
                ))}
              </select>
            </form>
          </submenu-bloco-coluna-simulador-07>
          <submenu-bloco-coluna-simulador-06>
            <button
              type="button"
              disabled={idade === null}
              onClick={() => handleSimular()}
            >
              {idade === null ? 'Selecione a idade para simular' : 'Simular'}
            </button>
          </submenu-bloco-coluna-simulador-06>
        </div>
      </submenu-bloco-horizontal>

      <div style={{ width: '100%' }}>
        <Collapse in={flagSimul}>
          <submenu-bloco>
            <submenu-titulos>
              Resultado da simulação | Saldo projetado
            </submenu-titulos>
            <frame-horizontal-aposent>
              <submenu-item-coluna-aposent-02>
                {saldoProjetado.exibCampo1 === 'S' ? (
                  <submenu-coluna-info-aposent-result>
                    {saldoProjetado.dcrCampo1}
                  </submenu-coluna-info-aposent-result>
                ) : (
                  <></>
                )}
                {saldoProjetado.exibCampo2 === 'S' ? (
                  <submenu-coluna-info-aposent-result>
                    {saldoProjetado.dcrCampo2}
                  </submenu-coluna-info-aposent-result>
                ) : (
                  <></>
                )}
                {saldoProjetado.exibCampo3 === 'S' ? (
                  <submenu-coluna-info-aposent-result>
                    {saldoProjetado.dcrCampo3}
                  </submenu-coluna-info-aposent-result>
                ) : (
                  <></>
                )}
                {saldoProjetado.exibCampo4 === 'S' ? (
                  <submenu-coluna-info-aposent-result>
                    {saldoProjetado.dcrCampo4}
                  </submenu-coluna-info-aposent-result>
                ) : (
                  <></>
                )}
                {saldoProjetado.exibCampo5 === 'S' ? (
                  <submenu-coluna-info-aposent-result>
                    {saldoProjetado.dcrCampo5}
                  </submenu-coluna-info-aposent-result>
                ) : (
                  <></>
                )}
                {saldoProjetado.exibCampo6 === 'S' ? (
                  <submenu-coluna-info-aposent-result>
                    {saldoProjetado.dcrCampo6}
                  </submenu-coluna-info-aposent-result>
                ) : (
                  <></>
                )}
                {saldoProjetado.exibCampo7 === 'S' ? (
                  <submenu-coluna-info-aposent-result>
                    {saldoProjetado.dcrCampo7}
                  </submenu-coluna-info-aposent-result>
                ) : (
                  <></>
                )}
              </submenu-item-coluna-aposent-02>
              <submenu-item-coluna-aposent-03>
                {saldoProjetado.exibCampo1 === 'S' ? (
                  <submenu-coluna-info-aposent-result>
                    {saldoProjetado.vlrCampo1}
                  </submenu-coluna-info-aposent-result>
                ) : (
                  <></>
                )}
                {saldoProjetado.exibCampo2 === 'S' ? (
                  <submenu-coluna-info-aposent-result>
                    {saldoProjetado.vlrCampo2}
                  </submenu-coluna-info-aposent-result>
                ) : (
                  <></>
                )}
                {saldoProjetado.exibCampo3 === 'S' ? (
                  <submenu-coluna-info-aposent-result>
                    {saldoProjetado.vlrCampo3}
                  </submenu-coluna-info-aposent-result>
                ) : (
                  <></>
                )}
                {saldoProjetado.exibCampo4 === 'S' ? (
                  <submenu-coluna-info-aposent-result>
                    {saldoProjetado.vlrCampo4}
                  </submenu-coluna-info-aposent-result>
                ) : (
                  <></>
                )}
                {saldoProjetado.exibCampo5 === 'S' ? (
                  <submenu-coluna-info-aposent-result>
                    {saldoProjetado.vlrCampo5}
                  </submenu-coluna-info-aposent-result>
                ) : (
                  <></>
                )}
                {saldoProjetado.exibCampo6 === 'S' ? (
                  <submenu-coluna-info-aposent-result>
                    {saldoProjetado.vlrCampo6}
                  </submenu-coluna-info-aposent-result>
                ) : (
                  <></>
                )}
                {saldoProjetado.exibCampo7 === 'S' ? (
                  <submenu-coluna-info-aposent-result>
                    {saldoProjetado.vlrCampo7}
                  </submenu-coluna-info-aposent-result>
                ) : (
                  <></>
                )}
              </submenu-item-coluna-aposent-03>
            </frame-horizontal-aposent>
          </submenu-bloco>
          <submenu-bloco>
            <submenu-titulos>Cálculo da renda</submenu-titulos>
            <submenu-bloco>
              <submenu-titulos>% resgate à vista</submenu-titulos>
              <submenu-bloco-parte01>
                <slider-parte01>
                  <SliderContribuicao
                    max={25}
                    min={0}
                    value={pctResgate}
                    defaultValue={0}
                    step={0.5}
                    valueLabelDisplay="auto"
                    marks={marksSuplementar}
                    onChange={handleChangePctResgate}
                  />
                </slider-parte01>
                <slider-parte02>
                  <input
                    type="text"
                    placeholder="0,0%"
                    value={`${pctResgate}%`}
                    disabled
                  />
                </slider-parte02>
              </submenu-bloco-parte01>
            </submenu-bloco>

            <submenu-bloco-horizontal>
              <div>
                <submenu-bloco-coluna-simulador-01>
                  <submenu-coluna-labels-simulador>
                    Forma de recebimento
                  </submenu-coluna-labels-simulador>
                </submenu-bloco-coluna-simulador-01>
                <submenu-bloco-coluna-simulador-07>
                  <form>
                    <select
                      onChange={(e) => {
                        setFormaRecebimento(e.target.value);
                        setPctSobreSaldo(0);
                        setPrazoAnos(0);
                        setValorReal('0');
                        setFlagCalc(false);
                      }}
                    >
                      <option value={null}>Selecione...</option>
                      <option value={1}>Por percentual</option>
                      <option value={5}>Prazo determinado</option>
                      <option value={7}>Valor real</option>
                    </select>
                  </form>
                </submenu-bloco-coluna-simulador-07>
                <submenu-bloco-coluna-simulador-06>
                  {inputFormaRecebimento}
                </submenu-bloco-coluna-simulador-06>
                <submenu-bloco-coluna-simulador-06>
                  <button
                    type="button"
                    disabled={
                      pctSobreSaldo === 0 &&
                      prazoAnos === 0 &&
                      valorReal === '0'
                    }
                    onClick={() => handleCalcular()}
                  >
                    {pctSobreSaldo === 0 && prazoAnos === 0 && valorReal === '0'
                      ? 'Preencha a forma de recebimento'
                      : 'Calcular'}
                  </button>
                </submenu-bloco-coluna-simulador-06>
              </div>
            </submenu-bloco-horizontal>
          </submenu-bloco>
        </Collapse>
      </div>
      <div style={{ width: '100%' }}>
        <Collapse in={flagCalc}>
          <submenu-bloco-box>
            <submenu-titulos>Resultado final</submenu-titulos>
            <frame-horizontal-aposent>
              <submenu-item-coluna-aposent-02>
                <submenu-coluna-info-aposent-result>
                  {calculoRenda.dcrCampo1}
                </submenu-coluna-info-aposent-result>
                <submenu-coluna-info-aposent-result>
                  {calculoRenda.dcrCampo2}
                </submenu-coluna-info-aposent-result>
                <submenu-coluna-info-aposent-result>
                  {calculoRenda.dcrCampo3}
                </submenu-coluna-info-aposent-result>
                {calculoRenda.exibeCampo4 === 'S' ? (
                  <submenu-coluna-info-aposent-result>
                    {calculoRenda.dcrCampo4}
                  </submenu-coluna-info-aposent-result>
                ) : (
                  <></>
                )}
                {calculoRenda.exibeCampo5 === 'S' ? (
                  <submenu-coluna-info-aposent-result>
                    {calculoRenda.dcrCampo5}
                  </submenu-coluna-info-aposent-result>
                ) : (
                  <></>
                )}
                {calculoRenda.exibeCampo6 === 'S' ? (
                  <submenu-coluna-info-aposent-result>
                    {calculoRenda.dcrCampo6}
                  </submenu-coluna-info-aposent-result>
                ) : (
                  <></>
                )}
                {calculoRenda.exibeCeampo7 === 'S' ? (
                  <submenu-coluna-info-aposent-result>
                    {calculoRenda.dcrCampo7}
                  </submenu-coluna-info-aposent-result>
                ) : (
                  <></>
                )}
                {calculoRenda.exibeCampo8 === 'S' ? (
                  <submenu-coluna-info-aposent-result>
                    {calculoRenda.dcrCampo8}
                  </submenu-coluna-info-aposent-result>
                ) : (
                  <></>
                )}
                {calculoRenda.exibeCampo9 === 'S' ? (
                  <submenu-coluna-info-aposent-result>
                    {calculoRenda.dcrCampo9}
                  </submenu-coluna-info-aposent-result>
                ) : (
                  <></>
                )}
                {calculoRenda.exibeCampo10 === 'S' ? (
                  <submenu-coluna-info-aposent-result>
                    {calculoRenda.dcrCampo10}
                  </submenu-coluna-info-aposent-result>
                ) : (
                  <></>
                )}
              </submenu-item-coluna-aposent-02>
              <submenu-item-coluna-aposent-03>
                <submenu-coluna-info-aposent-result>
                  {calculoRenda.vlrCampo1}
                </submenu-coluna-info-aposent-result>
                <submenu-coluna-info-aposent-result>
                  {calculoRenda.vlrCampo2}
                </submenu-coluna-info-aposent-result>
                <submenu-coluna-info-aposent-result>
                  {calculoRenda.vlrCampo3}
                </submenu-coluna-info-aposent-result>
                {calculoRenda.exibeCampo4 === 'S' ? (
                  <submenu-coluna-info-aposent-result>
                    {calculoRenda.vlrCampo4}
                  </submenu-coluna-info-aposent-result>
                ) : (
                  <></>
                )}
                {calculoRenda.exibeCampo5 === 'S' ? (
                  <submenu-coluna-info-aposent-result>
                    {calculoRenda.vlrCampo5}
                  </submenu-coluna-info-aposent-result>
                ) : (
                  <></>
                )}
                {calculoRenda.exibeCampo6 === 'S' ? (
                  <submenu-coluna-info-aposent-result>
                    {calculoRenda.vlrCampo6}
                  </submenu-coluna-info-aposent-result>
                ) : (
                  <></>
                )}
                {calculoRenda.exibeCampo7 === 'S' ? (
                  <submenu-coluna-info-aposent-result>
                    {calculoRenda.vlrCampo7}
                  </submenu-coluna-info-aposent-result>
                ) : (
                  <></>
                )}
                {calculoRenda.exibeCampo8 === 'S' ? (
                  <submenu-coluna-info-aposent-result>
                    {calculoRenda.vlrCampo8}
                  </submenu-coluna-info-aposent-result>
                ) : (
                  <></>
                )}
                {calculoRenda.exibeCampo9 === 'S' ? (
                  <submenu-coluna-info-aposent-result>
                    {calculoRenda.vlrCampo9}
                  </submenu-coluna-info-aposent-result>
                ) : (
                  <></>
                )}
                {calculoRenda.exibeCampo10 === 'S' ? (
                  <submenu-coluna-info-aposent-result>
                    {calculoRenda.vlrCampo10}
                  </submenu-coluna-info-aposent-result>
                ) : (
                  <></>
                )}
              </submenu-item-coluna-aposent-03>
            </frame-horizontal-aposent>
          </submenu-bloco-box>
        </Collapse>
      </div>
      <submenu-bloco>
        <submenu-titulos>Observações</submenu-titulos>
        <submenu-item-conteudo-ajustado>
          {calculoRenda.msg1 === '' || calculoRenda.msg1 === undefined ? (
            <SubmenuObservacoes>
              A simulação não representa promessa de rentabilidade ou garantia
              de nível de benefício.
            </SubmenuObservacoes>
          ) : (
            <SubmenuObservacoes>{calculoRenda.msg1}</SubmenuObservacoes>
          )}
          {calculoRenda.msg2 === '' || calculoRenda.msg2 === undefined ? (
            <></>
          ) : (
            <SubmenuObservacoes>{calculoRenda.msg2}</SubmenuObservacoes>
          )}
        </submenu-item-conteudo-ajustado>
      </submenu-bloco>
    </SubmenuCorpoFrame>
  );
}

export default Aposentadoria;
