import produce from 'immer';

const EstadoInicial = {
  profile: null,
  nome: null,
  indice: null,
};

export default function visaoGeral(state = EstadoInicial, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@visaoGeral/GET_DADOS_PLANO': {
        draft.profile = action.payload.dadosPlano;
        draft.nome = action.payload.nomeParticipante;
        draft.indice = '0';
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.profile = null;
        draft.nome = null;
        draft.indice = null;
        break;
      }
      case '@visaoGeral/MUDA_INDEX': {
        draft.indice = action.payload.index;
        break;
      }
      default:
    }
  });
}
