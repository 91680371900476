/* eslint-disable react/no-array-index-key */
import React from 'react';
import '../../../config/ReactotronConfig';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Bloco } from '../styles';

function BlocoEnderecos(props, x) {
  const dadosEndereco = props;

  if (props === undefined) {
    return <></>;
  }

  const { cep } = dadosEndereco[x];

  return (
    <>
      <Bloco>
        <dados-pessoais-subtitulo>
          Endereços cadastrados
        </dados-pessoais-subtitulo>
        <dados-pessoais-bloco-parte01>
          <dados-pessoais-bloco-parte02-100>
            <List dense>
              {cep === undefined ||
              cep === null ||
              cep === [] ||
              cep === '[]' ||
              cep === '' ? (
                <ListItem>
                  <ListItemText primary="Endereço" secondary="-" />
                </ListItem>
              ) : (
                dadosEndereco.map((n, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={`Endereço ${index + 1}${
                        n.tipoEndereco == null || n.tipoEndereco === ' '
                          ? ' '
                          : ` (${n.tipoEndereco})`
                      }`}
                      secondary={`${
                        (n.logradouro == null ? ' ' : `${n.logradouro}, `) +
                        (n.numero == null ? ' ' : `${n.numero}, `) +
                        (n.complemento == null ? ' ' : `${n.complemento}, `) +
                        (n.bairro == null ? ' ' : `${n.bairro}, `) +
                        (n.cep == null ? ' ' : `${n.cep}, `) +
                        (n.municipio == null ? ' ' : `${n.municipio}, `) +
                        (n.uf == null ? ' ' : `${n.uf}, `) +
                        (n.pais == null ? '. ' : `${n.pais}. `)
                      }`}
                    />
                  </ListItem>
                ))
              )}
            </List>
          </dados-pessoais-bloco-parte02-100>
        </dados-pessoais-bloco-parte01>
      </Bloco>
    </>
  );
}

export default BlocoEnderecos;
