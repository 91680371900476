import React, { useState, useEffect } from 'react';
import { Base64 } from 'js-base64';
import SaldoIndividual from './saldoindividual';
import api from '../../services/api';
import { SubmenusExtratos, BotaoSubmenuSaldoIndiv } from './styles';
import { store } from '../../store';

function Corpo() {
  // const [visible, setVisible] = useState(false);
  const [state, setState] = useState({
    ExtratoSaldoGet: null,
    Erro: '',
  });
  /* function HandleSaldoIndivVisible() {
    setVisible(1);
  } */

  useEffect(() => {
    async function pegaDatasExtrato() {
      try {
        const t = store.getState().auth.token;

        const parametros = Base64.encode(`{"token":"${t}"}`);
        const parametrosFinal = Base64.encode(parametros);

        const responseExtGet = await api.get(
          `/wsExtratoSaldo.rule?sys=PRT&Entrada=${parametrosFinal}`
        );

        setState({
          ExtratoSaldoGet: responseExtGet.data,
        });
      } catch (err) {
        setState({ Erro: err });
      }
    }
    pegaDatasExtrato();
  }, []);

  if (state.ExtratoSaldoGet === undefined || state.ExtratoSaldoGet === null) {
    return (
      <>
        <SubmenusExtratos />
      </>
    );
  }

  const { dadosDatas } = state.ExtratoSaldoGet;

  return (
    <SubmenusExtratos>
      {/* <w-tab-menu>
        <BotaoSubmenuSaldoIndiv>
          Extrato de saldo individual
        </BotaoSubmenuSaldoIndiv>
      </w-tab-menu> */}
      <w-tab-content>
        <w-tab-pane>
          <SaldoIndividual dadosDts={dadosDatas} />
        </w-tab-pane>
      </w-tab-content>
    </SubmenusExtratos>
  );
}

export default Corpo;
