import React, { useState, useEffect } from 'react';
import { Base64 } from 'js-base64';
import Bnf from './bnf';
import { SubmenusBeneficiarios } from './styles';
import { store } from '../../store';
import api from '../../services/api';

function Corpo() {
  const [state, setState] = useState({
    DadosBeneficiarios: null,
    Erro: '',
  });

  useEffect(() => {
    async function pegaDatasExtrato() {
      try {
        const t = store.getState().auth.token;

        const parametros = Base64.encode(`{"token":"${t}"}`);
        const parametrosFinal = Base64.encode(parametros);
        const responseBnf = await api.get(
          `/wsBeneficiario.rule?sys=PRT&Entrada=${parametrosFinal}`
        );

        setState({
          DadosBeneficiarios: responseBnf.data,
        });
      } catch (err) {
        setState({ Erro: err });
      }
    }
    pegaDatasExtrato();
  }, []);

  if (
    state.DadosBeneficiarios === undefined ||
    state.DadosBeneficiarios === null
  ) {
    return (
      <>
        <SubmenusBeneficiarios />
      </>
    );
  }

  const { dadosBeneficiarios } = state.DadosBeneficiarios;
  const { grauParentesco } = state.DadosBeneficiarios;
  return (
    <SubmenusBeneficiarios>
      <w-tab-content>
        <w-tab-pane>
          <Bnf dadosBnf={[dadosBeneficiarios, grauParentesco]} />
        </w-tab-pane>
      </w-tab-content>
    </SubmenusBeneficiarios>
  );
}

export default Corpo;
