/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import { AiOutlineHome, AiOutlineEye } from 'react-icons/ai';
// import { FaRegHandPointRight, FaRegHandPaper } from 'react-icons/fa';
import { Form, Input } from '@rocketseat/unform';
import { Base64 } from 'js-base64';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
// import axios from 'axios';
import api from '../../services/api';
import {
  Container,
  AlterarSenhaCorpo,
  BotaoAlterarSenha,
  AlterarSenhaCampo,
  /* FormAltSenha,
  AlterarSenhaLabel,
  TextField, */ ModalAlert,
} from './styles';
import IconeOlho from '../../assets/icone-mostrar-senha.svg';
import IconeOlhoFechado from '../../assets/icone-ocultar-senha.svg';
import BotaoInicio from '../../assets/icone-home-orange.svg';
import IconeMaoOk from '../../assets/icone-senha-ok.svg';
import IconeMaoAlerta from '../../assets/icone-senha-nok.svg';
import Footer from '../../components/Footer';
import { store } from '../../store';
import { signOut } from '../../store/modules/auth/actions';

const schema = Yup.object().shape({
  pass: Yup.string().required('Campo obrigatório'),
  newPass: Yup.string().required('Campo obrigatório'),
  confNewPass: Yup.string()
    .required('Campo obrigatório')
    .oneOf([Yup.ref('newPass')], 'As senhas não conferem'),
});

function AlterarSenha() {
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [prm, setPrm] = useState({
    AlterarSenha: {
      minimoCaracter: null,
      maximoCaracter: null,
      minusculoCaracter: null,
      maiusculoCaracter: null,
      numericoCaracter: null,
      especialCaracter: null,
      observacao: null,
    },
    Erro: '',
  });
  const [msg, setMsg] = useState('');
  const [alerta, setAlerta] = useState(false);
  const [vMinMax, setVMinMax] = useState(false);
  const [vMinusc, setVMinusc] = useState(false);
  const [vMaiusc, setVMaiusc] = useState(false);
  const [vNum, setVNum] = useState(false);
  const [vEsp, setVEsp] = useState(false);

  const dispatch = useDispatch();

  const { token } = store.getState().auth;

  useEffect(() => {
    async function pegaVisaoGeral() {
      try {
        const parametros = Base64.encode(`{"token":"${token}"}`);
        const parametrosFinal = Base64.encode(parametros);

        const response = await api.get(
          `/wsSenha.rule?sys=PRT&Entrada=${parametrosFinal}`
        );
        setPrm({
          AlterarSenha: response.data.dadosPoliticaSenha[0],
        });
      } catch (err) {
        dispatch(signOut());
        setPrm({ Erro: err });
      }
    }
    pegaVisaoGeral();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let charMaiusc = '';
  if (
    prm.AlterarSenha.maiusculoCaracter === null ||
    prm.AlterarSenha.maiusculoCaracter === undefined
  ) {
    charMaiusc = 0;
  } else {
    charMaiusc = prm.AlterarSenha.maiusculoCaracter;
  }

  let charMinusc = '';
  if (
    prm.AlterarSenha.minusculoCaracter === null ||
    prm.AlterarSenha.minusculoCaracter === undefined
  ) {
    charMinusc = 0;
  } else {
    charMinusc = prm.AlterarSenha.minusculoCaracter;
  }

  let numbChar = '';
  if (
    prm.AlterarSenha.numericoCaracter === null ||
    prm.AlterarSenha.numericoCaracter === undefined
  ) {
    numbChar = 0;
  } else numbChar = prm.AlterarSenha.numericoCaracter;

  let specialChar = '';
  if (
    prm.AlterarSenha.especialCaracter === null ||
    prm.AlterarSenha.especialCaracter === undefined
  ) {
    specialChar = 0;
  } else {
    specialChar = prm.AlterarSenha.especialCaracter;
  }

  let minChar = '';
  if (
    prm.AlterarSenha.minimoCaracter === null ||
    prm.AlterarSenha.minimoCaracter === undefined
  ) {
    minChar = 0;
  } else {
    minChar = prm.AlterarSenha.minimoCaracter;
  }

  let maxChar = '';
  if (
    prm.AlterarSenha.maximoCaracter === null ||
    prm.AlterarSenha.maximoCaracter === undefined
  ) {
    maxChar = 0;
  } else {
    maxChar = prm.AlterarSenha.maximoCaracter;
  }

  let minMaxCaracter = '';
  if (minChar === 0 && maxChar === 0) {
    minMaxCaracter = <></>;
  } else if (minChar === 0 && maxChar !== null) {
    minMaxCaracter = (
      <checklist-senha>
        <img
          src={vMinMax ? IconeMaoOk : IconeMaoAlerta}
          alt="IconeMaoOk"
          width="20"
        />
        <checklist-senha-texto>
          {`Entre 1 e
  ${maxChar} caracteres`}
        </checklist-senha-texto>
      </checklist-senha>
    );
  } else if (minChar !== null && maxChar === 0) {
    minMaxCaracter = (
      <checklist-senha>
        <img
          src={vMinMax ? IconeMaoOk : IconeMaoAlerta}
          alt="IconeMaoOk"
          width="20"
        />
        <checklist-senha-texto>
          {`No mínimo ${minChar} caracteres`}
        </checklist-senha-texto>
      </checklist-senha>
    );
  } else {
    minMaxCaracter = (
      <checklist-senha>
        <img
          src={vMinMax ? IconeMaoOk : IconeMaoAlerta}
          alt="IconeMaoOk"
          width="20"
        />
        <checklist-senha-texto>
          {`Entre ${minChar} e
    ${maxChar} caracteres`}
        </checklist-senha-texto>
      </checklist-senha>
    );
  }

  let caracMinusculo = '';
  if (charMinusc === 0) {
    caracMinusculo = <></>;
  } else {
    caracMinusculo = (
      <checklist-senha>
        <img
          src={vMinusc ? IconeMaoOk : IconeMaoAlerta}
          alt="IconeMaoOk"
          width="20"
        />
        <checklist-senha-texto>
          {charMinusc > 1
            ? `${charMinusc} letras minúsculas`
            : `${charMinusc} letra minúscula`}
        </checklist-senha-texto>
      </checklist-senha>
    );
  }

  let caracMaiusculo = '';
  if (charMaiusc === 0) {
    caracMaiusculo = <></>;
  } else {
    caracMaiusculo = (
      <checklist-senha>
        <img
          src={vMaiusc ? IconeMaoOk : IconeMaoAlerta}
          alt="IconeMaoOk"
          width="20"
        />
        <checklist-senha-texto>
          {charMaiusc > 1
            ? `${charMaiusc} letras maiúsculas`
            : `${charMaiusc} letra maiúscula`}
        </checklist-senha-texto>
      </checklist-senha>
    );
  }

  let caracNumerico = '';
  if (numbChar === 0) {
    caracNumerico = <></>;
  } else {
    caracNumerico = (
      <checklist-senha>
        <img
          src={vNum ? IconeMaoOk : IconeMaoAlerta}
          alt="IconeMaoAlerta"
          width="20"
        />
        <checklist-senha-texto>
          {numbChar > 1 ? `${numbChar} números` : `${numbChar} número`}
        </checklist-senha-texto>
      </checklist-senha>
    );
  }

  let caracEspecial = '';
  if (specialChar === 0) {
    caracEspecial = <></>;
  } else {
    caracEspecial = (
      <checklist-senha>
        <img
          src={vEsp ? IconeMaoOk : IconeMaoAlerta}
          alt="IconeMaoAlerta"
          width="20"
        />
        <checklist-senha-texto>
          {specialChar > 1
            ? `${specialChar} caracteres especiais`
            : `${specialChar} caractere especial`}
        </checklist-senha-texto>
      </checklist-senha>
    );
  }

  let observ = '';
  if (
    prm.AlterarSenha.observacao === null ||
    prm.AlterarSenha.observacao === '' ||
    prm.AlterarSenha.observacao === undefined
  ) {
    observ = <></>;
  } else {
    observ = (
      <alterar-senha-aviso-frame-parte01>
        <alterar-senha-aviso-texto>
          {prm.AlterarSenha.observacao}
        </alterar-senha-aviso-texto>
      </alterar-senha-aviso-frame-parte01>
    );
  }

  /* const teste =
    '/^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$/';
  // '/^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$/'; */

  function validaSenha(newPass) {
    const regexNumb = /[0-9]/;
    const regexMaiusc = /[A-Z]/;
    const regexMinusc = /[a-z]/;
    const regexEsp = /[}{,.^?~=+\-_\/*\-+.\|]/;

    if (
      newPass.length >= parseInt(minChar, 10) &&
      (newPass.length <= parseInt(maxChar, 10) || parseInt(maxChar, 10) === 0)
    ) {
      setVMinMax(true);
    } else {
      setVMinMax(false);
    }

    if (parseInt(numbChar, 10) === 0) {
      setVNum(true);
    } else {
      setVNum(regexNumb.test(newPass));
    }

    if (parseInt(charMaiusc, 10) === 0) {
      setVMaiusc(true);
    } else {
      setVMaiusc(regexMaiusc.test(newPass));
    }

    if (parseInt(charMinusc, 10) === 0) {
      setVMinusc(true);
    } else {
      setVMinusc(regexMinusc.test(newPass));
    }

    if (parseInt(specialChar, 10) === 0) {
      setVEsp(true);
    } else {
      setVEsp(regexEsp.test(newPass));
    }
  }

  function HandleToggleAlerta() {
    setAlerta(!alerta);
  }

  async function handleAlterarSenha({ pass, newPass }) {
    if (pass === newPass) {
      setMsg('As nova senha não pode ser igual à atual');
      setAlerta(true);
    } else {
      try {
        const parametros = Base64.encode(
          `{"token": "${token}","senha": "${pass}","novaSenha": "${newPass}"}`
        );
        const parametrosFinal = Base64.encode(parametros);

        await api.post(`/wsSenhaPUT.rule?sys=PRT&Entrada=${parametrosFinal}`);

        setMsg('Senha alterada com suceso!');
        setAlerta(true);
      } catch (err) {
        setMsg(
          'Senha não alterada! Verifique se sua senha atual está correta.'
        );
        setAlerta(true);
      }
    }
  }
  function HandleToggleVisible1() {
    setVisible1(!visible1);
  }
  function HandleToggleVisible2() {
    setVisible2(!visible2);
  }
  function HandleToggleVisible3() {
    setVisible3(!visible3);
  }

  return (
    <>
      <Container>
        <ModalAlert visible={alerta}>
          <popup-inner>
            <pop-up-sair-texto>{msg}</pop-up-sair-texto>
            <div>
              <button type="button" onClick={HandleToggleAlerta}>
                OK
              </button>
            </div>
          </popup-inner>
        </ModalAlert>
        <alterar-senha-frame>
          <Link to="/main">
            <botao-inicio>
              <botao-inicio-icone>
                <img src={BotaoInicio} alt="BotaoInicio" width="30" />
              </botao-inicio-icone>
              <botao-inicio-label>Voltar à página inicial</botao-inicio-label>
            </botao-inicio>
          </Link>
          <alterar-senha-linha-titulo>
            <alterar-senha-titulo>Alterar senha</alterar-senha-titulo>
          </alterar-senha-linha-titulo>
          <Form schema={schema} onSubmit={handleAlterarSenha}>
            <AlterarSenhaCorpo>
              <alterar-senha-corpo-parte01>
                <AlterarSenhaCampo>
                  <alterar-senha-input>
                    <Input name="pass" type={visible1 ? 'text' : 'password'} />
                    <img
                      onClick={HandleToggleVisible1}
                      src={visible1 ? IconeOlhoFechado : IconeOlho}
                      alt="IconeOlho"
                    />
                  </alterar-senha-input>
                  <label>Senha atual *</label>
                </AlterarSenhaCampo>

                <AlterarSenhaCampo>
                  <alterar-senha-input>
                    <Input
                      name="newPass"
                      type={visible2 ? 'text' : 'password'}
                      onChange={(e) => validaSenha(e.target.value)}
                    />
                    <img
                      onClick={HandleToggleVisible2}
                      src={visible2 ? IconeOlhoFechado : IconeOlho}
                      alt="IconeOlho"
                    />
                  </alterar-senha-input>
                  <label>Nova senha *</label>
                </AlterarSenhaCampo>

                <AlterarSenhaCampo>
                  <alterar-senha-input>
                    <Input
                      name="confNewPass"
                      type={visible3 ? 'text' : 'password'}
                      disabled={
                        !(vMinMax && vMaiusc && vMinusc && vNum && vEsp)
                      }
                    />
                    <img
                      onClick={HandleToggleVisible3}
                      src={visible3 ? IconeOlhoFechado : IconeOlho}
                      alt="IconeOlho"
                    />
                  </alterar-senha-input>
                  <label>Confirme a nova senha *</label>
                </AlterarSenhaCampo>
              </alterar-senha-corpo-parte01>
              <alterar-senha-corpo-parte02>
                <alterar-senha-aviso-frame>
                  {observ}
                  <alterar-senha-aviso-frame-parte02>
                    <checklist-senha-titulo>
                      Sua senha deve ter:
                    </checklist-senha-titulo>
                    {minMaxCaracter}
                    {caracMinusculo}
                    {caracMaiusculo}
                    {caracNumerico}
                    {caracEspecial}
                  </alterar-senha-aviso-frame-parte02>
                </alterar-senha-aviso-frame>
              </alterar-senha-corpo-parte02>
            </AlterarSenhaCorpo>
            <BotaoAlterarSenha type="submit">Gravar</BotaoAlterarSenha>
          </Form>
        </alterar-senha-frame>
      </Container>
      <Footer />
    </>
  );
}

export default AlterarSenha;
