import React, { useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import Collapse from '@material-ui/core/Collapse';
import { Base64 } from 'js-base64';
import {
  BlocoCadastro,
  DadosPesssoaisItemLabel,
  CorpoForm,
  CampoInput,
  BotaoCadastrar,
  Div,
  ModalAlert,
} from '../styles';
import codDDI from '../../../services/ddi.json';
import api from '../../../services/api';
import { store } from '../../../store';
import { Telefone, Integer } from '../../../services/masks';

function CadastrarTelefone() {
  const [visible, setVisible] = useState(false);
  const [tipoTel, setTipoTel] = useState('');
  const [ddi, setDDI] = useState('55');
  const [ddd, setDDD] = useState('');
  const [numero, setNumero] = useState('');
  const [msg, setMsg] = useState('');
  const [alerta, setAlerta] = useState(false);
  const [flag, setFlag] = useState(false);

  const { token } = store.getState().auth;

  function HandleToggleVisible() {
    setVisible(!visible);
  }

  const lista = codDDI.map((n) => (
    <option key={n.ddi} onChange={(e) => setDDI(e.target.value)}>
      {n.pais}
    </option>
  ));

  async function handleAddTelefone() {
    const telFormat = numero.replace('-', '');
    const parametros = Base64.encode(
      `{"token": "${token}", "tipo": "${tipoTel}", "ddi": ${ddi}, "ddd":${ddd}, "numero": ${telFormat}}`
    );
    const parametrosFinal = Base64.encode(parametros);
    await api
      .post(`/wsTelefone.rule?sys=PRT&Entrada=${parametrosFinal}`)
      .then(() => {
        setMsg('Telefone atualizado com sucesso');
        setAlerta(true);
        setFlag(true);
      })
      .catch(() => {
        setMsg(
          'Não foi possível atualizar o telefone agora. Tente novamente em alguns minutos.'
        );
        setAlerta(true);
      });
  }

  function HandleMaskTelefone(valor, mask) {
    setNumero(mask(valor));
  }
  function HandleMaskDDD(valor, mask) {
    setDDD(mask(valor));
  }

  function HandleToggleAlerta() {
    setAlerta(!alerta);
    if (flag === true) {
      document.location.reload(true);
    }
  }
  return (
    <BlocoCadastro>
      <ModalAlert visible={alerta}>
        <popup-inner>
          <pop-up-sair-texto>{msg}</pop-up-sair-texto>
          <div>
            <button type="button" onClick={HandleToggleAlerta}>
              OK
            </button>
          </div>
        </popup-inner>
      </ModalAlert>
      <dados-pessoais-abrir-cadastro onClick={HandleToggleVisible}>
        <dados-pessoais-subtitulo-white>
          Cadastrar novo telefone
        </dados-pessoais-subtitulo-white>
        <cadastro-botao-frame>
          <cadastro-botao visible={visible}>
            <AiOutlinePlus
              size={30}
              style={
                visible
                  ? {
                      transform: 'rotate(-135deg)',
                      transition: 'transform .2s ease-in-out',
                    }
                  : {
                      transform: 'rotate(0deg)',
                      transition: 'transform .2s ease-in-out',
                    }
              }
            />
          </cadastro-botao>
          {/* <cadastro-botao-barra-horizontal />
          <cadastro-botao-barra-vertical /> */}
        </cadastro-botao-frame>
      </dados-pessoais-abrir-cadastro>
      <Div>
        <Collapse in={visible}>
          <CorpoForm>
            <dados-pessoais-bloco-parte02>
              <dados-pesssoais-item-endereco>
                <DadosPesssoaisItemLabel>DDI</DadosPesssoaisItemLabel>
                <select>{lista}</select>
                <dados-pessoais-form>
                  {/* <dados-pessoais-form-frame>
                  <CampoInput type="text" placeholder="Inserir info" />
                </dados-pessoais-form-frame> */}
                </dados-pessoais-form>
              </dados-pesssoais-item-endereco>

              <dados-pesssoais-item-endereco>
                <DadosPesssoaisItemLabel>Número</DadosPesssoaisItemLabel>
                <dados-pessoais-form>
                  <dados-pessoais-form-frame>
                    <CampoInput>
                      <input
                        type="text"
                        name="numero"
                        placeholder="Inserir info"
                        value={numero}
                        maxLength="10"
                        onChange={(e) =>
                          HandleMaskTelefone(e.target.value, Telefone)
                        }
                      />
                    </CampoInput>
                  </dados-pessoais-form-frame>
                </dados-pessoais-form>
              </dados-pesssoais-item-endereco>
            </dados-pessoais-bloco-parte02>

            <dados-pessoais-bloco-parte02>
              <dados-pesssoais-item-endereco>
                <DadosPesssoaisItemLabel>DDD</DadosPesssoaisItemLabel>
                <dados-pessoais-form>
                  <dados-pessoais-form-frame>
                    <CampoInput>
                      <input
                        type="text"
                        name="ddd"
                        maxLength="2"
                        placeholder="Inserir info"
                        value={ddd}
                        onChange={(e) => HandleMaskDDD(e.target.value, Integer)}
                      />
                    </CampoInput>
                  </dados-pessoais-form-frame>
                </dados-pessoais-form>
              </dados-pesssoais-item-endereco>

              <dados-pesssoais-item-endereco>
                <DadosPesssoaisItemLabel>Tipo</DadosPesssoaisItemLabel>
                <dados-pessoais-form>
                  <dados-pessoais-form-frame>
                    <select
                      nome="tipo"
                      onChange={(e) => setTipoTel(e.target.value)}
                      defaultValue={tipoTel}
                    >
                      <option value="">Escolher opção</option>
                      <option value="R">Residencial</option>
                      <option value="E">Celular</option>
                      <option value="C">Comercial</option>
                      <option value="D">Recado</option>
                    </select>
                  </dados-pessoais-form-frame>
                </dados-pessoais-form>
              </dados-pesssoais-item-endereco>
              <dados-pesssoais-item-copy>
                <BotaoCadastrar onClick={handleAddTelefone}>
                  Cadastrar
                </BotaoCadastrar>
              </dados-pesssoais-item-copy>
            </dados-pessoais-bloco-parte02>
          </CorpoForm>
        </Collapse>
      </Div>
    </BlocoCadastro>
  );
}

export default CadastrarTelefone;
