/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Bloco } from '../styles';

function BlocoCusteioPlano(props, x) {
  const dadosPlano = props;

  if (props === undefined) {
    return <></>;
  }

  const custeio = dadosPlano[x].dadosCusteio;

  return (
    <Bloco>
      <dados-pessoais-subtitulo>
        Custeio individual no plano
      </dados-pessoais-subtitulo>
      <dados-pessoais-bloco-parte01>
        <dados-pessoais-bloco-colunas>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {/* <TableCell align="center">Linha</TableCell>
                  <TableCell align="center">Codigo</TableCell> */}
                  <TableCell align="center">Conta</TableCell>
                  <TableCell align="center">Início da vigência</TableCell>
                  <TableCell align="center">Contribuição %</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {custeio === undefined ||
                custeio === null ||
                custeio === [] ||
                custeio === '[]' ||
                custeio === '' ? (
                  <TableRow>
                    {/* <TableCell align="center">-</TableCell>
                    <TableCell align="center">-</TableCell> */}
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">-</TableCell>
                  </TableRow>
                ) : (
                  custeio.map((row, index) => (
                    <TableRow key={index}>
                      {/* <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {row.codigo == null || row.codigo === undefined
                          ? '-'
                          : row.codigo}
                      </TableCell> */}
                      <TableCell align="center">
                        {row.conta == null || row.conta === undefined
                          ? '-'
                          : row.conta}
                      </TableCell>
                      <TableCell align="center">
                        {row.inicioVigencia == null ||
                        row.inicioVigencia === undefined
                          ? '-'
                          : row.inicioVigencia}
                      </TableCell>
                      <TableCell align="center">
                        {row.contribuicao == null ||
                        row.contribuicao === undefined
                          ? '-'
                          : row.contribuicao}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </dados-pessoais-bloco-colunas>
      </dados-pessoais-bloco-parte01>
    </Bloco>
  );
}

export default BlocoCusteioPlano;
